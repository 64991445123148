import { Grid } from '@mui/material';
import { formatNewDate, formatNewHourClean } from 'utils/dates';
import { firestorage } from 'utils/firebase';
import { useCallback } from 'react';
import MapDesvio from 'pages/Desvios/components/MapDesvio';

// Columns

import * as S from './styled';

export const ExportToPdf = ({ acidente, printRef }) => {
  const logo = require('images/onisys.png');

  const getFileName = useCallback(fileUrl => {
    try {
      let newName = '-';
      if (fileUrl) newName = firestorage.refFromURL(fileUrl)?.name;
      return newName;
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <S.ModalMUI onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formIdent">
            <img src={logo} />
          </div>

          <div className="formIdent">
            <div className="titleForm">
              <span>Motorista: {acidente?.motorista}</span>
            </div>
            <div className="titleForm">
              <span>Acidente: {acidente?.id}</span>
            </div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações do acidente</h1>
        </S.CardHeader>
        <S.Header>
          <div className="topInfos">
            <div className="coverArea">
              {acidente?.latitude && (
                <MapDesvio
                  position={{
                    lat: parseFloat(acidente?.latitude),
                    lng: parseFloat(acidente?.longitude),
                  }}
                />
              )}
            </div>
            <div className="textsArea">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Motorista:</div>
                  <S.InputToPDF>{acidente?.motorista}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Filial:</div>
                  <S.InputToPDF>{acidente?.filial}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Tipo de acidente:</div>
                  <S.InputToPDF>{acidente?.tipo_acidente ?? '-'}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Data do acidente:</div>
                  <S.InputToPDF>
                    {(acidente?.data_acidente &&
                      formatNewHourClean(acidente?.data_acidente, true)) ||
                      '-'}
                  </S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="normalText">Agregado:</div>
                  <S.InputToPDF>{`${
                    acidente?.agregado ? 'Sim' : 'Não'
                  }`}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Placa:</div>
                  <S.InputToPDF>{acidente?.placa}</S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="normalText">Localização:</div>
                  <S.InputToPDF>
                    {` ${acidente?.localizacao || '-'}`}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Latitude:</div>
                  <S.InputToPDF>{` ${acidente?.latitude || '-'}`}</S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Longitude:</div>
                  <S.InputToPDF>{acidente?.longitude || '-'}</S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="normalText">Causa do acidente:</div>
                  <S.InputToPDF>{acidente?.causa || '-'}</S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="normalText">Estado físico:</div>
                  <S.InputToPDF>{acidente?.estado_fisico}</S.InputToPDF>
                </Grid>
              </Grid>
            </div>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Informações Complementares</h1>
        </S.CardHeader>
        <S.Header>
          <div className="bottomInfos">
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <div className="normalText">Prazo de investigação:</div>
                <S.InputToPDF>
                  {(acidente?.prazo_investigacao &&
                    formatNewDate(acidente?.prazo_investigacao)) ||
                    '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Observações:</div>
                <S.InputToPDF lines={4}>
                  {acidente?.observacao || '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Arquivo:</div>
                <S.InputToPDF>{getFileName(acidente?.arquivo)}</S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="normalText">Acidente evitável?</div>
                <S.InputToPDF>
                  {acidente?.acidente_evitavel ? 'Sim' : 'Não'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="normalText">Motorista voltou à operação?</div>
                <S.InputToPDF>
                  {acidente?.retorno_operacao ? 'Sim' : 'Não'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Exame psicológico:</div>
                <S.InputToPDF>
                  {getFileName(acidente?.exame_psicologico)}
                </S.InputToPDF>
              </Grid>
            </Grid>
          </div>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
