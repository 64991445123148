import { AddCircleOutline } from '@mui/icons-material';
import DefaultButton from 'components/Buttons/Default';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import colors from 'styles/colors';
import * as S from './styled';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from '../../../components/Loading';

const Empty = ({
  titleButton,
  query,
  setModalAdd,
  inSearch,
  width = '400px',
  forMe,
  tryed,
}) => {
  const img = require(`images/empty/desvio.png`);

  const { user } = useSelector(state => {
    return state.auth.user;
  });
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };

  return (
    <S.Container width={width}>
      {!inSearch && (
        <>
          <S.ImageArea>
            {!tryed && <img src={img} alt="Conteúdo vazio" />}
            {tryed && (
              <HeartBrokenIcon
                htmlColor={colors.grey}
                style={{ fontSize: 100 }}
              />
            )}
          </S.ImageArea>
          <S.ContentArea>
            <span className="textArea">
              {!forMe && (
                <h5>
                  Clique em "Criar novo certificado" e comece agora mesmo!
                </h5>
              )}
            </span>
            {setModalAdd && (
              <span className="buttonArea">
                <DefaultButton
                  children="Criar novo certificado"
                  onClick={() =>
                    !isConvidado ? setModalAdd(true) : handleConvidado()
                  }
                  startIcon={<AddCircleOutline />}
                />
              </span>
            )}
          </S.ContentArea>
        </>
      )}
      {inSearch && <Loading />}
    </S.Container>
  );
};

export default Empty;
