import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

// Material
import { Grid, Tooltip } from '@mui/material';
import SaveAlt from '@mui/icons-material/SaveAlt';

// Estilos
import { useTheme } from 'styled-components';

// Components
import Input from 'components/Inputs/TextField';
import TextInput from 'components/Inputs/TextField';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import { formatNewDate, formatNewHour } from 'utils/dates';
import { ReactComponent as FormIcon } from 'images/icons/sidebar/checklist.svg';
import ImageOcorrencia from './ImageOcorrencia';
import { ExportOcorrenciaPdf } from './Export';
import ModalSign from '../../components/ModalSign';

// Servicos
import * as request from '../services';

import ReviewModal from '../ReviewModal';
import * as S from './styled';

const PadDetail = () => {
  const theme = useTheme();
  const [ocorrencia, setOcorrencia] = useState(null);
  const [responsaveis, setResponsaveis] = useState(null);

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const [loadingViewButton, setLoadingViewButton] = useState(false);
  const [formHist, setFormHist] = useState(null);
  const [viewFormModal, setViewFormModal] = useState(false);
  const [currentHist, setCurrentHist] = useState(null);

  const [fileUrl, setFileUrl] = useState(null);

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [openConfirmFinishModal, setOpenConfirmFinishModal] = useState(false);

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const componentRef = useRef();
  const navigate = useNavigate();

  const { id } = useParams();

  const fetchData = async () => {
    const res = await request.requestOcorrencia(id);
    if (res.data) {
      setOcorrencia(res.data);
      setFileUrl(res.data ? res.data.arquivo_evidencia : null);
    }

    const res2 = await request.selectResponsibles();
    if (res2.data) {
      const resps = res2.data.map(usr => ({ name: usr.nome, value: usr.id }));
      setResponsaveis(resps);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // UseEffect para verificar as permissões
  useEffect(() => {
    if (ocorrencia && user) {
      const userId = user?.id;
      const userNivel = +user?.nivel;
      const responsavelOcorrenciaId = ocorrencia?.id_responsavel;
      const isResponsavelOcorreciaIdIgualUserId =
        userId === responsavelOcorrenciaId;

      // Check se o usuário tem permissão para acessar a ocorrência
      if (
        (userNivel === 3 || userNivel === 4) &&
        !isResponsavelOcorreciaIdIgualUserId
      ) {
        toast.error('Você não tem permissão para acessar esta ocorrência!', {
          toastId: 'permission-denied',
        });

        setTimeout(() => {
          navigate('/formularios/ocorrencias');
        }, 100);
      }
    }
  }, [ocorrencia]);

  useEffect(() => {
    if (printMode) handlePrint();
  }, [printMode]);

  const handleChange = (key, value) => {
    setOcorrencia(prev => {
      return { ...prev, [key]: value };
    });
  };

  const handleDeleteOcorrencies = async id => {
    setOpenConfirmDeleteModal(true);
  };

  const deleteOcorrencia = async () => {
    const res = await request.deleteOcorrencia(id);

    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }

    setOpenConfirmDeleteModal(false);
  };

  const handleSalvar = async () => {
    const data = {
      ids: [id],
      aluno: ocorrencia.aluno ? ocorrencia.aluno : null,
      id_responsavel: ocorrencia.id_responsavel
        ? ocorrencia.id_responsavel
        : null,
      status: ocorrencia.status ? ocorrencia.status : 'DELETADA',
      ocorrencia: ocorrencia.ocorrencia ? ocorrencia.ocorrencia : null,
      legenda_evidencia: ocorrencia.legenda_evidencia
        ? ocorrencia.legenda_evidencia
        : null,
      arquivo_evidencia: fileUrl || null,
      plano_de_acao: ocorrencia.plano_de_acao ? ocorrencia.plano_de_acao : null,
      observacao_responsavel: ocorrencia.observacao_responsavel
        ? ocorrencia.observacao_responsavel
        : '',
      data_evento: ocorrencia.data_evento ? ocorrencia.data_evento : null,
      data_vencimento: ocorrencia.data_vencimento
        ? ocorrencia.data_vencimento
        : null,
      data_encerramento: ocorrencia.data_encerramento
        ? ocorrencia.data_encerramento
        : null,
      data_exclusao: ocorrencia.data_exclusao ? ocorrencia.data_exclusao : null,
    };

    const res = await request.updateOcorrencia(data);

    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchData();
    } else if (res.data?.message) toast.error(res.data.message);
  };

  const [anchorMenu, setAnchorMenu] = useState(false);
  const openMenu = Boolean(anchorMenu);

  const handleMenu = e => setAnchorMenu(e.currentTarget);

  const handleExport = () => {
    handlePrint();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setPrintMode(false);
      setLoadingPdf(false);
    },
    documentTitle: `ocorrencia${ocorrencia?.id}`,
    pageStyle: `@page
    { size: auto; margin: 0mm; }
    @media print { body { -webkit-print-color-adjust: exact; padding: 20px !important; background:white !important;} }`,
  });

  const [loadingReview, setLoadingReview] = useState(false);
  const [openReview, setOpenReview] = useState(false);

  const handleReviewOcorrencies = async () => {
    setOpenReview(true);
  };

  const reviewOcorrencies = async data => {
    setLoadingReview(true);

    const res = await request.reviewOcorrencia(data);
    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else if (res.message) toast.error(res.message);

    setOpenReview(false);
    setLoadingReview(false);
  };

  const handleFinishOcorrencies = async () => {
    setOpenConfirmFinishModal(true);
  };

  const finishOcorrencies = async () => {
    const res = await request.finishOcorrencia([id]);

    if (res.success) {
      toast.success(res.message);
      fetchData();
    } else {
      toast.error(res.message);
    }

    setOpenConfirmFinishModal(false);
  };

  const handleViewForm = async () => {
    const id = ocorrencia.formulario.id;
    const histId = ocorrencia.resposta.historico.id;
    const alunoId = ocorrencia.aluno.id;
    const alunoTipo =
      ocorrencia.aluno.tipo.toUpperCase() === 'MOTORISTA'
        ? 'MOTORISTA'
        : 'GESTOR';

    setLoadingViewButton(true);
    setCurrentHist(id);
    const res = await request.requestFormResponse(
      id,
      histId,
      alunoId,
      alunoTipo,
    );

    if (res.success) {
      setFormHist(res);
      setViewFormModal(true);
    } else {
      toast.error(res.message);
    }

    setLoadingViewButton(false);
  };

  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhes da ocorrência</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<FormIcon />}
            size="medium"
            onClick={() => handleViewForm()}
            sx={{ marginLeft: '20px' }}
            disabled={loadingViewButton}
          >
            VER FORMULÁRIO
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={e => handleExport()}
            style={{ marginLeft: '10px' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  const renderInfo1 = () => {
    const hasImage = ocorrencia.aluno.foto ? ocorrencia.aluno.foto : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {ocorrencia.arquivo_evidencia && (
            <Grid item xs={12} sm={12}>
              <p
                style={{
                  color: theme.palette.words.subtitle.natural,
                  font: 'normal medium 14px/20px Texta',
                }}
              >
                Evidência
              </p>
              <p
                style={{
                  color: theme.palette.words.subtitle.light,
                  font: 'normal medium 14px/20px Texta',
                  marginTop: '16px',
                  marginBottom: '16px',
                }}
              >
                {ocorrencia?.legenda_evidencia
                  ? `${ocorrencia?.legenda_evidencia}`
                  : ''}
              </p>
              <ImageOcorrencia ocorrencia={ocorrencia} onlyImage={printMode} />
            </Grid>
          )}

          <Grid item xs={9} sm={9} display="flex">
            <S.StyledLogoWrapper>
              <Tooltip title={ocorrencia.aluno.filial?.nome} placement="top">
                <S.StyledLogo backgroundImage={hasImage}>
                  {!hasImage && <h2>{ocorrencia.aluno.nome[0]}</h2>}
                </S.StyledLogo>
              </Tooltip>
            </S.StyledLogoWrapper>
            <S.StyledTextWrapper>
              <h1>{ocorrencia.aluno.nome.toLowerCase()}</h1>
            </S.StyledTextWrapper>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Input
              label="Violação"
              value={ocorrencia.ocorrencia || ''}
              name="violacao_descricao"
              id="violacao_descricao"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Input
              label="Criado em"
              value={formatNewHour(ocorrencia.data_evento) || ''}
              name="createdAt"
              id="createdAt"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <Input
              label="Data encerramento"
              value={formatNewHour(ocorrencia.data_encerramento) || ''}
              name="encerramento"
              id="encerramento"
              InputProps={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          {ocorrencia.data_exclusao && (
            <Grid item xs={6} sm={6}>
              <Input
                label="Data exclusão"
                value={formatNewHour(ocorrencia.data_exclusao) || ''}
                name="data_exclusao"
                id="data_exclusao"
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
            </Grid>
          )}

          {renderFooterButtons1()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderInfo2 = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <>
            <Grid item xs={8} sm={8}>
              <Input
                label="Responsável"
                value={
                  ocorrencia.responsavel ? ocorrencia.responsavel.nome : ''
                }
                name="violacao_descricao"
                id="responsavel"
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <Input
                label="Data vencimento"
                value={
                  ocorrencia.data_vencimento
                    ? formatNewDate(ocorrencia.data_vencimento)
                    : ''
                }
                name="data_vencimento"
                id="data_vencimento"
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
            </Grid>
          </>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Plano de ação"
              placeholder="plano de ação"
              onChange={e => handleChange('plano_de_acao', e.target.value)}
              multiline
              disabled={false}
              rows={4}
              value={ocorrencia.plano_de_acao}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Inserir observações"
              onChange={e =>
                handleChange('observacao_responsavel', e.target.value)
              }
              multiline
              rows={4}
              value={ocorrencia.observacao_responsavel}
            />
          </Grid>
          {renderFooterButtons2()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderFooterButtons1 = () => {
    return (
      !printMode && (
        <Grid item xs={12} sm={12} display="flex" justifyContent="flex-start">
          {ocorrencia.status !== 'DELETADO' && (
            <GhostButton
              size="medium"
              customcolor={theme.palette.semantics.feedback.attention.natural}
              onClick={() => handleDeleteOcorrencies()}
            >
              EXCLUIR
            </GhostButton>
          )}
        </Grid>
      )
    );
  };

  const renderFooterButtons2 = () => {
    return (
      !printMode && (
        <>
          <Grid item xs={3} sm={3}>
            <GhostButton
              size="medium"
              onClick={() => navigate('/formularios/ocorrencias')}
              display="flex"
              justifyContent="flex-start"
            >
              VOLTAR
            </GhostButton>
          </Grid>
          <Grid item xs={9} sm={9} display="flex" justifyContent="flex-end">
            <GhostButton size="medium" onClick={() => handleSalvar()}>
              SALVAR
            </GhostButton>

            {ocorrencia.status === 'PENDENTE' && (
              <GhostButton
                size="medium"
                customcolor={theme.palette.semantics.feedback.warning.dark}
                onClick={() => handleReviewOcorrencies()}
                style={{ marginLeft: '10px' }}
              >
                Alterar responsável
              </GhostButton>
            )}

            {ocorrencia.status !== 'FINALIZADO' &&
              ocorrencia.status !== 'DELETADO' && (
                <DefaultButton
                  size="medium"
                  onClick={() => handleFinishOcorrencies()}
                  style={{ marginLeft: '10px' }}
                >
                  Finalizar
                </DefaultButton>
              )}
          </Grid>
        </>
      )
    );
  };

  const renderAssinaturas = () => {
    return (
      printMode && (
        <S.Card>
          <S.CardHeader>
            <h1>Assinaturas</h1>
          </S.CardHeader>
          <S.CardContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <S.Assinatura>
                  <S.AsignTitle>
                    {ocorrencia.responsavel ? (
                      ocorrencia.responsavel.nome.toLowerCase()
                    ) : (
                      <S.NoContent>Aguardando Assinatura</S.NoContent>
                    )}
                  </S.AsignTitle>
                  <S.AsignDate>{formatNewDate(new Date())}</S.AsignDate>
                  <hr />
                  <h4>Responsavel</h4>
                </S.Assinatura>
              </Grid>
              <Grid item xs={4}>
                <S.Assinatura>
                  <S.AsignTitle> Aguardando Assinatura</S.AsignTitle>
                  <S.AsignDate>{formatNewDate(new Date())}</S.AsignDate>
                  <hr />
                  <h4>Testemunha (opcional)</h4>
                </S.Assinatura>
              </Grid>
              <Grid item xs={4}>
                <S.Assinatura>
                  <S.AsignTitle>
                    {ocorrencia.motorista.nome.toLowerCase()}
                  </S.AsignTitle>
                  <S.AsignDate>{formatNewDate(new Date())}</S.AsignDate>
                  <hr />
                  <h4>Motorista</h4>
                </S.Assinatura>
              </Grid>
            </Grid>
          </S.CardContent>
        </S.Card>
      )
    );
  };

  return ocorrencia && responsaveis ? (
    <>
      {renderHeader()}
      <S.Main /* ref={componentRef} */>
        <Grid
          container
          spacing="20px"
          marginTop="10px"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderInfo1()}
          </Grid>

          {printMode && <Grid item xs={12} height="350px" />}

          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderInfo2()}
          </Grid>

          <ExportOcorrenciaPdf
            ocorrencia={ocorrencia}
            aluno={ocorrencia.aluno}
            printRef={componentRef}
          />
        </Grid>
      </S.Main>
      <ConfirmModal
        handleClose={() => setOpenConfirmDeleteModal(false)}
        open={!!openConfirmDeleteModal}
        title="Deseja excluir ocorrência?"
        // subtitle="Seus pontos deixarão de contar para este motorista."
        buttonText="Confirmar"
        onClick={() => deleteOcorrencia()}
      />
      <ConfirmModal
        handleClose={() => setOpenConfirmFinishModal(false)}
        open={!!openConfirmFinishModal}
        title="Deseja finalizar esta ocorrência?"
        subtitle="O status desta
      ocorrência será atualizado para Finalizado."
        buttonText="Confirmar"
        onClick={() => finishOcorrencies()}
      />
      {openReview && (
        <ReviewModal
          handleConfirm={reviewOcorrencies}
          ids={[id]}
          handleClose={() => setOpenReview(false)}
          responsaveis={responsaveis}
          loading={loadingReview}
        />
      )}
      {formHist && viewFormModal && (
        <ModalSign
          open={viewFormModal}
          onClose={() => {
            setViewFormModal(false);
            setFormHist(false);
            setCurrentHist(null);
          }}
          form={formHist}
          histId={currentHist}
          refetch={handleViewForm}
          loading={loadingViewButton}
          backPath={`/formularios/ocorrencias/${ocorrencia.id}`}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default PadDetail;
