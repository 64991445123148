import { ReactComponent as pen } from 'images/formulario/pen.svg';
import { ReactComponent as multipla_escolha } from 'images/formulario/multipla_escolha.svg';
import { ReactComponent as selecao_unica } from 'images/formulario/escolha_unica.svg';
import { ReactComponent as conformidade } from 'images/formulario/conformidade.svg';
import { ReactComponent as selecao } from 'images/formulario/selecao.svg';
import { ReactComponent as texto_curto } from 'images/formulario/texto_curto.svg';
import { ReactComponent as texto_longo } from 'images/formulario/texto_longo.svg';
import { ReactComponent as numero_simples } from 'images/formulario/numero_simples.svg';
import { ReactComponent as moeda } from 'images/formulario/moeda.svg';
import { ReactComponent as porcentagem } from 'images/formulario/porcentagem.svg';
import { ReactComponent as data } from 'images/formulario/data.svg';
import { ReactComponent as hora } from 'images/formulario/hora.svg';
import { ReactComponent as data_hora } from 'images/formulario/data_hora.svg';
import { ReactComponent as gps } from 'images/formulario/gps.svg';
import { ReactComponent as fotos } from 'images/formulario/fotos.svg';
import { ReactComponent as empresa } from 'images/formulario/empresa.svg';
import { ReactComponent as filial } from 'images/formulario/filial.svg';
import { ReactComponent as funcionario } from 'images/formulario/funcionario.svg';
import { ReactComponent as placa } from 'images/formulario/placa.svg';
import { ReactComponent as gestor } from 'images/formulario/gestor.svg';
// import { ReactComponent as motorista} from 'images/formulario/motorista.svg';
// import { ReactComponent as cliente} from 'images/formulario/cliente.svg';
import { ReactComponent as operacao } from 'images/formulario/operacao.svg';
import { ReactComponent as carregamento } from 'images/formulario/carregamento.svg';
import { ReactComponent as modelo_veiculo } from 'images/formulario/modelo_veiculo.svg';
import { ReactComponent as frota } from 'images/formulario/frota.svg';
import { ReactComponent as departamento } from 'images/formulario/departamento.svg';
import { ReactComponent as cambio } from 'images/formulario/cambio.svg';
import { ReactComponent as propriedade } from 'images/formulario/propriedade.svg';

// Components
import { MultiplaEscolha } from './Fields/MultiplaEscolha';
import { EscolhaUnica } from './Fields/EscolhaUnica';
import { Conformidade } from './Fields/Conformidade';
import { Selecao } from './Fields/Selecao';
import { TextoCurto } from './Fields/TextoCurto';
import { TextoLongo } from './Fields/TextoLongo';
import { ModalGenerico } from './Fields/GenericModal';
import { Moeda } from './Fields/Moeda';
import { Data } from './Fields/Data';
import { Hora } from './Fields/Hora';
import { Data_Hora } from './Fields/Data_Hora';
import { ModalAutomaticoGenerico } from './Fields/GenericAutomaticModal';

// Response Components
// Gerais
import { ResponseMultiplaEscolha } from './FieldsResponse/withQuestion/MultiplaEscolha';
import { ResponseEscolhaUnica } from './FieldsResponse/withQuestion/EscolhaUnica';
import { ResponseSelecao } from './FieldsResponse/withQuestion/Selecao';
import { ResponseTexto } from './FieldsResponse/withQuestion/Texto';
import { ResponseNumero } from './FieldsResponse/withQuestion/Numero';
import { ResponseMoeda } from './FieldsResponse/withQuestion/Moeda';
import { ResponsePorcentagem } from './FieldsResponse/withQuestion/Porcentagem';
import { ResponseData } from './FieldsResponse/withQuestion/Data';
import { ResponseHora } from './FieldsResponse/withQuestion/Hora';
import { ResponseDataHora } from './FieldsResponse/withQuestion/Data_Hora';
import { ResponseGps } from './FieldsResponse/withQuestion/Gps';
import { ResponseFoto } from './FieldsResponse/withQuestion/Fotos';

// Autos
import { ResponseDisabled } from './FieldsResponse/outQuestion/DisabledDefault';
import { SelectSearch } from './FieldsResponse/outQuestion/SelectSearch';
import { SelectClient } from './FieldsResponse/outQuestion/SelectClient';
import { SelectSimple } from './FieldsResponse/outQuestion/SelectSimple';

// Atenção, o id de todos os campos deve estar de acordo com a tabela do banco
// formulario_tipos_campos

export const items = [
  {
    section: 'Gerais',
    description:
      'Ao selecionar um campo, você poderá definir uma pergunta e as opções de resposta do usuário de acordo com o formato do item selecionado.  Alguns do items permitem gerenciar criação de ocorrências e solicitção de evidências',
    types: [
      {
        id_tipo_campo: 1,
        nome: 'Multipla Escolha',
        icone: multipla_escolha,
        tipo: 'multipla_escolha',
        Component: params => MultiplaEscolha(params),
        ResponseComponent: params => ResponseMultiplaEscolha(params),
        ViewComponent: params => ResponseMultiplaEscolha(params),
      },
      {
        id_tipo_campo: 2,
        nome: 'Seleção Única',
        icone: selecao_unica,
        tipo: 'selecao_unica',
        Component: params => EscolhaUnica(params),
        ResponseComponent: params => ResponseEscolhaUnica(params),
        ViewComponent: params => ResponseEscolhaUnica(params),
      },
      {
        id_tipo_campo: 3,
        nome: 'Conformidade',
        icone: conformidade,
        tipo: 'conformidade',
        Component: params => Conformidade(params),
        ResponseComponent: params => ResponseEscolhaUnica(params),
        ViewComponent: params => ResponseEscolhaUnica(params),
      },
      {
        id_tipo_campo: 4,
        nome: 'Seleção',
        icone: selecao,
        tipo: 'selecao',
        Component: params => Selecao(params),
        ResponseComponent: params => ResponseSelecao(params),
        ViewComponent: params => ResponseSelecao(params),
      },
      {
        id_tipo_campo: 5,
        nome: 'Texto Curto',
        icone: texto_curto,
        tipo: 'texto_curto',
        Component: params => TextoCurto(params),
        ResponseComponent: params => ResponseTexto(params),
        ViewComponent: params => ResponseTexto(params),
      },
      {
        id_tipo_campo: 6,
        nome: 'Texto Longo',
        icone: texto_longo,
        tipo: 'texto_longo',
        Component: params => TextoLongo(params),
        ResponseComponent: params => ResponseTexto(params),
        ViewComponent: params => ResponseTexto(params),
      },
      {
        id_tipo_campo: 7,
        nome: 'Numero Simples',
        icone: numero_simples,
        tipo: 'numero_simples',
        Component: params => ModalGenerico(params),
        ResponseComponent: params => ResponseNumero(params),
        ViewComponent: params => ResponseNumero(params),
      },
      {
        id_tipo_campo: 8,
        nome: 'Moeda',
        icone: moeda,
        tipo: 'moeda',
        Component: params => Moeda(params),
        ResponseComponent: params => ResponseMoeda(params),
        ViewComponent: params => ResponseMoeda(params),
      },
      {
        id_tipo_campo: 9,
        nome: 'Porcentagem',
        icone: porcentagem,
        tipo: 'porcentagem',
        Component: params => ModalGenerico(params),
        ResponseComponent: params => ResponsePorcentagem(params),
        ViewComponent: params => ResponsePorcentagem(params),
      },
      {
        id_tipo_campo: 10,
        nome: 'Data',
        icone: data,
        tipo: 'data',
        Component: params => Data(params),
        ResponseComponent: params => ResponseData(params),
        ViewComponent: params => ResponseData(params),
      },
      {
        id_tipo_campo: 11,
        nome: 'Hora',
        icone: hora,
        tipo: 'hora',
        Component: params => Hora(params),
        ResponseComponent: params => ResponseHora(params),
        ViewComponent: params => ResponseHora(params),
      },
      {
        id_tipo_campo: 12,
        nome: 'Data & Hora',
        icone: data_hora,
        tipo: 'data_hora',
        Component: params => Data_Hora(params),
        ResponseComponent: params => ResponseDataHora(params),
        ViewComponent: params => ResponseDataHora(params),
      },
      {
        id_tipo_campo: 13,
        nome: 'GPS',
        icone: gps,
        tipo: 'gps',
        Component: params => ModalGenerico(params),
        ResponseComponent: params => ResponseGps(params),
        ViewComponent: params => ResponseGps(params),
      },
      {
        id_tipo_campo: 14,
        nome: 'Fotos',
        icone: fotos,
        tipo: 'fotos',
        Component: params => ModalGenerico(params),
        ResponseComponent: params => ResponseFoto(params),
        ViewComponent: params => ResponseFoto(params),
      },
    ],
  },
  {
    section: 'Automáticos',
    description:
      'Ao selecionar um campo, você poderá definir uma pergunta que o usuário poderá responder seguindo as opções disponíveis no Onisys ou que já serão respondidos automaticamente.',
    types: [
      {
        id_tipo_campo: 31,
        nome: 'Localização (auto)',
        icone: gps,
        tipo: 'gps_auto',
        example: 'Ex: -15.721487,-48.1021699',
        notRequired: true,
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: () => {
          return <></>;
        },
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 15,
        nome: 'Empresa',
        icone: empresa,
        tipo: 'empresa',
        example: 'Ex: Transpedrosa',
        notRequired: true,
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => ResponseDisabled(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 16,
        nome: 'Filial (Preenchido)',
        icone: filial,
        tipo: 'filial',
        example: 'Ex: Filial Nordeste',
        notRequired: true,
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => ResponseDisabled(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 17,
        nome: 'Usuário',
        icone: funcionario,
        tipo: 'funcionario',
        example: 'Ex: João Silva',
        notRequired: true,
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => ResponseDisabled(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 18,
        nome: 'Data & Hora (auto)',
        icone: data_hora,
        tipo: 'data_hora_auto',
        example: 'Ex: 14/07/2022 - 16:02',
        notRequired: true,
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => ResponseDisabled(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 19,
        nome: 'Placa',
        icone: placa,
        tipo: 'placa',
        isSelect: true,
        example: 'Ex: BHZ 2521',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSearch(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 20,
        nome: 'Gestor',
        icone: gestor,
        tipo: 'gestor',
        isSelect: true,
        example: 'Ex: Bruno Almeida de Assis',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSearch(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 21,
        nome: 'Motorista',
        icone: pen,
        tipo: 'motorista',
        isSelect: true,
        example: 'Ex: Carlos Duarte Silva',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSearch(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 22,
        nome: 'Cliente',
        icone: pen,
        tipo: 'cliente',
        isSelect: true,
        example: 'Ex: Raizen',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectClient(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 23,
        nome: 'Operação',
        icone: operacao,
        tipo: 'operacao',
        isSelect: true,
        example: 'Ex: Primária',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSimple(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 24,
        nome: 'Carregamento',
        icone: carregamento,
        tipo: 'carregamento',
        isSelect: true,
        example: 'Ex: Grãos',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSimple(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 25,
        nome: 'Modelo do VeÍculo',
        icone: modelo_veiculo,
        tipo: 'modelo_veiculo',
        isSelect: true,
        example: 'Ex: Trucker Plus',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSearch(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 26,
        nome: 'Frota',
        icone: frota,
        tipo: 'frota',
        isSelect: true,
        example: 'Ex: Frota 47',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSearch(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 27,
        nome: 'Departamento',
        icone: departamento,
        tipo: 'departamento',
        isSelect: true,
        example: 'Ex: Marketing',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSimple(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 28,
        nome: 'Cambio',
        icone: cambio,
        tipo: 'cambio',
        isSelect: true,
        example: 'Ex: Manual',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSimple(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 29,
        nome: 'Propriedade',
        icone: propriedade,
        tipo: 'propriedade',
        isSelect: true,
        example: 'Ex: Própria',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSimple(params),
        ViewComponent: params => ResponseDisabled(params),
      },
      {
        id_tipo_campo: 30,
        nome: 'Filial (Selecionar)',
        icone: filial,
        tipo: 'filial_selecionar',
        isSelect: true,
        example: 'Ex: Filial Divinópolis',
        Component: params => ModalAutomaticoGenerico(params),
        ResponseComponent: params => SelectSimple(params),
        ViewComponent: params => ResponseDisabled(params),
      },
    ],
  },
];
