// React
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteTemplate } from '../../service';

// Components
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import AlertModal from 'components/AlertModal';

// Utils
import * as S from './styled';
import { formatTitle } from '../../../actions';
import { useTheme } from 'styled-components';

// Images
import cardsCertificado from '../../../../../images/capacitacao/cards_certificado.svg';

const ContentCard = ({
  template,
  loadingHistoryLine,
  loadingScreen,
  loadingCard,
  forMe,
  onDelete,
}) => {
  const theme = useTheme();
  const [screenView, setScreenView] = useState('100%');
  const [inHover, setInHover] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const navigate = useNavigate();

  // Função para contar assinaturas ativas
  const countActiveSignatures = () => {
    let count = 0;
    if (template.assinatura_1_ativo) count++;
    if (template.assinatura_2_ativo) count++;
    if (template.assinatura_3_ativo) count++;
    return count;
  };

  // -------------------------CALLED BY LIST -----------------------//
  const edit = async id => {
    setCurrentId(id);
    navigate(`/capacitacao/certificados/${id}`);
    setCurrentId(null);
  };

  // -------------------------CALLED BY FORM ME -----------------------//
  const handleDeleteClick = templateId => {
    setTemplateToDelete(templateId);
    setShowDeleteModal(true);
    setDeleteError(null); // Reset any previous errors
  };

  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true);
      const response = await deleteTemplate(templateToDelete);

      if (response.success) {
        // Close modal and notify parent component
        setShowDeleteModal(false);
        setTemplateToDelete(null);
        if (onDelete) {
          onDelete(templateToDelete);
        }
      } else {
        setDeleteError(response.message || 'Erro ao excluir template');
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      setDeleteError('Erro ao excluir template. Por favor, tente novamente.');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <S.Wrapper
        className="cardContent"
        charge={loadingCard}
        loadingScreen={loadingScreen}
        inHover={inHover}
        onMouseLeave={() => {
          setInHover(false);
        }}
        onMouseEnter={() => {
          setInHover(true);
        }}
      >
        {(loadingCard && currentId === template.id) || isDeleting ? (
          <S.LoadingBox>
            <Loading />
          </S.LoadingBox>
        ) : null}
        <>
          <S.Image
            loadingScreen={loadingScreen || !template?.titulo}
            src={cardsCertificado}
            finished={template.inactivated_at !== null}
          >
            <div className="preLoad" />
          </S.Image>

          <S.Content
            loadingScreen={loadingScreen || !template?.titulo}
            screenView={screenView}
            inHover={inHover}
            className="content"
            forMe={forMe}
            finished={template.inactivated_at !== null}
            inLineLoading={loadingHistoryLine}
          >
            <div className="screenPrimary">
              <h6 className="title">{formatTitle(template.titulo)}</h6>

              <span>Certificamos que ...</span>

              <S.MediaContainer forMe={false} inHover={inHover}>
                <S.SignatureCount>
                  {`${countActiveSignatures()} ${
                    countActiveSignatures() === 1 ? 'assinatura' : 'assinaturas'
                  }`}
                </S.SignatureCount>
              </S.MediaContainer>

              <S.Buttons forMe={false} inHover={inHover}>
                <GhostButton
                  children="Editar"
                  style={{ width: '100%', marginBottom: '10px' }}
                  onClick={() => edit(template?.id)}
                />

                <GhostButton
                  children="Excluir"
                  style={{ width: '100%' }}
                  onClick={() => handleDeleteClick(template?.id)}
                  disabled={template.inactivated_at !== null}
                  customcolor={
                    theme.palette.semantics.feedback.attention.natural
                  }
                />
              </S.Buttons>
            </div>
            <div className="preLoad" />
          </S.Content>
          <AlertModal
            open={showDeleteModal}
            handleClose={() => {
              setShowDeleteModal(false);
              setDeleteError(null);
            }}
            onClick={handleConfirmDelete}
            title={`Excluir ${template.titulo}?`}
            subtitle={
              deleteError ||
              'Caso esse certificado esteja associado a algum conteúdo, ele voltará ao modelo padrão. Deseja prosseguir?'
            }
            buttonText="Excluir"
            backButtonText="Cancelar"
            loading={isDeleting}
            error={deleteError}
          />
        </>
      </S.Wrapper>
    </>
  );
};

export default ContentCard;
