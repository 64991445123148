import React from 'react';
import {
  NavigationContainer,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
} from './styles';
import { useNavigate } from 'react-router-dom';

const NavigationPath = ({ paths }) => {
  const navigate = useNavigate();
  return (
    <NavigationContainer aria-label="breadcrumb">
      <BreadcrumbList>
        {paths.map((path, index) => (
          <BreadcrumbItem key={path.href}>
            <BreadcrumbLink
              onClick={() => navigate(path.href)}
              className={index === paths.length - 1 ? 'active' : ''}
              aria-current={index === paths.length - 1 ? 'page' : undefined}
            >
              {path.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </NavigationContainer>
  );
};

export default NavigationPath;
