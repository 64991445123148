import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import DefaultButton from 'components/Buttons/Default';
import Calendar from 'components/Inputs/Calendar';
import TextField from 'components/Inputs/TextField';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import { QuizContainer, Option, OptionsContainer } from '../../Criar/styled';

import * as S from '../styled';
import { usePlans } from 'hooks/usePlans';

const Config = ({ acidente }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { isProvider } = usePlans();

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {isProvider ? (
            <>
              <div className="imageArea">
                {acidente?.logo_empresa ? (
                  <img src={acidente?.logo_empresa} />
                ) : (
                  <span className="letters">{acidente?.empresa[0]}</span>
                )}
              </div>
              <div>
                <div className="distributorArea">Empresa</div>
                <div className="distributorName">{acidente?.empresa}</div>
              </div>
            </>
          ) : (
            <>
              <div className="imageArea">
                {acidente?.logo_cliente ? (
                  <img src={acidente?.logo_cliente} />
                ) : (
                  <span className="letters">{acidente?.cliente[0]}</span>
                )}
              </div>
              <div>
                <div className="distributorArea">Cliente</div>
                <div className="distributorName">{acidente?.cliente}</div>
              </div>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={12} mt={2}>
          <Calendar
            disabled
            id="prazo_investigacao"
            value={acidente?.prazo_investigacao}
            label="Prazo de investigação"
            name="prazo_investigacao"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled
            label="Observações"
            name="observacao"
            placeholder="Preencha a observação"
            id="observacao"
            value={acidente?.observacao}
            multiline
            rows={4}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <InputAttachedFile
            disabled
            idInput="arquivo"
            label="Arquivo"
            inputLabel="Anexar Arquivo"
            fileUrl={acidente?.arquivo}
            fileDir="acidentes/arquivos"
          />
        </Grid>

        <Grid item xs={12} mt={2}>
          <QuizContainer>
            <h2>O acidente foi evitável?</h2>

            <OptionsContainer>
              <Option
                type="button"
                active={acidente?.acidente_evitavel}
                disabled
              >
                Evitável
              </Option>
              <Option
                type="button"
                active={
                  !acidente?.acidente_evitavel &&
                  acidente?.acidente_evitavel != null
                }
                disabled
              >
                Inevitável
              </Option>
            </OptionsContainer>
          </QuizContainer>
        </Grid>

        <Grid item xs={12} mt={1}>
          <QuizContainer>
            <span>
              Considerando todas as ameaças presentes, o motorista tomou as
              ações necessárias? Para os acidentes causados por falha mecânica,
              deve ser analisada a possibilidade do motorista ter identificado
              algum indício que possibilitasse uma ação preventiva (poucos
              freios, ruídos, etc). Em caso positivo, será considerado evitável,
              caso contrário, o acidente deve ser considerado inevitável.
            </span>
          </QuizContainer>
        </Grid>

        <Grid item xs={12} mt={2}>
          <QuizContainer>
            <div style={{ flex: 1 }}>
              <h2>Motorista voltou à operação?</h2>
            </div>
            <OptionsContainer>
              <Option
                type="button"
                active={acidente?.retorno_operacao}
                disabled
              >
                SIM
              </Option>
              <Option
                type="button"
                active={
                  !acidente?.retorno_operacao &&
                  acidente?.retorno_operacao != null
                }
                disabled
              >
                NÃO
              </Option>
            </OptionsContainer>
          </QuizContainer>
        </Grid>

        {!!acidente?.retorno_operacao && (
          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              disabled
              idInput="arquivo"
              label="Exame psicológico"
              inputLabel="Anexar Arquivo"
              fileUrl={acidente?.exame_psicologico}
              fileDir="acidentes/arquivos"
            />
          </Grid>
        )}

        <Grid item xs={12} mt={2} display="flex" justifyContent="end" gap={1}>
          <DefaultButton
            children="Editar"
            onClick={() => navigate(`/acidentes/editar/${params.id}`)}
            disabled={
              (!isProvider && acidente?.provider) ||
              (isProvider && !acidente?.provider)
            }
          />
        </Grid>
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Config;
