import styled, { css, keyframes } from 'styled-components';
import { OutlinedInput } from '@mui/material';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

const Container = styled.div`
  position: relative;
  transition: all ease 0.9s;
`;

export const CustomSelect = styled(Select)(({ theme, selected }) => ({
  padding: 0,
  border: 'none',
  outline: 'none',
  boxShadow: 'none',
  background: selected ? theme.palette.brand.primary.natural : '#fff',
  color: '#fff',
  borderRadius: '20px',
  height: '32px',
  minWidth: 0,
  '.title-select': {
    color: !selected ? '#4B5166' : '#fff',
    fontFamily: 'Texta',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '25px',
  },
  '& .MuiSelect-select': {
    padding: '6px 16px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    color: !selected ? '#4B5166' : '#fff',
  },
  '& .MuiSelect-icon': {
    color: !selected ? '#4B5166' : '#fff',
    fontSize: '18px',
  },
  '& fieldset': {
    border: 'none !important',
  },
  '&.Mui-focused fieldset': {
    border: 'none',
  },
  '&:focus': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  '&:hover': {
    background: selected ? theme.palette.brand.primary.natural : '#FAFAFA',
    opacity: selected ? 0.7 : 1,
  },
}));

export const Input = styled(OutlinedInput)`
  ${({ theme }) => css`
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid ${theme.palette.brand.primary.light};
    }
    .MuiOutlinedInput-notchedOutline.error {
      border: 2px solid ${theme.palette.semantics.feedback.attention.natural};
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 16px;
      font-weight: 400;
      font-family: 'Texta';
      opacity: 1;
    }
    .Mui-disabled {
      background-color: ${theme.palette.semantics.feedback.unknown.light},
      border-radius: '5px',
    }
`}
`;

const Label = styled.p`
  ${({ theme }) => theme.palette.words.label.natural};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
`;

const Selecteds = styled.div`
  color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
  opacity: ${props => props.opacity};
  transition: all ease 0.5s;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: red;
`;

const SearchBox = styled.div`
  width: 100%;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
`;

export const LineBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const SubTitle = styled.div`
  width: 50%;
  font-family: 'Texta';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #4b5166;
  margin-bottom: 14px;
  margin-top: 8px;
  margin-left: 10px;
`;
export const SelectAll = styled.div`
  font-family: 'Texta';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #ff8040;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
`;

const SearchInput = styled(OutlinedInput)`
  ${({ theme }) => css`
    width: 100%;
    border: none;
    text-align: left;
    border-radius: 5px;
    font-size: 16px;
    height: 40px;
    background-color: #fff;
    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      background-color: #fff;
      border: none;
      box-shadow: none;
    }

    & .MuiOutlinedInput-notchedOutline {
      box-shadow: none;
      border: 1px solid #edf2f9;
    }
  `}
`;

const CheckboxStyled = styled(Checkbox)`
  ${({ theme }) => css`
    &.MuiCheckbox-root {
      border-radius: 20px;
      color: rgba(149, 170, 201, 0.3);
      padding: 6px 12px;
    }

    &.Mui-checked {
      color: ${theme.palette.brand.primary.natural};
    }
    & .MuiSvgIcon-root {
      font-size: 30px;
    }
  `}
`;

const LabelCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, ...p }) =>
    !p.checked ? '#EDF2F9' : theme.palette.brand.primary.natural};
  border-radius: 100px;
  font-family: 'Texta';
  font-style: normal;
  padding: 0px 16px;
  height: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: ${p => (p.checked ? '#fff' : '#4b5166 ')};
  max-width: 30ch;
  overflow: hidden;
  text-overflow: clip ellipsis;
  text-overflow: '…' '…';
  white-space: nowrap;
  &:hover {
    opacity: 0.8;
  }
`;

const ClearSelection = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    text-align: center;
    color: ${theme.palette.semantics.feedback.unknown.dark};
    font-weight: 600;
    width: 100%;
    cursor: pointer;
  `}
`;

const OptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  overflow-y: scroll;
  max-height: 354px;
  scrollbar-width: 10px;
  scrollbar-color: #4a4a70 #f1f1f1;
  margin-right: 10px;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4a4a70;
    border-radius: 20px;
    border: 3px solid #f1f1f1;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const LabelSearch = styled.label`
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    margin-left: 15px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  }
`;

const EmptyOptions = styled.span`
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.words.placeholder.light};
`;
const SeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;

  .textSeeMore {
    font-size: 18px;
    font-weight: 600;
    color: #4b5166;
    cursor: pointer;
  }

  .divIcon {
    background-color: red;
    animation: ${jump} 1s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: absolute;
    }
  }
`;

export {
  Label,
  Required,
  SearchBox,
  SearchInput,
  LabelSearch,
  OptionsArea,
  ClearSelection,
  Selecteds,
  Container,
  EmptyOptions,
  SeeMore,
  CheckboxStyled,
  LabelCheckBox,
};
