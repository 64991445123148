// Styled

// React
import { useEffect, useState } from 'react';

// Components
import Radio from 'pages/Fomularios/Fields/Components/Radio';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Actions
import { changeCheck, addImage, editImage, deleteImage } from '../../actions';

export const ResponseEscolhaUnica = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosEvidencias,
  errosObrigatorios,
  preview,
  previewWeb,
  approveView,
}) => {
  const [cleanError, setCleanError] = useState([]);

  const redirectFunction = (executable, idResposta, idCampo, item) => {
    if (errosEvidencias) setCleanError([...cleanError, idResposta]);
    executable(idResposta, idCampo, item, setSecoes, secoes, sectionId);
  };

  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [hasErrorEvidencia, setHasErrorEvidencia] = useState(false);
  const [wrongOptions, setWrongOptions] = useState([]);
  const [enderecos, setEnderecos] = useState({});

  const getEndereco = async () => {
    field.opcoes.forEach(opcao => {
      // Verifica se opcao.arquivos existe, é um array e tem elementos
      if (
        opcao.arquivos &&
        Array.isArray(opcao.arquivos) &&
        opcao.arquivos.length > 0
      ) {
        // Para cada arquivo na opção
        opcao.arquivos.forEach(arquivo => {
          // Verifica se o arquivo tem latitude e longitude
          if (arquivo.latitude && arquivo.longitude) {
            const latitude = String(arquivo.latitude).replace(',', '.');
            const longitude = String(arquivo.longitude).replace(',', '.');

            const obterEndereco = async (lat, long, arquivoId) => {
              try {
                const response = await fetch(
                  `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
                );
                const data = await response.json();
                // Armazena o endereço usando o ID do arquivo como chave
                setEnderecos(prev => ({
                  ...prev,
                  [arquivoId]: data.display_name,
                }));
              } catch (error) {
                console.error('Erro ao obter endereço:', error);
                setEnderecos(prev => ({
                  ...prev,
                  [arquivoId]: 'Endereço não encontrado',
                }));
              }
            };

            obterEndereco(latitude, longitude, arquivo.id);
          }
        });
      }
    });
  };

  useEffect(() => {
    getEndereco();
    // Reset fields
    setHasErrorObrigatorio(false);
    setHasErrorEvidencia(false);
    setWrongOptions([]);
    setCleanError([]);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }

    if (errosEvidencias) {
      const wrongs = [];
      let hasError = false;
      for (const i in errosEvidencias) {
        const erros = errosEvidencias[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          const { opcoes } = field;
          for (const j in opcoes) {
            if (opcoes[j].id === erros.idResposta) {
              wrongs.push(erros.idResposta);
              hasError = true;
            }
          }
        }
      }
      setWrongOptions(wrongs);
      setHasErrorEvidencia(hasError);
    }
  }, [errosEvidencias, errosObrigatorios]);

  const formatarData = data => {
    if (!data) return '';
    const dataObj = new Date(data);
    const opcoes = {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return dataObj.toLocaleDateString('pt-BR', opcoes).replace(',', '');
  };

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanError.length}
        className={`fieldClass-${field.id}`}
        preview={preview}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanError.length}
          preview={preview}
          approveView={approveView}
        />
        {field?.opcoes?.map((opcao, key) => (
          <S.ResponseArea
            isChecked={opcao?.checked}
            key={key}
            preview={preview}
          >
            <span className="checkarea">
              <Radio
                value={opcao.checked}
                label={opcao.item}
                onChange={() =>
                  redirectFunction(changeCheck, opcao?.id, field?.id, 'unic')
                }
                error={hasErrorObrigatorio && !cleanError.length}
                preview={preview}
                disabled={approveView || preview || previewWeb}
              />
            </span>
            {opcao?.evidencia && (
              <div className="iconsBox">
                <DropZoneArchives
                  data={opcao?.arquivos}
                  multiple={false}
                  fileDir="formularios/evidencias"
                  addFunction={item =>
                    redirectFunction(addImage, opcao?.id, field?.id, item)
                  }
                  editFunction={item =>
                    redirectFunction(editImage, opcao?.id, field?.id, item)
                  }
                  deleteFunction={item =>
                    redirectFunction(deleteImage, opcao?.id, field?.id, item)
                  }
                  saveNow
                  setSaveNow={() => {}}
                  minimal
                  minimalText={!approveView ? 'Adicionar evidência' : null}
                  minimalRequired
                  onlyRead={approveView}
                  haveLegend
                  newImagesByModal="Alterar Evidência"
                  error={
                    hasErrorEvidencia &&
                    wrongOptions.includes(opcao?.id) &&
                    !cleanError.includes(opcao?.id)
                  }
                  tooltip={approveView}
                />
              </div>
            )}
            {/* {console.log('Opcao:', opcao)} */}
            {opcao?.evidencia &&
              opcao?.arquivos &&
              Array.isArray(opcao?.arquivos) &&
              opcao?.arquivos.length > 0 &&
              opcao?.arquivos.map((arquivo, index) => (
                <div
                  key={index}
                  className="print-container"
                  style={{
                    marginTop: '30px',
                    position: 'relative',
                    display: 'flex',
                  }}
                >
                  <img
                    src={arquivo?.imagem_url}
                    alt={`Imagem do opcao ${opcao?.id}`}
                    style={{
                      width: '30%',
                      height: 'auto',
                      paddingRight: 15,
                    }}
                  />
                  <div>
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#666',
                        margin: '5px 0 0',
                      }}
                    >
                      {formatarData(arquivo?.data_evidencia)}
                    </p>
                    <p
                      style={{
                        fontSize: '12px',
                        margin: '0',
                      }}
                    >
                      {enderecos[arquivo?.id] || ''}
                    </p>
                  </div>
                </div>
              ))}
          </S.ResponseArea>
        ))}
      </S.QuestionBox>
      <Divider />
    </>
  );
};
