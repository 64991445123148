import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { PlayCircle, Close, OpenInFull } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import ImageModal from 'components/ImageModal';
import { firestorageVideos } from 'utils/firebase';
import * as S from './styled';

const ImageDesvio = ({ imagem, video, onlyImage, frame_pos }) => {
  const theme = useTheme();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoActive, setVideoActive] = useState(false);
  const [expandedImage, setExpandedImage] = useState(false);

  useEffect(() => {
    const getVideoData = () => {
      const caminho =
        process.env.REACT_APP_ENV !== 'production'
          ? 'Transpedrosa/Moveit Transpedrosa/10207#ADRIANO DOUGLAS LOPES/2022-39/10207#ADRIANO DOUGLAS LOPES_2022-39.mp4'
          : video || '';
      if (caminho) {
        const caminhoIsAbsolute = caminho.includes('http');
        const storageRef = caminhoIsAbsolute
          ? firestorageVideos.refFromURL(caminho)
          : firestorageVideos.ref(caminho);

        storageRef
          .getDownloadURL()
          .then(fireBaseUrl => {
            if (fireBaseUrl) {
              let video_url = fireBaseUrl;
              if (frame_pos) {
                video_url = `${fireBaseUrl}#t=${frame_pos - 5},${
                  frame_pos + 20
                }`;
              }
              setVideoUrl(video_url);
              // Se tiver vídeo e imagem, ativa o vídeo por padrão
              if (imagem && !onlyImage) {
                setVideoActive(true);
              }
            }
          })
          .catch(err => console.log(err));
      }
    };

    getVideoData();
  }, [video, frame_pos, imagem, onlyImage]);

  // Determina se deve mostrar o vídeo
  const shouldShowVideo = videoUrl && !onlyImage && (videoActive || !imagem);

  // Determina se deve mostrar a imagem
  const shouldShowImage = imagem && (onlyImage || !videoActive);

  return (
    <>
      {shouldShowVideo && (
        <S.VideoContainer>
          <ReactPlayer
            url={videoUrl}
            controls
            playing
            width="100%"
            height="100%"
          />
          {imagem && !onlyImage && (
            <span onClick={() => setVideoActive(false)}>
              <Close
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </span>
          )}
        </S.VideoContainer>
      )}

      {shouldShowImage && (
        <S.ImageContainer>
          <img src={imagem} alt="Content" />

          {video && videoUrl && !onlyImage && (
            <div className="playVideo" onClick={() => setVideoActive(true)}>
              <PlayCircle
                sx={{ color: theme.palette.brand.primary.light, fontSize: 50 }}
              />
            </div>
          )}
          <div
            className="expandImage"
            onClick={() => setExpandedImage(!expandedImage)}
          >
            <OpenInFull
              sx={{
                color: theme.palette.brand.secondary.natural,
                fontSize: 40,
              }}
            />
          </div>
        </S.ImageContainer>
      )}

      <ImageModal
        open={expandedImage}
        handleClose={() => setExpandedImage(false)}
        image={imagem}
      />
    </>
  );
};

export default ImageDesvio;
