import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { subHours } from 'date-fns';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import Tabs from 'components/Tabs';
// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '12px',
          border: '1px solid #e6e6e6',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          fontSize: '12px',
          fontFamily: 'inherit',
        }}
      >
        <div
          style={{
            borderBottom: '1px solid #eee',
            marginBottom: '8px',
            paddingBottom: '4px',
            fontWeight: '600',
            color: '#4B5563',
          }}
        >
          {`Período: ${label}`}
        </div>
        {payload.map((pld, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '4px',
            }}
          >
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: pld.color,
                marginRight: '8px',
              }}
            />
            <span
              style={{
                color: '#4B5563',
                marginRight: '8px',
                fontWeight: '500',
              }}
            >
              {pld.name}:
            </span>
            <span style={{ color: pld.color, fontWeight: '600' }}>
              {Number(pld.value).toFixed(2)}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export const HistoryPoints = ({
  tab = 'ptsH',
  tabs = [],
  data = [],
  dates,
  handleDate,
  handleChangeTab = () => false,
  print = false,
}) => {
  const handleChange = value => {
    handleChangeTab(value);
  };

  return (
    <S.Container print={print}>
      <div className="container-filters">
        <div className="titletop">
          <div className="graphtitle">Histórico de pontos</div>
          <PeriodPicker
            finalDate={dates.finalDate}
            initialDate={dates.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              handleDate({
                initialDate: date.initialDate,
                finalDate: subHours(date.finalDate, 3),
              });
            }}
          />
        </div>
        <div>
          <Tabs
            value={tab}
            onChange={(e, value) => handleChange(value)}
            items={tabs}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height={300}
          data={data}
          margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            height={70}
            tick={<CustomTick />}
            interval={30}
          />
          <YAxis tickFormatter={value => value.toFixed(0)} />
          <Tooltip content={<CustomTooltip />} />

          <Line
            type="monotone"
            dataKey="x"
            name="Empresa"
            stroke="#f64e60"
            dot={false}
            strokeWidth={2}
          />

          <Line
            type="monotone"
            dataKey="factor2"
            name="Global"
            stroke="#656E8C"
            dot={false}
            strokeWidth={2}
          />

          {/* Se algum dia tiver que por meta no gráfico */}
          {/* <Line
            type="monotone"
            dataKey="meta"
            name="Meta"
            stroke="#1bc5bd"
            strokeDasharray="5 5"
            dot={false}
            strokeWidth={2}
          /> */}
        </LineChart>
      </ResponsiveContainer>
      <div className="legend-container">
        <div className="legend">
          <div className="format-point">
            <div className="point1" />
            Empresa
          </div>
          <div className="format-point">
            <div className="point2" />
            Global
          </div>
        </div>
      </div>
    </S.Container>
  );
};
