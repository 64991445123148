import Empty from 'components/Empty';
import React from 'react';

import * as S from './styled';
import { Link } from '../../../Components/SingleTable/Link';
import { Divider } from '@mui/material';
import { Radar } from './Radar';
import { ListaDesvios } from './ListaDesvios';
import { usePlans } from 'hooks/usePlans';

export const PerfilMotoristas = ({ id, motorista, filters }) => {
  const { hasTelemetria, hasMonitoramento } = usePlans();
  if (!id)
    return (
      <Empty
        title="Veja mais detalhes do motorista"
        subtitle="Escolha um motorista na lista ao lado para ver mais estatísticas."
        image="motorista.png"
        height="50%"
        vertical
      />
    );
  return (
    <S.Container>
      <S.Header>
        <S.Title>{String(motorista?.motorista).toLocaleLowerCase()}</S.Title>
      </S.Header>
      <Divider />
      {(hasTelemetria || hasMonitoramento) && (
        <Radar id={id} filters={filters} />
      )}
      <ListaDesvios id={id} filters={filters} />
      <Link
        component="button"
        onClick={() => window.open(`/motoristas/${id}`)}
        justify="right"
      >
        Ver perfil
      </Link>
    </S.Container>
  );
};
