import styled from 'styled-components';

// container da página
export const PreviewConfig = styled.div`
  display: flex;
  border-radius: var(--border_radius, 4px);
  border: 1px solid var(--neutral400_30, rgba(147, 154, 171, 0.3));
  background: var(--neutral0, #fff);
  width: 120%;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Main = styled.div`
  width: 100%;
  padding: 30px;
`;

export const Section = styled.h3`
  color: var(--neutral600, #424449);
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`;

export const SignatureLabel = styled.h3`
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 0;
  padding: 0;
`;
