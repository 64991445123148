// Styled
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';

// Components
import GhostButton from 'components/Buttons/Ghost';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import { SaveAlt } from '@mui/icons-material';
import { Divider, Popover, SvgIcon } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

// Utils
import ExportToExcel from 'utils/exportToCvs';
import { ExportFormPdf } from '../../../Config/Export';
import { ReactComponent as calendar } from 'images/icons/calendar.svg';

// Services
import * as services from '../../../Config/services';

// Columns
import { columns } from './columns';
import { format } from 'date-fns';

export const ModalPendentes = ({
  data,
  open,
  onClose,
  currentPeriod,
  idForm,
  openModalJustify,
  periodo,
}) => {
  // Theme
  const theme = useTheme();

  // Redux
  const formularios = useSelector(state => state.formularios);

  // General States
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewrPrint, setViewrPrint] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  // Print
  const printRefPdfPend = useRef();

  const handleLabel = () => {
    if (periodo === 'DIA') {
      return format(new Date(currentPeriod[0]), 'dd/MM/yyyy');
    }

    return `${format(new Date(currentPeriod[0]), 'dd/MM/yyyy')} - ${format(
      new Date(currentPeriod[1]),
      'dd/MM/yyyy',
    )}`;
  };

  // -------------------------------------------- TABLE -----------------------------------------------//

  const actions = [
    {
      title: 'Justificar',
      function: (_, item) => {
        handleAction([item]);
      },
    },
    {
      title: 'Ver Perfil',
      function: (_, item) => window.open(`/motoristas/${item?.id}?step=6`),
      visible: item => item.tipo === 'Motorista',
    },
  ];

  const bulk = [
    {
      title: 'Justificar',
      function: () =>
        handleAction(data.filter(item => selectedData.includes(item.id))),
    },
  ];

  const handleAction = data => {
    openModalJustify(data);
    onClose();
  };

  // -------------------------------------------- EXPORTS -----------------------------------------------//
  const exportExcel = async () => {
    setLoadingExport(true);
    let res = await services.exportExcel({
      id: idForm,
      start: currentPeriod[0],
      end: currentPeriod[1],
      pending: true,
    });
    if (res.data.success) {
      toast.success(res.data.message);
      ExportToExcel({
        excel: res.data.data.excel,
        name: `formulario_pendentes_${idForm}_${format(
          new Date(currentPeriod[0]),
          'dd-MM-yyyy',
        )}`,
      });
      setLoadingExport(false);
      onClose();
    } else {
      toast.error(res.data.message);
      setLoadingExport(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRefPdfPend.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => {
      setLoadingExport(false);
      setViewrPrint(true);
    },
    copyStyles: true,
    documentTitle: `formulario_id_${formularios?.id}`,
  });

  useEffect(() => {
    if (viewrPrint) handlePrint();
  }, [viewrPrint]);

  return (
    <>
      <S.ModalMUI open={open}>
        <S.Content>
          <S.Header>
            <div className="left">
              <SchoolOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.attention.natural}
              />
              <span className="title">Alunos Pendentes</span>
            </div>
            <div className="right">
              <CloseOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
                onClick={onClose}
              />
            </div>
          </S.Header>
          <Divider />
          <S.TableContainer>
            <div className="visualButton">
              <div className="periodo">
                <SvgIcon component={calendar} />
                <p>{handleLabel()}</p>
              </div>
              <GhostButton
                children="Exportar"
                icon={<SaveAlt />}
                onClick={event => setAnchorEl(event.currentTarget)}
                loading={loadingExport}
              />
              <Popover
                sx={{ transform: 'translate(0px, 2px)' }}
                id={anchorEl ? 'popover' : undefined}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <S.Popup>
                  <button onClick={() => exportExcel()}>Excel</button>
                  <button onClick={() => setViewrPrint(true)}>PDF</button>
                </S.Popup>
              </Popover>
            </div>

            <DefaultTable
              bulk={bulk}
              actions={actions}
              columns={columns}
              data={data}
              fixedItems={10}
              setSelectedRows={setSelectedData}
              placeholder="Buscar Aluno"
              searchKeys={['nome']}
            />
          </S.TableContainer>
        </S.Content>
      </S.ModalMUI>

      {data && viewrPrint && (
        <ExportFormPdf
          form={formularios}
          data={{
            rows: data.map(i => ({
              ...i,
              concluido: null,
              aluno: {
                nome: i.nome,
                tipo: i.tipo,
                filial: {
                  nome: i.filial,
                },
              },
            })),
          }}
          printRef={printRefPdfPend}
          period={currentPeriod}
          noCards
        />
      )}
    </>
  );
};
