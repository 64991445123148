import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div``;

export const LineTitle = styled.div`
  margin-bottom: 10px;
`;

export const LineHeader = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const LineCards = styled.div`
  flex: 3;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-right: 20px;
`;

export const LineView = styled.div`
  flex: 1;
`;

export const LineTemplates = styled.div`
  display: flex;
  justify-content: flex-end;

  span {
    margin-bottom: 10px;
    font-weight: 600;
    color: ${colors.greySubtitle};
    cursor: pointer;

    &:hover {
      color: ${colors.greyTitle};
      opacity: 0.8;
    }
  }
`;
