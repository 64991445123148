import * as S from './styled';
import React from 'react';

import { Grid } from '@mui/material';
import Switch from 'components/Inputs/_withController/Switch';
import TextField from 'components/Inputs/_withController/TextField';
import InputAttachedFile from 'components/Inputs/_withController/InputAttachedFileOutlined';
import { Box } from '@mui/material';

const PreviewConfig = ({ control, errors }) => {
  return (
    <S.PreviewConfig>
      <S.Main>
        <Grid container spacing={2}>
          {/* Título Cabeçalho */}
          <Grid item xs={12}>
            <S.Section>CABEÇALHO</S.Section>
          </Grid>

          {/* Select e Switch na mesma linha */}
          <Grid item xs={6}>
            <InputAttachedFile
              control={control}
              name="logo"
              label="Imagem (Logo)"
              inputLabel="Escolher imagem"
              fileDir="capacitacao/templates/imagem_logo"
            />
          </Grid>

          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box ml={8}>
              {' '}
              {/* ml={2} equivale a margin-left: 16px */}
              <Switch
                name="exibe_criador"
                control={control}
                textOn="Mostrar criador do conteúdo"
                textOff="Mostrar criador do conteúdo"
              />
            </Box>
          </Grid>

          {/* Campo de texto do cabeçalho */}
          <Grid item xs={12}>
            <TextField
              control={control}
              name="cabecalho"
              label="Texto do cabeçalho"
              placeholder="Digite aqui"
            />
          </Grid>

          {/* Título Assinaturas */}
          <Grid item xs={12}>
            <S.Section>ASSINATURAS</S.Section>
          </Grid>

          {/* Linha 1 assinatura */}
          <Grid item xs={1.5}>
            <S.SignatureLabel>Assinatura 1</S.SignatureLabel>
            <Switch name="assinatura_1_ativo" control={control} />
          </Grid>
          <Grid item xs={5.25}>
            <InputAttachedFile
              control={control}
              name="assinatura_1_img"
              label="Imagem (Assinatura)"
              inputLabel="Escolher imagem"
              fileDir="capacitacao/templates/imagem_1"
            />
          </Grid>
          <Grid item xs={5.25}>
            <TextField
              control={control}
              name="assinatura_1"
              label="Descrição"
              placeholder="Digite aqui"
            />
          </Grid>
          {/* Linha 2 assinatura */}
          <Grid item xs={1.5}>
            <S.SignatureLabel>Assinatura 2</S.SignatureLabel>
            <Switch name="assinatura_2_ativo" control={control} />
          </Grid>
          <Grid item xs={5.25}>
            <InputAttachedFile
              control={control}
              name="assinatura_2_img"
              label="Imagem (Assinatura)"
              inputLabel="Escolher imagem"
              fileDir="capacitacao/templates/imagem_2"
            />
          </Grid>
          <Grid item xs={5.25}>
            <TextField
              control={control}
              name="assinatura_2"
              label="Descrição"
              placeholder="Digite aqui"
            />
          </Grid>
          {/* Linha 3 assinatura */}
          <Grid item xs={1.5}>
            <S.SignatureLabel>Assinatura 3</S.SignatureLabel>
            <Switch name="assinatura_3_ativo" control={control} />
          </Grid>
          <Grid item xs={5.25}>
            <InputAttachedFile
              control={control}
              name="assinatura_3_img"
              label="Imagem (Assinatura)"
              inputLabel="Escolher imagem"
              fileDir="capacitacao/templates/imagem_3"
            />
          </Grid>
          <Grid item xs={5.25}>
            <TextField
              control={control}
              name="assinatura_3"
              label="Descrição"
              placeholder="Digite aqui"
            />
          </Grid>
        </Grid>
      </S.Main>
    </S.PreviewConfig>
  );
};

export default PreviewConfig;
