import { default_logo } from './constants';

export const gerenerateCertificate = (
  nome_aluno = '',
  matricula = '',
  title_conteudo = '',
  horas_aula = 0,
  inicio = '',
  fim = '',
  data_validade = '',
  nota = '',
  autor = '',
  data_atual = '',
  gerente_ssma = '',
  cnpj = '',
  template,
) => {
  return `<!DOCTYPE html>
    <html lang="pt">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Certificado</title>
        <style>
            body {
                font-family: 'Texta', sans-serif;
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
                color: #424449;
                margin: 0;
                padding: 0;
            }

            .certificate {
                width: 100%;
                max-width: 1200px;
                background: white;
                padding: 40px;
                position: relative;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .logo img {
                height: 40px;
            }

            .info {
                text-align: right;
            }

            .icon {
                position: absolute;
                top: 150px;
                left: 50%;
                transform: translateX(-50%);
            }

            .icon img {
                width: 60px;
            }

            .content {
                margin-top: 150px;
                text-align: justify;
                padding: 0 20px;
                margin-bottom: 120px;
            }

            .content strong {
                font-weight: bold;
            }

            .signatures {
                position: relative;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                gap: 40px;
                padding: 0 40px;
                margin-top: 40px;
            }

            .signature {
                width: 250px;
                text-align: center;
            }

            .signature-image-container {
                height: 50px;
                margin-bottom: 5px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }

            .signature img {
                width: 120px;
                height: 50px;
                object-fit: contain;
            }

            .line {
                width: 100%;
                height: 1px;
                background-color: rgba(147,154,171,0.3);
                margin: 8px 0;
            }

            .signature p {
                margin: 3px 0;
                font-size: 14px;
                line-height: 1.4;
            }
        </style>
    </head>
    <body>
        <div class="certificate">
            <div class="header">
                <div class="logo">
                    <img src="${
                      template?.logo ||
                      'https://cdn.prod.website-files.com/5f2c6214ab715c5971920e7f/66733cc568b34069bca04fb2_logo_onisys_nstech-p-500.png'
                    }" alt="logo" />
                </div>
                <div class="info">
                    <p>${template?.cabecalho || `CNPJ: ${cnpj}`}</p>
                    ${
                      template?.exibe_criador
                        ? `<p>Criado por: ${autor}</p>`
                        : ''
                    }
                </div>
            </div>

            <div class="icon">
                ${default_logo}
            </div>

            <div class="content">
                <p>Certificamos que <strong>${nome_aluno}</strong>, portador(a) da matrícula/CPF ${matricula},
                   concluiu com êxito o curso <strong>"${title_conteudo}"</strong>, ${
    horas_aula
      ? `com carga horária de <strong>${horas_aula} horas/aula</strong>,`
      : ''
  }  ${
    fim
      ? `realizado no período de ${inicio} a ${fim}`
      : `realizado no dia ${inicio}`
  }
                ${
                  data_validade
                    ? `.</p><br/><p>O certificado é válido até <strong>${data_validade}</strong> e a`
                    : ' e a'
                } média final obtida foi de <strong>${nota}</strong>.</p>
                <p><br>Reconhecido pela equipe Onisys${
                  template?.exibe_criador ? ` e <strong>${autor}</strong>` : ''
                }. ${data_atual}.</p>
            </div>

            <div class="signatures">
                ${
                  template?.assinatura_1_ativo
                    ? `<div class="signature">
                         <div class="signature-image-container">
                           ${
                             template?.assinatura_1_img
                               ? `<img src="${template.assinatura_1_img}" alt="Assinatura 1" />`
                               : ''
                           }
                         </div>
                         <div class="line"></div>
                         <p>${
                           template?.assinatura_1 || 'gerente de transportes'
                         }</p>
                       </div>`
                    : ''
                }
                ${
                  template?.assinatura_2_ativo
                    ? `<div class="signature">
                         <div class="signature-image-container">
                           ${
                             template?.assinatura_2_img
                               ? `<img src="${template.assinatura_2_img}" alt="Assinatura 2" />`
                               : ''
                           }
                         </div>
                         <div class="line"></div>
                         <p>${
                           template?.assinatura_2 ||
                           `${gerente_ssma}, gestor de SSMA`
                         }</p>
                       </div>`
                    : ''
                }
                ${
                  template?.assinatura_3_ativo
                    ? `<div class="signature">
                         <div class="signature-image-container">
                           ${
                             template?.assinatura_3_img
                               ? `<img src="${template.assinatura_3_img}" alt="Assinatura 3" />`
                               : ''
                           }
                         </div>
                         <div class="line"></div>
                         <p>${
                           template?.assinatura_3 || 'diretor de operações'
                         }</p>
                       </div>`
                    : ''
                }
            </div>
        </div>
    </body>
    </html>`;
};
