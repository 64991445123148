import React from 'react';
import { useQuery } from 'react-query';
import { addDays, startOfDay } from 'date-fns';

import { Divider, Grid } from '@mui/material';

import * as S from './styled';
import { MaisSeguros } from './MaisSeguros';
import { MenosSeguros } from './MenosSeguros';
import { ListaMotoristas } from './ListaMotoristas';
import { Historico } from './Historico';
import { requestDriversRanking } from 'pages/RankingMotoristas/services';
import { usePlans } from 'hooks/usePlans';

const today = new Date();
const last30 = startOfDay(addDays(new Date(), -30));

const query = {
  initialDate: last30,
  finalDate: today,
  tipo: 'motorista',
};

export const MotoristasEstatisticas = () => {
  const { isFetching, data: resData } = useQuery(
    ['ranking-motoristas-seguros', query],
    () => query && requestDriversRanking(query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    planosAtivos,
    isOpLogistico,
    isProvider,
    hasTorrePlus,
    hasMonitoramento,
    hasTelemetria,
    hasFadiga,
  } = usePlans();

  // Determine whether to show the Historico component based on the conditions
  const showHistorico = hasMonitoramento && hasTelemetria && hasFadiga;

  return (
    <S.Container>
      <S.Main>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <MenosSeguros resData={resData} isFetching={isFetching} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MaisSeguros resData={resData} isFetching={isFetching} />
          </Grid>
          {showHistorico && (
            <Grid item xs={12}>
              <Historico />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListaMotoristas />
          </Grid>
        </Grid>
      </S.Main>
    </S.Container>
  );
};
