import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Ranking, { useFilterFields } from 'pages/_templates/Ranking';
import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';
import { requestDriversRanking } from './services';
import { columns, excelFields, fields } from './constants';
import { getRouterQueryParams } from 'utils/router-query-params';
import { generateFileName } from 'utils/generateFileName';

import ExcelModal from 'components/ExcelModalNew';

const RankingMotoristas = () => {
  const navigate = useNavigate();
  const { planosAtivos, isOpLogistico, isProvider, hasTorrePlus } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);

  const [query, setQuery] = useState(null);
  const [podium, setPodium] = useState([]);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.motoristas_ranking_provider;
  });

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const {
    isFetching,
    isLoading,
    data: resData,
    refetch: rankingRefetch,
  } = useQuery(
    ['ranking', query],
    () => query && requestDriversRanking(query),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    setQuery({ ...filtersPersist, tipo: 'motorista' });
  }, [filtersPersist]);

  useEffect(() => {
    if (resData?.data?.data) {
      const podio = resData.data.data?.geral?.slice(0, 3);
      setPodium(
        podio.map(item => ({
          nameOne: item.nome ?? item.nome_motorista,
          position: item.posicao,
          oldPosition: item.posicao_anterior
            ? item.posicao_anterior > item.posicao
              ? 'UP'
              : item.posicao_anterior < item.posicao
              ? 'DOWN'
              : 'EQUAL'
            : 'UP',
          logo: item.foto,
          value: `${item.valor ?? item.total_pontos} pontos`,
        })),
      );
    }
  }, [resData]);

  const formattedColumns = useMemo(
    () =>
      columns
        .filter(
          item =>
            !item.plans ||
            planosAtivos.some(({ id_do_plano }) =>
              item.plans.includes(id_do_plano),
            ),
        )
        .map(item => {
          if (item.id === 'nome_motorista') {
            if (hasTorrePlus)
              return {
                ...item,
                subRow: {
                  value: (_, item) => item?.nome_empresa,
                },
              };
            if (!isOpLogistico)
              return {
                ...item,
                subRow: {
                  prefix: 'Filial:',
                  value: (_, item) => item?.nome_filial,
                },
              };
          }
          return item;
        }),
    [query, isOpLogistico],
  );

  const actions = [
    {
      title: 'Ver perfil',
      function: (id, item) => {
        if (item.id_motorista) navigate(`/motoristas/${item.id_motorista}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    return fields
      .filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        const val = resData?.data?.resumo?.geral?.[item.id] || '';
        return {
          ...item,
          value: item.value ? item.value(val) : val,
        };
      });
  }, [resData, fields]);

  const getDataMedia = useCallback(data => {
    const getMedia = (resumo, data) => resumo.pontos / data.length;
    return (
      data?.data?.geral.map(item => ({
        ...item,
        media: getMedia(data.resumo.geral, data.data.geral),
      })) ?? []
    );
  }, []);

  const filterFields = useFilterFields();

  return (
    <>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={excelFields}
          query={{
            ...query,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={
            isProvider
              ? '/excel-provider/motoristas_ranking'
              : '/excel/motoristas_ranking'
          }
          filtersPersis={filtersPersist}
          collumns={filterFields}
          isManualDownload
        />
      )}
      <Ranking
        title="Ranking"
        filterKey="motoristas_ranking_provider"
        headerInfo=""
        handleRefetch={() => rankingRefetch()}
        headerActions={() => {
          trackEvent(user, 'Exportar Planilha Ranking Motoristas');
          setOpenExcelModal(true);
        }}
        podium={podium}
        loading={isFetching}
        tableProps={{
          local: true,
          loading: isLoading,
          data: getDataMedia(resData?.data),
          columns: formattedColumns,
          ...(hasTorrePlus ? { actions } : {}),
          searchKeys: ['nome'],
          placeholder: 'Buscar por Motorista',
          sortBy: { id: 'posicao', order: order ?? 'ASC' },
          empty: {
            image: 'motorista.png',
            title: 'Ops! Não foram encontrados motoristas para essa busca.',
            description:
              'Altere os filtros ou faça o cadastro do novo motorista!',
          },
        }}
        resumo={formatResumo}
      />
    </>
  );
};

export default RankingMotoristas;
