import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
  Check,
} from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import ConstantsUpdater from 'services/updateConstants';
import { getDaysInMonth } from 'date-fns';
import { trackEvent } from 'utils/mixpanel';
import { useLocation } from 'react-router-dom';
import { generateFileName } from 'utils/generateFileName';

// Components
import { formatNewDate, formatNameDate } from 'utils/dates';
import TableDisponibility from 'components/TableDisponibility';
import Card from 'components/Cards/Indicador';
import Loading from 'components/Loading';
import InfoCard from 'components/Cards/InfoCard';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import ConfirmModal from 'components/ConfirmModal';
import ExcelModal from 'components/ExcelModalNew';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import ModalAddFrota from './ModalAddFrota';
import ModalJustifyBottom from './ModalBottom';
import ModalJustificativa from './ModalJustificativa';
import SaveModal from '../Avaliacoes/SaveModal';
import { DefaultTable } from 'components/_Table/templates/default';
import FiltersGlobal from 'components/FiltersGlobal';

import {
  fields,
  resetExcelFields,
  columnsFrota2,
  cardsFrotaInit,
  cardsDisponibilidadeInit,
  statusDisponiveis,
  statusJustificados,
} from './constants';

import * as S from './styled';
import {
  changeStatus,
  changeStatusMany,
  requestAvailable,
  createAvailable,
  requestExcel,
  changeClient,
} from './services';
import { usePlans } from 'hooks/usePlans';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const pageTabs = [
  { value: 'veiculos', label: 'Veículos' },
  { value: 'disponibilidade', label: 'Disponibilidade' },
];

const sortBy = [
  {
    id: 'placa',
    desc: false,
  },
];

const sortByDisp = [
  {
    id: 'availability_percentage',
    desc: true,
  },
];

const Frota = ({ pageDefault }) => {
  const theme = useTheme();
  const { status, departamento, cargas } = useFetchMultipleWithCache();

  const today = new Date();
  const { hasFadiga, hasTelemetria, hasMonitoramento } = usePlans();

  // Redux e hooks
  const filter = useSelector(state => {
    return state.filter;
  });
  const filterFrota = useSelector(state => {
    return state.filterFrota;
  });
  const { trucks, clients, filials } = useSelector(state => {
    return state.selects;
  });
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.frota;
  });

  // Plano safe para disponibilizar aba de disponibilidade
  const { currentPlan } = useSelector(state => {
    return state.pageMap;
  });
  const hasSafePlan = currentPlan % 2 === 0;

  // Nível e filial do usuário
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;

  const navigate = useNavigate();

  // Data
  const path = useLocation().pathname;
  const pageTab = path.includes('disponibilidade')
    ? 'disponibilidade'
    : 'veiculos';

  const [data, setData] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [availability, setAvailability] = useState(null);
  const [dataAvailability, setDataAvailability] = useState(null);
  const [dataDisponibilidade, setDataDisponibilidade] = useState({
    ano: today.getFullYear(),
    mes: today.getMonth(),
  });
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [loadingCreateAvailability, setLoadingCreateAvailability] =
    useState(false);
  const [queryDisponibilidade, setQueryDisponibilidade] = useState(null);
  const [selectedDisponibilidade, setSelectedDisponibilidade] = useState([]);
  const [openModalJustificativa, setOpenModalJustificativa] = useState(false);

  // Add e edit modal
  let { pathname } = useLocation();
  const [openAddFrota, setOpenAddFrota] = useState(false);
  const [dataEdit, setdataEdit] = useState(null);

  // Seleção tabela frota
  const [selectedData, setSelectedData] = useState(null);
  const [idConfirmChangeStatus, setIdConfirmChangeStatus] = useState(false);
  const [idsConfirmDesativar, setIdsConfirmDesativar] = useState(false);
  const [idsConfirmAtivar, setIdsConfirmAtivar] = useState(false);
  const [idsConfirmAlterarCliente, setIdsConfirmAlterarCliente] =
    useState(false);
  const [cliente, setCliente] = useState('');
  const [loadingLines, setLoadingLines] = useState([]);

  // Cards
  const [cardsFrota, setCardsFrota] = useState(
    cardsFrotaInit.filter(cf => {
      if (
        hasMonitoramento ||
        (!['posicaoInadequada', 'canalIncorreto'].includes(cf.name) &&
          !hasMonitoramento)
      ) {
        return cf;
      }
    }),
  );
  const [cardsDisponibilidade, setCardsDisponibilidate] = useState(
    cardsDisponibilidadeInit,
  );

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handlePageTab = (event, newValue) => {
    trackEvent(
      user,
      newValue === 'veiculos'
        ? 'FROTA: TAB VEICULO'
        : 'FROTA: TAB DISPONIBILIDADE',
    );
    if (newValue === 'veiculos') navigate('/frota');
    else navigate('/frota/disponibilidade');
  };

  // ---------------------  FROTA ---------------------

  useEffect(() => {
    if (pathname.includes('criar')) setOpenAddFrota(true);
  }, [pathname]);

  useEffect(() => {
    const newData = trucks.filter(truck => {
      const res =
        (!filter.filters?.frota?.filiais ||
          filter.filters?.frota?.filiais.length === 0 ||
          filter.filters?.frota?.filiais.some(f => f == truck.id_da_filial)) &&
        (!filter?.filters?.frota?.cleintes ||
          filter?.filters?.frota?.cleintes.length === 0 ||
          filter?.filters?.frota?.cleintes.some(
            c => c == truck.id_da_distribuidora,
          )) &&
        (!filter?.filters?.frota?.operation ||
          filter?.filters?.frota?.operation.length === 0 ||
          filter?.filters?.frota?.operation.some(
            c => c == truck.id_da_distribuidora,
          )) &&
        (!filter?.filters?.frota?.departamento ||
          filter?.filters?.frota?.departamento.length === 0 ||
          filter?.filters?.frota?.departamento.some(
            c => c == truck.departamento,
          )) &&
        (!filter?.filters?.frota?.status ||
          filter?.filters?.frota?.status.length === 0 ||
          filter?.filters?.frota?.status.some(c => c == truck.status)) &&
        (!filter?.filters?.frota?.propriedade ||
          filter?.filters?.frota?.propriedade.length === 0 ||
          filter?.filters?.frota?.propriedade.some(
            c => c === truck.propriedade,
          )) &&
        (!filter?.filters?.frota?.carga ||
          filter?.filters?.frota?.carga.length === 0 ||
          filter?.filters?.frota?.carga.some(c => c === truck.id_da_carga)) &&
        (userLevel === 1 || userFilials.includes(truck.id_da_filial));
      return res;
    });

    // Atualiza valores dos cards com base nos dados filtrados
    let dataCardSeleted;
    const newCardsFrota = cardsFrota.map(card => {
      if (card.name === 'ativas') {
        trackEvent(user, 'FROTA: VISUALIZAR MOTORISTAS ATIVOS');
        const cardData = newData.filter(item => item.status === 'ATIVO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      } else if (card.name === 'posicaoInadequada') {
        const cardData = newData.filter(item => item.pos_camera === 'NAO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      } else if (card.name === 'canalIncorreto') {
        const cardData = newData.filter(item => item.dvr === 'NAO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      }
      return card;
    });

    setCardsFrota(newCardsFrota);
    // Atualiza data com newData ou dataCardSeleted se algum card for selecionado
    if (dataCardSeleted) setData(dataCardSeleted);
    else setData(newData);
  }, [
    filter?.frota?.filiais,
    filter.frota?.cleintes,
    filter?.frota,
    cardsFrota[0].selected,
    cardsFrota[1]?.selected || null,
    cardsFrota[2]?.selected || null,
    trucks,
  ]);

  // Ativa / inativa card clicado. Inativa os demais
  // Seleciona / limpa dados filtrados do card em dataCard
  const handleClickCards = name => {
    trackEvent(user, 'FROTA: DISPONIBILIDADE POR PERÍODO');
    const cards = pageTab === 'veiculos' ? cardsFrota : cardsDisponibilidade;
    const newCards = cards.map(card => {
      if (name === card.name) {
        if (card.selected !== undefined) {
          card.selected = !card.selected;

          // Seleciona dados do card de disponibilidade
          if (pageTab === 'disponibilidade' && availability) {
            if (card.selected) setDataAvailability(card.data);
            else setDataAvailability(availability.rows);
          }
        }
      } else if (card.selected) card.selected = false;
      return card;
    });

    // Atualiza valores
    if (pageTab === 'veiculos') setCardsFrota(newCards);
    else setCardsDisponibilidate(newCards);
  };

  const handleEditar = id => {
    trackEvent(user, 'FROTA: EDITAR VEÍCULO');

    const truckEdit = data?.find(truck => String(truck.id) === String(id));
    if (truckEdit) setdataEdit(truckEdit);
  };

  const handleHistorico = id => {
    navigate(`/logs/frota/${id}`);
  };

  const handleSetDesativarAtivar = truckId => {
    const truckEdit = data?.find(truck => String(truck.id) === String(truckId));
    setIdConfirmChangeStatus({ id: truckId, status: truckEdit.status });
  };

  // Finaliza edição de status
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleDesativarAtivar = async truckId => {
    setLoadingLines([truckId]);
    const res = await changeStatus(truckId);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setIdConfirmChangeStatus(null);
    setLoadingLines([]);
  };

  const handleDesativarAtivarMassa = async (newStatus, truckIds) => {
    setLoadingLines(truckIds);
    const data = { newStatus, truckIds };
    const res = await changeStatusMany(data);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setIdsConfirmDesativar(null);
    setIdsConfirmAtivar(null);
    setLoadingLines([]);
  };

  const handleAlterarCliente = async () => {
    if (cliente && idsConfirmAlterarCliente) {
      const data = {
        ids: idsConfirmAlterarCliente,
        client: cliente,
      };

      setLoadingLines(idsConfirmAlterarCliente);
      const res = await changeClient(data);

      if (res.data.success) {
        toast.success(res.data.message);
      } else toast.error(res.data.message);

      setLoadingLines([]);
      setTriggerUpdate(true);
      handleCloseAlterarCliente();
    }
  };

  const handleCloseAlterarCliente = () => {
    setCliente('');
    setIdsConfirmAlterarCliente(false);
  };

  // ---------------------  DISPONIBILIDADE ---------------------
  const fetchAvailability = async query => {
    setLoadingAvailability(true);
    const res = await requestAvailable(query);
    if (res.data?.success) {
      const newData = res.data.data;
      setAvailability(newData);

      // Atualiza valores dos cards com base nos dados filtrados
      // Armazena dados de newData filtrados pelo card em dataCardSeleted caso algum esteja selecionado
      let dataCardSeleted;
      const newCardsDisponibilidade = cardsDisponibilidade.map(card => {
        if (card.name === 'disponiveis') {
          card.value = `${newData.available_percentage} %`;
          card.data = newData.fullAvailable;
          if (card.selected) dataCardSeleted = newData.fullAvailable;
        } else if (card.name === 'abaixo95') {
          card.text = `Placas abaixo do mínimo de ${
            newData.parametroDisponibilidade || 0
          }%`;
          card.value = newData.bellow_average?.length;
          card.data = newData.bellow_average;
          if (card.selected) dataCardSeleted = newData.bellow_average;
        } else if (card.name === 'indispJustificada') {
          card.value = newData.justified?.length;
          card.data = newData.justified;
          if (card.selected) dataCardSeleted = newData.justified;
        }
        return card;
      });
      setCardsDisponibilidate(newCardsDisponibilidade);

      // Atualiza data com newData ou dataCardSeleted se algum card for selecionado
      if (dataCardSeleted) setDataAvailability(dataCardSeleted);
      else setDataAvailability(newData.rows);
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingAvailability(false);
  };

  // Aplica filtros em disponibilidade
  useEffect(() => {
    console.log('queyr => ', filter);

    const query = {
      filial: filter.filters?.disponibilidade?.filiais,
      client: filter.filters?.disponibilidade?.cleintes,
      departamento: filter.filters?.disponibilidade?.departamento,
      propriedade: filter.filters?.disponibilidade?.propriedade,
      ano: dataDisponibilidade.ano,
      mes: dataDisponibilidade.mes,
    };
    setQueryDisponibilidade(query);
  }, [filter, dataDisponibilidade]);

  useEffect(() => {
    if (queryDisponibilidade) fetchAvailability(queryDisponibilidade);
  }, [queryDisponibilidade]);

  // Finaliza justificativa e busca novos dados
  const handleSubmitJustificativa = async (newValue, outro) => {
    setLoadingCreateAvailability(true);
    const newData = selectedDisponibilidade.map(item => ({
      id_do_caminhao: item.id_do_caminhao,
      dia: item.dia,
      status: newValue,
      outro,
    }));
    const res = await createAvailable(newData);
    if (res.data?.success) toast.success(res.data.message);
    else if (res.data?.message) toast.error(res.data.message);
    else toast.error('Erro ao atualizar disponibilidade');
    setLoadingCreateAvailability(false);
    fetchAvailability(queryDisponibilidade);
    setOpenModalJustificativa(false);
    setSelectedDisponibilidade([]);
  };

  const filterTransporterFrota = [
    {
      filterName: 'filiais',
      label: 'Filiais',
      options:
        filials?.map(item => {
          return {
            label: item.nome,
            value: item.id,
          };
        }) || [],
    },
    {
      filterName: 'cleintes',
      label: 'Clientes',
      options:
        clients.map(item => {
          return {
            label: item.nome,
            value: item.id,
          };
        }) || [],
    },
    {
      filterName: 'departamento',
      label: 'Departamento',
      options: departamento?.data || [],
      multiple: false,
    },
    {
      filterName: 'status',
      label: 'Status',
      options: status?.data || [],
      multiple: false,
    },
    {
      filterName: 'propriedade',
      label: 'Propriedade',
      options: [
        { label: 'Próprio', value: 'PRÓPRIO' },
        { label: 'Agregado', value: 'AGREGADO' },
      ],
      multiple: false,
    },
    {
      filterName: 'carga',
      label: 'Carga',
      options: cargas?.data || [],
    },
  ];

  const filterDisponibilidade = filterTransporterFrota.filter(
    item => item.filterName !== 'status' && item.filterName !== 'carga',
  );
  // ---------------------  RENDER: FROTA e DISPONIBILIDADE ---------------------
  const PopoverAdicionar = (
    <S.AdicinarDiv>
      <button
        onClick={() => {
          trackEvent(user, 'FROTA:Adicionar novo');
          setOpenAddFrota(!openAddFrota);
        }}
      >
        Adicionar novo
      </button>

      <button
        onClick={() => {
          trackEvent(user, 'FROTA:Adicionar em massa');
          navigate('/frota/add-veiculos');
        }}
      >
        Adicionar em massa
      </button>
    </S.AdicinarDiv>
  );

  // Titulo, botoes add e exportar, infocard e tabs
  const renderTop = () => {
    return (
      <>
        <h1>Veículos {pageTab}</h1>
        <div style={{ marginTop: 15 }} />
        <FiltersGlobal
          data={
            pageTab === 'veiculos'
              ? filterTransporterFrota
              : filterDisponibilidade
          }
          hideRefleshButton
          persistDate
          handleExport={() => setOpenExcelModal(true)}
          hideExportButton={pageTab !== 'veiculos'}
          hideDate={pageTab !== 'veiculos'}
          customComponent={
            <DefaultButtonPopover
              startIcon={<AddCircleOutline />}
              size="medium"
              popoverComponent={PopoverAdicionar}
            >
              Adicionar veículo
            </DefaultButtonPopover>
          }
        />
        <div style={{ padding: '0px 0px' }}>
          <InfoCard message="Gerencie sua frota. Você pode adicionar, editar, ativar e inativar caminhões." />
        </div>
      </>
    );
  };

  // Renderiza cardsFrota ou cardsDisponibilidade
  // pageTab === 'veiculos' > cardsFrota
  // pageTab === 'disponibilidade' > cardsDisponibilidade
  const renderCards = () => {
    const cards = pageTab === 'veiculos' ? cardsFrota : cardsDisponibilidade;
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards?.map(card => {
          return (
            <Grid item key={card.name} xs={12} md={4} xl={4}>
              <Card
                value={card.value}
                icon={card.icon}
                text={card.text}
                handleClick={() => handleClickCards(card.name)}
                selected={card.selected}
                round={false}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // ---------------------  RENDER: FROTA ---------------------

  const renderTableFrota = () => {
    const actions = [
      { title: 'Editar', function: handleEditar },
      {
        title: 'Histórico alterações',
        function: handleHistorico,
      },
      {
        title: 'Ativar/Desativar',
        function: handleSetDesativarAtivar,
      },
    ];

    const bulkActions = [
      {
        title: 'Desativar',
        function: () => {
          trackEvent(user, 'FROTA: DESATIVAR TODOS OS VEÍCULOS');
          setIdsConfirmDesativar(selectedData);
        },
      },
      {
        title: 'Ativar',
        function: () => {
          trackEvent(user, 'FROTA: ATIVAR TODOS OS VEÍCULOS');
          setIdsConfirmAtivar(selectedData);
        },
      },
      {
        title: 'Alterar cliente',
        function: () => setIdsConfirmAlterarCliente(selectedData),
      },
    ];

    return (
      <DefaultTable
        data={data || []}
        columns={columnsFrota2.filter(cf => {
          if (
            hasMonitoramento ||
            (!['pos_camera', 'dvr'].includes(cf.id) && !hasMonitoramento)
          ) {
            return cf;
          }
        })}
        actions={actions}
        bulk={bulkActions}
        searchKeys={['placa', 'frota', 'modelo']}
        loading={loading}
        placeholder="Buscar por Placa, Frota ou Modelo"
        setSelectedRows={setSelectedData}
        sortBy={{ id: 'placa', order: 'ASC' }}
        loadingSelection={loadingLines}
        searchEvent={search =>
          trackEvent(user, 'Busca Gestão de Frotas', null, search)
        }
      />
    );
  };

  const renderModalAddFrota = () => {
    return (
      openAddFrota && (
        <ModalAddFrota
          open={openAddFrota}
          handleClose={() => setOpenAddFrota(false)}
          setTriggerUpdate={setTriggerUpdate}
        />
      )
    );
  };

  const renderModalEditFrota = () => {
    return (
      dataEdit && (
        <ModalAddFrota
          open={Boolean(dataEdit)}
          dataEdit={dataEdit}
          handleClose={() => setdataEdit(null)}
          setTriggerUpdate={setTriggerUpdate}
        />
      )
    );
  };

  // ---------------------  RENDER: DISPONIBILIDADE ---------------------

  const renderTableDisponibility = () => {
    const justifyColors = (check, label) => {
      if (check) return '#93c5dc';
      if (label === 'DISPONÍVEL')
        return theme.palette.semantics.feedback.success.natural;
      if (statusDisponiveis.includes(label))
        return theme.palette.semantics.feedback.warning.natural;
      if (statusJustificados.includes(label))
        return theme.palette.semantics.feedback.warning.natural;
      return '';
    };

    const getIcoStatus = (check, label) => {
      if (check) return <Check style={{ fill: '#93c5dc', width: '15px' }} />;
      if (label === 'DISPONÍVEL')
        return (
          <Check
            style={{
              fill: theme.palette.semantics.feedback.success.natural,
              width: '15px',
            }}
          />
        );
      if (statusDisponiveis.includes(label)) return <S.Info>i</S.Info>;
      if (statusJustificados.includes(label)) return <S.Info>i</S.Info>;
      return '';
    };

    const getLabelStatus = (label, outro) => {
      if (label === 'DISPONÍVEL')
        return {
          label: 'Disponível',
          color: theme.palette.semantics.feedback.success.natural,
        };
      if (label === 'INDISPONÍVEL')
        return {
          label: 'Indisponível',
          color: theme.palette.brand.primary.light,
        };
      return {
        label: 'Justificado',
        color: theme.palette.semantics.feedback.warning.natural,
        justificativa: outro || label,
      };
    };

    const handleChecked = (status, dia, id_caminhao, prevChecked) => {
      // Só permite marcar
      // DISPONIVEL E (mes atual e dia antes do atual OU mes anterior)
      if (status === 'DISPONÍVEL') {
        toast.warning('Este dia já está disponível.');
      } else if (
        dia > today &&
        dataDisponibilidade.ano >= today.getFullYear() &&
        dataDisponibilidade.mes >= today.getMonth()
      ) {
        toast.warning('Não é permitido selecionar data futura.');
      } else {
        // Adiciona ou remove da lista de marcados
        const newData = {
          id_do_caminhao: id_caminhao,
          dia,
        };

        if (prevChecked) {
          setSelectedDisponibilidade(prev => {
            return prev.filter(
              item =>
                !(
                  String(item.id_do_caminhao) === String(id_caminhao) &&
                  String(item.dia) === String(dia)
                ),
            );
          });
        } else {
          setSelectedDisponibilidade(prev => {
            return [...prev, newData];
          });
        }
      }
    };

    const daysInMonth = getDaysInMonth(
      new Date(dataDisponibilidade.ano, dataDisponibilidade.mes, 15),
    );

    const columnsDisponbility = [
      {
        Header: 'Placa',
        id: 'placa',
        accessor: d => (d.placa ? String(d.placa) : '-'),
        sortType: 'basic',
        Cell: ({ row }) => (
          <h3 style={{ color: '#2E3240', fontWeight: '600' }}>
            {row.original.placa}
          </h3>
        ),
      },

      {
        Header: (
          <S.HeaderDays>
            {Array.from({ length: daysInMonth }, (x, i) => (
              <span key={`day${i + 1}`}>{i + 1}</span>
            ))}
          </S.HeaderDays>
        ),
        id: 'day',
        accessor: 'day',
        cantSort: true,
        Cell: ({ row }) => {
          return (
            <S.StatusDisp>
              {row.original.disponibilidade.map((i, idx) => {
                const dia = new Date(
                  dataDisponibilidade.ano,
                  dataDisponibilidade.mes,
                  idx + 1,
                );
                const checked = selectedDisponibilidade.find(
                  selected =>
                    String(selected.id_do_caminhao) ===
                      String(row.original.id_do_caminhao) &&
                    String(selected.dia) === String(dia),
                );
                const labelStatus = getLabelStatus(i.status, i.outro);
                const color = justifyColors(checked, i.status);
                const icoStatus = getIcoStatus(checked, i.status);
                return (
                  <S.StatusDispWrapper key={`daycol${idx}`}>
                    <S.UnChecked
                      color={color}
                      onClick={() =>
                        handleChecked(
                          i.status,
                          dia,
                          row.original.id_do_caminhao,
                          checked,
                        )
                      }
                    >
                      {icoStatus}
                    </S.UnChecked>

                    <S.LabelDisp color={labelStatus.color}>
                      <h2>{dia.toLocaleDateString()}</h2>
                      <h4>{labelStatus.label}</h4>
                      {labelStatus.justificativa && (
                        <h3>
                          Motivo: {labelStatus.justificativa.toLowerCase()}
                        </h3>
                      )}
                      <h3>
                        Última atualização:{' '}
                        {i.updatedAt && formatNewDate(i.updatedAt)}
                      </h3>
                    </S.LabelDisp>
                  </S.StatusDispWrapper>
                );
              })}
            </S.StatusDisp>
          );
        },
      },
      {
        Header: 'Disp.',
        id: 'availability_percentage',
        accessor: d => d.truckAvailabilityPercentage || '-',
        sortType: 'basic',
        Cell: ({ row }) => (
          <S.DispPercentage value={row.original.truckAvailabilityPercentage}>
            {row.original.truckAvailabilityPercentage}%
          </S.DispPercentage>
        ),
      },
    ];

    return dataAvailability ? (
      <TableDisponibility
        columns={columnsDisponbility}
        permitIsSortedOccur
        data={dataAvailability}
        search={{
          inputLabel: 'Buscar por Placa...',
          keys: ['placa'],
        }}
        mesAno={dataDisponibilidade}
        setMesAno={setDataDisponibilidade}
        loading={loadingAvailability}
        sortBy={sortByDisp}
        pageToMixPanel="Busca Disponibilidade de Frotas"
      />
    ) : (
      <Loading />
    );
  };

  const checkFilds = excelFields => {
    let tmp = excelFields;

    if (!hasTelemetria) {
      tmp = tmp.filter(item => item.value !== 'telemtria_ultima_posicao');
    }
    if (!hasMonitoramento) {
      tmp = tmp.filter(item => item.value !== 'fadiga_ultimo_evento');
    }
    if (!hasFadiga) {
      tmp = tmp.filter(item => item.value !== 'camera_ultimo_video');
    }
    return tmp;
  };
  // ---------------------------------- EXCEL --------------------------------//
  // const handleRequestExcel = async () => {
  //   setLoadingExcel(true);

  //   const newFields = excelFields.filter(item => item.selected === true);

  //   const formatedDate = formatNameDate(new Date());

  //   const newQuery = { ...filter, ...filterFrota, excelFields: newFields };

  //   const res = await requestExcel(newQuery);
  //   if (res.data && res.data?.data?.excel)
  //     ExportToExcel({
  //       excel: res.data.data.excel,
  //       name: `frota_${formatedDate}`,
  //     });
  //   else if (res.data.message) toast.error(res.data?.message);

  //   setExcelFields(resetExcelFields(fields));
  //   setLoadingExcel(false);
  //   setOpenExcelModal(false);
  // };

  // ---------------------------------- Componentes da Main --------------------------------//
  const renderMain = () => (
    <>
      <ConstantsUpdater
        names={['trucks', 'clients']}
        trigger={triggerUpdate}
        setTrigger={setTriggerUpdate}
        forced
      />
      <S.Main>
        {renderTop()}
        {renderCards()}
        {pageTab === 'veiculos' && renderTableFrota()}
        {pageTab === 'disponibilidade' && renderTableDisponibility()}
      </S.Main>

      {renderModalAddFrota()}
      {renderModalEditFrota()}

      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={checkFilds(excelFields)}
          query={{
            ...filtersPersist,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route="/excel/frota"
          filtersPersis={filtersPersist}
          collumns={
            pageTab === 'veiculos'
              ? filterTransporterFrota
              : filterDisponibilidade
          }
          isManualDownload
        />
      )}

      {
        /* Confirmação de ativacao / desativacao */
        idConfirmChangeStatus && (
          <ConfirmModal
            open={Boolean(idConfirmChangeStatus)}
            handleClose={() => setIdConfirmChangeStatus(null)}
            title={
              idConfirmChangeStatus.status === 'ATIVO'
                ? 'Deseja desativar este veículo?'
                : 'Deseja reativar este veículo?'
            }
            titleIcon={
              idConfirmChangeStatus.status === 'ATIVO' ? (
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              ) : (
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              )
            }
            subtitle="Veículos inativos não geram avaliações e não são contabilizados na disponibilidade!"
            buttonText="Confirmar"
            onClick={() => {
              trackEvent(
                user,
                idConfirmChangeStatus.status === 'ATIVO'
                  ? 'FROTA: DESATIVA VEÍCULO'
                  : 'FROTA: ATIVAR VEÍCULO',
              );
              handleDesativarAtivar(idConfirmChangeStatus.id);
            }}
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de ativacao em massa */
        idsConfirmAtivar && (
          <ConfirmModal
            open={Boolean(idsConfirmAtivar)}
            handleClose={() => setIdsConfirmAtivar(null)}
            title={`Deseja reativar ${idsConfirmAtivar.length} veículos?`}
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Todos os veículos marcados serão reativados!"
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('ATIVO', idsConfirmAtivar)
            }
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de desativacao em massa */
        idsConfirmDesativar && (
          <ConfirmModal
            open={Boolean(idsConfirmDesativar)}
            handleClose={() => setIdsConfirmDesativar(null)}
            title={`Deseja desativar ${idsConfirmDesativar.length} veículos?`}
            titleIcon={
              <DeleteOutline
                sx={{
                  color: theme.palette.semantics.feedback.attention.natural,
                }}
                fontSize="medium"
              />
            }
            subtitle="Todos os veículos marcados serão desativados!
          Veículos inativos não geram avaliações e não são contabilizados na disponibilidade."
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('INATIVO', idsConfirmDesativar)
            }
            loading={loading}
          />
        )
      }
      {
        /* Confirmação alteração de clientes em massa */
        clients && idsConfirmAlterarCliente && (
          <SaveModal
            onClick={() => handleAlterarCliente()}
            open={Boolean(clients)}
            handleClose={() => handleCloseAlterarCliente()}
            title={`Deseja alterar clientes para ${idsConfirmAlterarCliente.length} veículos?`}
            titleIcon={file}
            subtitle="Os veículos marcados serão de exclusividade do cliente selecionado."
            value={cliente}
            handleChange={setCliente}
            selectLabel="Cliente"
            data={clients.map(item => ({
              value: item.id,
              name: item.nome,
            }))}
          />
        )
      }

      <ModalJustifyBottom
        open={selectedDisponibilidade.length > 0}
        data={selectedDisponibilidade}
        handleClose={() => setSelectedDisponibilidade([])}
        handleApply={() => setOpenModalJustificativa(true)}
      />

      {openModalJustificativa && (
        <ModalJustificativa
          open={openModalJustificativa}
          handleClose={() => setOpenModalJustificativa(false)}
          handleApply={handleSubmitJustificativa}
          loading={loadingCreateAvailability}
        />
      )}
    </>
  );

  return loading ? <Loading /> : renderMain();
};

export default Frota;
