import { getStatus, getTurmas } from 'constants/_SERVICES/user';

// Pages
import Formularios from 'pages/Fomularios';
import FormulariosParaMim from 'pages/Fomularios/ParaMim';
import HistoryUser from 'pages/Fomularios/ParaMim/HistoryUser';
import TemplateConfigFormulario from 'pages/Fomularios/Config';
import TemplateReportsFormulario from 'pages/Fomularios/Reports';
import FormulariosOcorrencia from 'pages/Fomularios/Ocorrencia';
import FormulariosOcorrenciaDetalhe from 'pages/Fomularios/Ocorrencia/Detalhe';

export default {
  '/formularios': {
    title: 'Formulários',
    page: <Formularios />,
  },

  '/formularios/criar': {
    pageFilterName: 'filterFormulario',
    defaults: [],
    filters: [
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'multiple',
        data: async () => getStatus(),
      },
    ],
    page: <Formularios />,
  },

  '/formularios/novo': {
    defaults: [],
    pageFilterName: 'filterFormulario',
    filters: [
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'multiple',
        data: async () => getStatus(),
      },
      {
        name: 'turma',
        placeholder: 'Filtrar por Turma',
        mode: 'single',
        data: async () => getTurmas(),
      },
    ],
    page: <Formularios />,
  },

  '/formularios/configuracao/:id': {
    back: -1,
    subTitle: 'Formulários',
    title: 'Novo Formulário',
    id: true,
    status: true,
    defaults: [],
    page: <TemplateConfigFormulario />,
  },

  '/formularios/configuracao/editar/:id': {
    back: '/formularios',
    subTitle: 'Formulários',
    title: 'Editar Formulário',
    id: true,
    status: true,
    defaults: [],
    page: <TemplateConfigFormulario />,
  },

  '/formularios/estatisticas/:id': {
    back: '/formularios',
    subTitle: 'Formulários',
    title: 'Formulário',
    id: true,
    status: true,
    defaults: [],
    page: <TemplateReportsFormulario />,
  },

  '/formularios/para-mim': {
    title: 'Para mim',
    page: <FormulariosParaMim />,
  },

  '/formularios/ocorrencias': {
    title: 'Ocorrências',
    page: <FormulariosOcorrencia />,
  },

  '/formularios/ocorrencias/:id': {
    back: '/formularios/ocorrencias',
    title: 'Ocorrência',
    id: true,
    defaults: [],
    page: <FormulariosOcorrenciaDetalhe />,
  },

  '/fomularios/historicos/:id': {
    back: -1,
    title: 'Formulário',
    subTitle: 'Histórico',
    id: true,
    defaults: [],
    page: <HistoryUser />,
  },
};
