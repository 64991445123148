/* eslint-disable */
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import { handleApplyFilter } from 'store/modules/filter/actions';
import { ptBR } from 'date-fns/locale';
import { DateRangePicker } from './react-date-range/src';
import React, { useEffect, useRef, useState } from 'react';
import { Popover } from '@mui/material';
import { toast } from 'react-toastify';
import { addDays, parse, format } from 'date-fns';
import { createStaticRanges, getDefaultRanges, getDefineds } from './defined';
import { formatNewDate } from '../../utils/dates';
import * as S from './styled';
import InputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconCalendar } from '../../images/icons/components/calendar.svg';

const CustomDateRangePicker = ({
  onDate = () => {},
  dInt = new Date(),
  dfim = new Date(),
  persistDate = false,
  showHours = false,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const { provider } = user;

  // Controle do modal
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const location = useLocation();
  const path = location.pathname;
  const currentPageName = path.split('/').pop();

  const [range, setRange] = useState(
    provider
      ? [
          {
            startDate: new Date(dInt),
            endDate: new Date(dfim),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: new Date(dInt),
            endDate: new Date(dfim),
            key: 'selection',
          },
        ],
  );

  const [dateInput, setDateInput] = useState({
    startDate: format(range[0].startDate, 'dd/MM/yyyy'),
    endDate: format(range[0].endDate, 'dd/MM/yyyy'),
  });

  const [defaultStaticRanges, setDefaultStaticRanges] = useState([]);

  const filters = useSelector(state => {
    return state.filter.filters && state.filter.filters[currentPageName]
      ? state.filter.filters[currentPageName]
      : {};
  });

  const handleDate = (initialDate, finalDate, dateLabel) => {
    onDate({ initialDate, finalDate, dateLabel });
  };

  // Controle do modal
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // Atualiza filtro redux apenas quando fecha o modal
  const handleClose = () => {
    setAnchorEl(null);
  };

  const diffInMonths = (end, start) => {
    const timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365.25));
  };

  const handleDateInputChange = (event, type) => {
    const value = event.target.value;
    const parsedDate = parse(value, 'dd/MM/yyyy', new Date());

    setDateInput(prev => ({
      ...prev,
      [type]: value,
    }));

    if (!isNaN(parsedDate)) {
      const newRange = { ...range[0] };
      if (type === 'startDate') {
        newRange.startDate = parsedDate;
      } else if (type === 'endDate') {
        newRange.endDate = parsedDate;
      }
      setRange([newRange]);
    }
  };

  // Valor recebido pelo componente: Range[0] = [value]
  const handleChange = value => {
    const { startDate, endDate } = value;
    const res = diffInMonths(startDate, endDate);
    if (res <= 12)
      setRange([
        {
          ...value,
          endDate: value.isLabel
            ? value.endDate
            : addDays(new Date(endDate).setHours(23, 59, 59), 0),
          isLabel: false,
        },
      ]);
    else {
      toast.warning(
        'Você não pode selecionar um intervalo superior a 12 meses!',
      );
    }
  };

  // Aplica valor atual ao redux
  const handleApply = () => {
    // Verifica se é uma data predefinida
    const dateLabelFinded = defaultStaticRanges.find(item => {
      const newRange = item.range();
      return (
        newRange.endDate === range[0].endDate &&
        newRange.startDate === range[0].startDate
      );
    });

    let dateLabel = '';
    if (dateLabelFinded) dateLabel = dateLabelFinded.label;

    const start = range[0].startDate;
    const end = range[0].endDate;

    handleDate(start, end, dateLabel);

    if (persistDate) {
      dispatch(
        handleApplyFilter(
          {
            ...filters,
            initialDate: start,
            finalDate: end,
          },
          currentPageName,
        ),
      );
    }

    handleClose();
  };

  // String do botao do filtro
  const getDateString = () => {
    const startDateStr = formatNewDate(range[0].startDate);
    const endDateStr = formatNewDate(
      new Date(range[0].endDate).setHours(0, 0, 0, 0),
    );
    return `${startDateStr} - ${endDateStr}`;
  };

  const handleTimeChange = (event, type, timePart) => {
    const value = parseInt(event.target.value, 10);
    const newRange = { ...range[0] };

    const newStartDate = new Date(newRange.startDate);
    const newEndDate = new Date(newRange.endDate);

    if (type === 'start') {
      if (timePart === 'hour') {
        newStartDate.setHours(value);
      } else if (timePart === 'minute') {
        newStartDate.setMinutes(value);
      }
      newRange.startDate = newStartDate;
    } else if (type === 'end') {
      if (timePart === 'hour') {
        newEndDate.setHours(value);
      } else if (timePart === 'minute') {
        newEndDate.setMinutes(value);
      }
      newRange.endDate = newEndDate;
    }

    setRange([newRange]);
  };

  // Atualiza os ranges estaticos sempre que a data atual mudar
  const todayRef = useRef(new Date().getDate());
  const updateRanges = () => {
    todayRef.current = new Date().getDate();
    const _defaultStaticRanges = createStaticRanges(
      getDefaultRanges(getDefineds()),
    );
    setDefaultStaticRanges(_defaultStaticRanges);
  };

  useEffect(() => {
    // Atualiza quando o componente monta
    updateRanges();

    // Cria um intervalo que verifica a cada 30 segundos se o dia mudou
    const interval = setInterval(() => {
      const now = new Date();
      const currentDay = now.getDate();

      if (currentDay !== todayRef.current) {
        updateRanges();
      }
    }, 30000); // Verifica a cada 30 segundos

    return () => clearInterval(interval); // Limpa o intervalo quando o componente desmonta
  }, []);

  useEffect(() => {
    if (persistDate && filters && filters.startDate && filters.endDate) {
      setRange([
        {
          ...range[0],
          startDate: new Date(filters.initialDate),
          endDate: new Date(filters.finalDate),
        },
      ]);
    }
  }, [filters]);

  useEffect(() => {
    setDateInput({
      startDate: format(range[0].startDate, 'dd/MM/yyyy'),
      endDate: format(range[0].endDate, 'dd/MM/yyyy'),
    });
  }, [range]);

  return (
    <div>
      <S.StyledButton
        textcolor={theme.palette.brand.primary.natural}
        backgroundcolor="transparent"
        endIcon={<IconCalendar />}
        height="45px"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        data-testid="custom-date-range-picker"
      >
        {getDateString()}
      </S.StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Main data-testid="date-range-picker">
          <S.CustomDateRangePickerWrapper>
            <DateRangePicker
              onChange={item => handleChange(item.selection)}
              showMonthAndYearPickers={false}
              showMonthArrow // default
              showDateDisplay={false}
              showPreview // default
              dayDisplayFormat="d" // default
              weekdayDisplayFormat="EEEEEE"
              dateDisplayFormat="MMM d, yyyy" // default
              monthDisplayFormat="MMMM"
              moveRangeOnFirstSelection={false} // default
              locale={ptBR}
              months={2}
              rangeColors={[theme.palette.brand.secondary.natural]}
              ranges={range}
              direction="horizontal"
              preventSnapRefocus
              calendarFocus="backwards"
              inputRanges={[]}
              staticRanges={defaultStaticRanges}
            />
          </S.CustomDateRangePickerWrapper>
          <S.Footer>
            <S.ItemsWrapper>
              <S.CancelButton onClick={handleClose}>Cancelar</S.CancelButton>

              <S.ApplyButton onClick={handleApply}>Aplicar data</S.ApplyButton>
            </S.ItemsWrapper>

            <S.ItemsWrapper style={{ marginLeft: 30 }}>
              <S.DateTimeWrapper>
                <S.FieldWrapper style={{ marginRight: 20 }}>
                  <S.Label>Data início</S.Label>

                  <InputMask
                    mask="99/99/9999"
                    placeholder="dd/mm/yyyy"
                    className="date-input"
                    value={dateInput.startDate}
                    onChange={e => handleDateInputChange(e, 'startDate')}
                  >
                    {inputProps => (
                      <S.DateDisplay {...inputProps} showHours={showHours} />
                    )}
                  </InputMask>
                </S.FieldWrapper>

                {showHours && (
                  <S.FieldWrapper>
                    <S.Label>Hora</S.Label>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <S.TimeInput
                        type="number"
                        min="00"
                        max="23"
                        defaultValue="00"
                        value={
                          range[0].startDate
                            ? new Date(range[0].startDate)
                                .getHours()
                                .toString()
                                .padStart(2, '0')
                            : '00'
                        }
                        onChange={e => handleTimeChange(e, 'start', 'hour')}
                      />
                      <div className="divider-hours">:</div>
                      <S.TimeInput
                        type="number"
                        min="00"
                        max="59"
                        defaultValue="00"
                        value={
                          range[0].startDate
                            ? new Date(range[0].startDate)
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')
                            : '00'
                        }
                        onChange={e => handleTimeChange(e, 'start', 'minute')}
                      />
                    </div>
                  </S.FieldWrapper>
                )}
                <S.FieldWrapper style={{ marginLeft: 40, marginRight: 15 }}>
                  <S.Label>Data fim</S.Label>
                  <InputMask
                    mask="99/99/9999"
                    placeholder="dd/mm/yyyy"
                    className="date-input"
                    value={dateInput.endDate}
                    onChange={e => handleDateInputChange(e, 'endDate')}
                  >
                    {inputProps => (
                      <S.DateDisplay {...inputProps} showHours={showHours} />
                    )}
                  </InputMask>
                </S.FieldWrapper>

                {showHours && (
                  <S.FieldWrapper>
                    <S.Label>Hora</S.Label>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <S.TimeInput
                        type="number"
                        min="00"
                        max="23"
                        defaultValue="23"
                        value={
                          range[0].endDate
                            ? new Date(range[0].endDate)
                                .getHours()
                                .toString()
                                .padStart(2, '0')
                            : '23'
                        }
                        onChange={e => handleTimeChange(e, 'end', 'hour')}
                      />
                      <div className="divider-hours">:</div>
                      <S.TimeInput
                        type="number"
                        min="00"
                        max="59"
                        defaultValue="59"
                        value={
                          range[0].endDate
                            ? new Date(range[0].endDate)
                                .getMinutes()
                                .toString()
                                .padStart(2, '0')
                            : '59'
                        }
                        onChange={e => handleTimeChange(e, 'end', 'minute')}
                      />
                    </div>
                  </S.FieldWrapper>
                )}
              </S.DateTimeWrapper>
            </S.ItemsWrapper>
          </S.Footer>
        </S.Main>
      </Popover>
    </div>
  );
};

export default CustomDateRangePicker;
