export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    picture: 'foto',
    sort: true,
  },
  {
    header: 'Tipo',
    id: 'tipoUser',
    type: 'string',
    sort: true,
  },
  {
    header: 'Status',
    id: 'ativa',
    type: 'string',
    sort: true,
    align: 'center',
    value: value => (value ? 'Ativo' : 'Inativo'),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Alunos',
    id: 'n_alunos',
    type: 'number',
    sort: true,
    align: 'center',
  },
];

export const columnsTurmas = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => Number(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    accessor: d => String(d.tipoUser),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Status',
    id: 'ativa',
    accessor: d => String(d.ativa),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Alunos',
    id: 'n_alunos',
    accessor: d => Number(d.n_alunos),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];

export const view = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: false,
    picture: 'foto',
  },
  {
    header: 'Tipo',
    id: 'tipo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    type: 'number',
    sort: true,
    align: 'center',
  },
];
