// Styles
import * as S from './styled';
import 'react-date-range/dist/styles.css';
import { useTheme } from 'styled-components';
import 'react-date-range/dist/theme/default.css';

// Libs
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Components
import GhostButton from 'components/Buttons/Ghost';

// React
import React, { useMemo, useState } from 'react';
import { DateRangePicker } from 'react-date-range';

// Components MUI
import { Popover } from '@mui/material';
import { ArrowForward, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

// Constants
import { defaultStaticRanges } from './defined';

export const DatePicker = ({
  initialize,
  select,
  setCurrentSelect,
  periodo,
}) => {
  // Theme
  const theme = useTheme();

  // Popover Items
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const initial = new Date(initialize?.[0]);
  const final = new Date(initialize?.[1]);

  const [range, setRange] = useState([
    {
      startDate: initial,
      endDate: final,
      value: initialize,
      key: 'selection',
    },
  ]);

  const ranges = useMemo(
    () =>
      select.map(item => {
        const start = new Date(item[0]);
        const end = new Date(item[1]);

        const initial = new Date(item[0]);
        const datesBetween = [];

        for (
          let date = initial;
          date <= end;
          date.setDate(date.getDate() + 1)
        ) {
          datesBetween.push(date.getTime());
        }

        return {
          startDate: start,
          endDate: end,
          value: item,
          datesBetween,
        };
      }),
    [],
  );

  const invalidDays = useMemo(() => {
    const max = new Date(select?.[0][0]);
    const min = new Date(select?.[select.length - 1][1]);

    const total = [];

    for (
      let date = new Date(min);
      date <= new Date(max);
      date.setDate(date.getDate() + 1)
    ) {
      total.push(date.getTime());
    }

    const intervals = [];

    select?.map(item => {
      const start = new Date(item[0]);
      const end = new Date(item[1]);

      for (
        let date = new Date(start);
        date <= new Date(end);
        date.setDate(date.getDate() + 1)
      ) {
        intervals.push(date.getTime());
      }
    });

    const invalid = total.filter(item => !intervals.includes(item));
    return invalid;
  }, []);

  // Handlers
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = item => {
    const time = item.startDate.getTime();
    let found = ranges.find(range => range.datesBetween.includes(time));
    if (!found) {
      found = item;
    }
    setRange([
      {
        startDate: found?.startDate,
        endDate: found?.endDate,
        value: found?.value,
        key: 'selection',
      },
    ]);
  };

  const handleApply = () => {
    setCurrentSelect(range[0].value);
    handleClose();
  };

  const handleLabel = () => {
    if (periodo === 'DIA') {
      return format(new Date(initialize[0]), 'dd/MM/yyyy');
    }

    return `${format(new Date(initialize[0]), 'dd/MM/yyyy')} - ${format(
      new Date(initialize[1]),
      'dd/MM/yyyy',
    )}`;
  };

  return (
    <div>
      <S.StyledButton
        textcolor={
          anchorEl
            ? theme.palette.brand.secondary.natural
            : theme.palette.words.subtitle.natural
        }
        backgroundcolor="transparent"
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        {handleLabel()}
      </S.StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Main>
          <DateRangePicker
            maxDate={ranges[ranges.length - 1].startDate}
            minDate={ranges[0].endDate}
            onChange={item => handleChange(item.selection)}
            showMonthAndYearPickers={false}
            showMonthArrow // default
            showDateDisplay={false}
            showPreview // default
            dayDisplayFormat="d" // default
            weekdayDisplayFormat="EEEEEE"
            dateDisplayFormat="MMM d, yyyy" // default
            monthDisplayFormat="MMMM"
            rangeColors={[theme.palette.brand.secondary.natural]}
            dragSelectionEnabled={false}
            focusedRange={[0, 0]}
            disabledDates={invalidDays.map(item => new Date(item))}
            weekStartsOn={1}
            locale={ptBR}
            months={2}
            ranges={range}
            direction="horizontal"
            preventSnapRefocus
            calendarFocus="backwards"
            inputRanges={[]}
            staticRanges={defaultStaticRanges(ranges)}
          />

          <S.Footer>
            <S.ItemsWrapper>
              <S.DateDisplay>
                <h3>{range[0].startDate.toLocaleDateString()}</h3>
              </S.DateDisplay>

              <ArrowForward />

              <S.DateDisplay>
                <h3>{range[0].endDate.toLocaleDateString()}</h3>
              </S.DateDisplay>
            </S.ItemsWrapper>

            <S.ItemsWrapper width="350px">
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                size="medium"
                onClick={handleClose}
              >
                Cancelar
              </GhostButton>

              <GhostButton size="medium" onClick={handleApply}>
                Aplicar período
              </GhostButton>
            </S.ItemsWrapper>
          </S.Footer>
        </S.Main>
      </Popover>
    </div>
  );
};
