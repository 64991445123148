import React from 'react';
import { Controller } from 'react-hook-form';
import Input from 'components/Inputs/InputAttachedFileOutlined';

const TextField = ({ control, name, rules, data, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Input
          idInput={name}
          fileUrl={value}
          setFileUrl={value => onChange(value)}
          {...props}
        />
      )}
    />
  );
};

export default TextField;
