import { Fragment, useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as S from './styled';

import Empty from '../Empty';
import AddCard from '../AddCard';
import ContentCard from '../ContentCards';

const Templates = ({
  query,
  templates,
  inSearch,
  forMe,
  loadingHistoryLine,
  loadingScreen,
  loadingCard,
  tryed,
  onCreateNew,
}) => {
  const [items, setItems] = useState(7);
  const [localTemplates, setLocalTemplates] = useState(templates);

  useEffect(() => {
    setLocalTemplates(templates);
  }, [templates]);

  const handleTemplateDelete = deletedTemplateId => {
    setLocalTemplates(prevTemplates =>
      prevTemplates.filter(template => template.id !== deletedTemplateId),
    );
  };

  return (
    <>
      {localTemplates?.length > 0 && (
        <S.Global isLoading={loadingScreen}>
          <S.Wrapper>
            {!forMe && (
              <AddCard title="Criar Novo Conteúdo" onClick={onCreateNew} />
            )}
            {localTemplates?.map((temp, key) => (
              <Fragment key={key}>
                {key < items && (
                  <ContentCard
                    key={key}
                    template={temp}
                    onDelete={handleTemplateDelete}
                    loadingHistoryLine={loadingHistoryLine}
                    loadingScreen={loadingScreen}
                    loadingCard={loadingCard}
                    forMe={forMe}
                    tryed={tryed}
                  />
                )}
              </Fragment>
            ))}
          </S.Wrapper>
          {localTemplates.length > items && (
            <S.SeeMore onClick={() => setItems(items + 8)}>
              <span className="textSeeMore">Ver Mais</span>
              <div className="divIcon">
                <KeyboardArrowDownIcon className="icon" />
              </div>
            </S.SeeMore>
          )}
        </S.Global>
      )}
      {localTemplates?.length <= 0 && (
        <Empty
          query={query}
          inSearch={inSearch}
          onCreateNew={onCreateNew}
          titleButton="Novo conteúdo"
          forMe={forMe}
          tryed={tryed}
        />
      )}
    </>
  );
};

export default Templates;
