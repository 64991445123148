// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reset } from 'store/modules/formularios/actions';

// Components
import ConfirmModal from 'components/ConfirmModal';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Loading from '../../components/Loading';
import ModalResponse from '../../components/ModalResponse';

// Components MUI
import * as S from './styled';

// Utils
import { steps } from '../constants';
import * as services from '../services';
import * as responses from '../../ParaMim/services';

export const Revisar = ({
  trySave,
  setTrySave,
  finalMode,
  refetch,
  setCurrentStep,
  formPrev,
  setFormPrev,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formularios = useSelector(state => state.formularios);

  // General States
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [description, setDescription] = useState('');
  const [saveModal, setSaveModal] = useState(false);

  useEffect(() => {
    if (formPrev) return null;
    setLoading(true);
    const responseForm = async () => {
      const res = await responses.getFormPreview(formularios.id);

      if (res.success) {
        setFormPrev({ form: res.data.form });
      } else {
        toast.error('Erro ao carregar prévia');
      }
      setLoading(false);
    };
    responseForm();
  }, [formularios]);

  useEffect(() => {
    if (trySave) {
      setSaveModal(true);
      if (finalMode === 'public') {
        setMessage('Tudo pronto para a sua viagem?');
        setDescription(
          'A partir de agora, você poderá acessar os relatórios e acompanhar de perto como tem sido a aderência do formulário.',
        );
      } else if (finalMode === 'draft') {
        setMessage('Tem certeza que deseja salvar como rascunho?');
        setDescription(
          'O formulário não será disponibilizado para os usuários',
        );
      }
    }
  }, [trySave]);

  const saveRequest = async () => {
    if (finalMode === 'draft') {
      dispatch(reset());
      navigate('/formularios');
    } else if (finalMode === 'public') {
      setLoading(true);
      const res = await services.publishForm(formularios.id);
      if (res.success) {
        setTrySave(false);
        setSaveModal(false);
        toast.success(res.message);
        refetch();
        navigate(`/formularios/estatisticas/${formularios.id}`);
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.TitlePrev>Prévia Mobile</S.TitlePrev>
        <S.PhoneScale>
          {formPrev && !loading && <ModalResponse form={formPrev} preview />}
          {loading && (
            <div className="loadControll">
              <Loading />
            </div>
          )}
        </S.PhoneScale>
      </S.Wrapper>

      <ConfirmModal
        open={saveModal}
        title={message}
        subtitle={description}
        titleIcon={
          <CheckBoxOutlinedIcon
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        buttonText="Confirmar"
        onClick={() => saveRequest()}
        loading={loading}
        handleClose={() => {
          setSaveModal(false);
          setTrySave(false);
        }}
      />
    </>
  );
};
