import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SvgIcon } from '@mui/material';
import { Close, DeleteOutline } from '@mui/icons-material';
import { ReactComponent as anexar } from 'images/icons/inputs/anexar.svg';
import ImageIcon from '@mui/icons-material/Image';
import ConfirmModal from 'components/ConfirmModal';
import { firestorage } from 'utils/firebase';
import { getDatetimeString } from 'utils/dates';
import { useTheme } from 'styled-components';
import Loading from 'components/Loading';
import * as S from './styled';

// Upload de arquivo para o firebase storage
// Controle total sobre fileUrl
// O nome do arquivo é atualizado pelo componente fileName

// Para deletar do storage deve ser passado deleteFromStorage=true
// Mesmo assim, usuário deve confirmar exclusão no modal ConfirmModal
const AttachFile = ({
  label, // Título superior
  idInput = 'attached-file', // Id de referencia para o input. Deve ser diferente caso existam 2 ou mais
  inputLabel = 'Anexar arquivo (25MB)', // Título interno
  fileDir, // Diretorio do Storage onde será armazenado o arquivo.   Ex: "/desvios/13223"
  fileUrl, // estado url do arquivo
  setFileUrl, // funcao do setState da url do arquivo
  deleteFromStorage, // boolean se vai apagar do storage ou nao (só limpar fileUrl)
  required, // opcional
  disabled, // opcional
  typeFiles, // optional
  maxFileSize = 25,
}) => {
  const theme = useTheme();
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // Atualiza o nome do arquico ao atualizar fileUrl
  // Try catch serve para fileName inválido
  useEffect(() => {
    try {
      let newName = '';
      if (fileUrl) newName = firestorage.refFromURL(fileUrl)?.name;
      setFileName(newName);
    } catch (error) {
      console.log(error);
    }
  }, [fileUrl]);

  // Ao carregar arquivo
  const handleAttachedFile = input => {
    let checkSize = maxFileSize * 1024 * 1024;
    if (input[0]?.size > checkSize) {
      toast.error(
        `O tamanho do anexo ultrapassa o limite de ${maxFileSize}mb.`,
      );
      return;
    }
    setLoading(true);
    const data = input[0];

    if (typeFiles && data.type !== typeFiles) {
      toast.error('Formato de arquivo inválido');
      setLoading(false);
      return;
    }

    // data e hora no final do arquivo para o usuário não sobreescrever um arquivo com mesmo nome
    const dh = getDatetimeString();
    const fileExt = data.name.split('.').pop();
    const newName = `${fileDir}/${
      data.name.split(`.${fileExt}`)[0]
    }_${dh}.${fileExt}`;

    const fileRef = firestorage.ref(newName);
    const uploadTask = fileRef.put(data);
    uploadTask.on(
      'state_changed',
      snapShot => {},
      err => {
        toast.error('Erro ao carregar arquivo!');
        console.log('Err', err);
        setLoading(false);
      },
      () => {
        fileRef.getDownloadURL().then(fireBaseUrl => {
          setFileUrl(fireBaseUrl);
          setLoading(false);
        });
      },
    );
  };

  // Só deleta arquivo se deleteFromStorage E após confirmação do modal
  const handleRemoveFile = async () => {
    setLoading(true);
    try {
      const fileReference = firestorage.refFromURL(fileUrl);
      if (fileReference) await fileReference.delete();
    } catch (error) {
      console.log(error);
    }
    setFileUrl(null);
    setLoading(false);
    setConfirmDelete(false);
  };

  return (
    <>
      <S.Title>
        {label}
        {required ? <span>*</span> : ''}
      </S.Title>

      <S.AttachFile disabled={disabled}>
        {loading && <Loading />}
        {fileUrl && fileName && !loading ? (
          <>
            <S.StyledDiv>
              <S.Link href={fileUrl} target="_blank">
                {fileName}
              </S.Link>

              <S.IconDivClose
                disabled={disabled}
                onClick={() =>
                  deleteFromStorage ? setConfirmDelete(true) : setFileUrl(null)
                }
              >
                <p style={{ cursor: 'pointer', color: 'red' }}> </p>
                <Close sx={{ color: theme.palette.brand.secondary.natural }} />
              </S.IconDivClose>
            </S.StyledDiv>

            <S.Label disabled={disabled} htmlFor={idInput}>
              <SvgIcon component={ImageIcon} />
              {inputLabel}
            </S.Label>
          </>
        ) : (
          <S.Text disabled={disabled} htmlFor={idInput}>
            <SvgIcon component={ImageIcon} />
            {inputLabel}
          </S.Text>
        )}
        <input
          onChange={e => handleAttachedFile(e.target.files)}
          id={idInput}
          name={idInput}
          type="file"
          disabled={disabled}
          accept={typeFiles}
        />
      </S.AttachFile>

      <ConfirmModal
        handleClose={() => setConfirmDelete(false)}
        open={confirmDelete}
        title="Excluir arquivo?"
        subtitle="O arquivo será removido permanentemente do servidor. Deseja prosseguir?"
        titleIcon={<DeleteOutline />}
        buttonText="Confirmar"
        onClick={() => handleRemoveFile()}
        loading={loading}
      />
    </>
  );
};

export default AttachFile;
