import styled, { css } from 'styled-components';
import { Collapse } from '@mui/material';

export const Box = styled.div`
  ${({ theme }) => css`
    pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
    width: 100%;
    background-color: #fff;
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
  `}
`;

export const ContentLimit = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.palette.words.text.light};
    border-radius: 5px;
  `}
`;

export const BoxConfigure = styled.div`
  ${({ theme }) => css`
    min-height: 47px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid ${theme.palette.words.text.light};

    &.repetable {
      opacity: ${props => (props.repeat ? 1 : 0.5)};
    }

    .textArea {
      font-size: 17px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      display: flex;
      align-items: center;

      .tooltip {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  `}
`;

export const BoxCollapse = styled.div`
  ${({ theme }) => css`
    padding: 15px 20px;
    min-height: 50px;
    cursor: pointer;
    border-bottom: 1px solid ${theme.palette.words.text.light};

    &.lastCollapse {
      border-bottom: none;
    }

    .clickColl {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: ${props => (props.hasTest ? 1 : 0.5)};

      .leftSide {
        display: flex;
        align-items: center;
        justify-content: start;

        .textArea {
          font-size: 17px;
          font-weight: 600;
          color: ${theme.palette.words.title.natural};
        }
      }

      .rigthSide {
        display: flex;
        align-items: center;

        .textArea {
          margin-right: 13px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .number {
          text-transform: lowercase;
        }
      }
    }
  `}
`;

export const CollapseMu = styled(Collapse)`
  margin-top: 15px;

  .totalContentScroll {
    overflow-y: scroll;
    max-height: 400px;
  }

  .boxTemplate {
    display: flex;
    width: 100%;
    align-items: start;
    justify-content: space-between;
    gap: 1rem;
  }

  .boxTime {
    width: 100%;

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.5rem;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 2px;
    }
  }
`;

export const StyledButton = styled.button`
  ${({ theme }) => css`
    /* Base styles */
    width: 50px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    line-height: 0px;
    outline: none;
    transition: all 0.2s ease-in-out;
    background-color: white;

    /* Hover state */
    &:hover {
      border-color: #666;
    }
  `}
`;

export const StyledInput = styled.input`
  ${({ theme, ...props }) => css`
    /* Base styles */
    width: 30%;
    height: 45px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: all 0.2s ease-in-out;

    /* Input type number specific styles */
    &[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }

    /* Remove default arrows for Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Custom arrows container */
    &::-webkit-outer-spin-button {
      position: relative;
      opacity: 1;
      margin-left: -20px;
    }

    /* Hover state */
    &:hover {
      border-color: #666;
    }

    /* Focus/Active state */
    &:focus {
      border-width: 2px;
    }
  `}
`;

export const CollapseItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: ${props =>
      props.checked ? theme.palette.semantics.feedback.unknown.light : '#0000'};
    border-radius: 4px;
    transition: all ease 0.3s;

    .infoCheck {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: start;

      .boxUser {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 25px;

        .nameElement {
          margin-left: 20px;
          font-size: 17px;
          font-weight: 600;
          color: ${theme.palette.words.title.natural};
          text-transform: capitalize;
        }
      }

      .imageArea {
        width: 38px;
        height: 38px;
        border-radius: 38px;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 100%;
          height: 100%;
          background-color: ${theme.palette.semantics.feedback.unknown.light};
          border-radius: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: 700;
          color: ${theme.palette.words.title.natural};
        }
      }
    }

    .locale {
      flex: 1;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }

    .workArea {
      flex: 1;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
