import React, { useMemo } from 'react';
import { SingleTable } from '../../Components/SingleTable';
import { columns } from './constants';

export const MaisSeguros = ({ resData, isFetching }) => {
  const data = useMemo(() => {
    const _data = resData?.data?.data?.geral ?? [];
    return _data.slice(0, 5);
  }, [resData]);

  return (
    <SingleTable
      title="Motoristas mais seguros"
      subtitle="Últimos 30 dias"
      data={data}
      columns={columns}
      link={{
        to: '/motoristas/rankings',
        children: 'Ver todos',
        justify: 'right',
      }}
      loading={isFetching}
    />
  );
};
