// React
import React, { useState, useEffect } from 'react';

// Components
import FiltersGlobal from 'components/FiltersGlobal';

import { formatNewDate } from 'utils/dates';
import * as S from './styled';

// Utils
import { items } from '../../items';

const Filters = ({
  setFieldsFilter,
  historySelected,
  setHistorySelected,
  historyForm,
  setPassByFilter,
  fieldsFilter = [],
  isHistory,
  customComponent = <></>,
  handleExport = () => {},
  isLoadingExport = false,
}) => {
  const [idsFieldsFilterAuto, setIdsFieldsFilterAuto] = useState([]);
  const [idsFieldsFilterGen, setIdsFieldsFilterGen] = useState([]);

  // opcoes filters
  const [itemsAutoFilters, setItemsAutoFilters] = useState([]);
  const [itemsGeneralFilters, setItemsGeneralFilters] = useState([]);

  useEffect(() => {
    let isAddAuto = [];
    const itemsAutoFilters = items[1].types.map(item => {
      if (fieldsFilter.includes(item.id_tipo_campo.toString())) {
        isAddAuto.push(item.id_tipo_campo);
      }
      return {
        value: item.id_tipo_campo,
        label: item.nome,
      };
    });

    setItemsAutoFilters(itemsAutoFilters);
    setIdsFieldsFilterAuto(isAddAuto);

    let isAddGen = [];
    const itemsGeneralFilters = items[0].types.map(item => {
      if (fieldsFilter.includes(item.id_tipo_campo.toString())) {
        isAddGen.push(item.id_tipo_campo);
      }
      return {
        value: item.id_tipo_campo,
        label: item.nome,
      };
    });

    setItemsGeneralFilters(itemsGeneralFilters);
    setIdsFieldsFilterGen(isAddGen);
  }, []);

  const handleSave = ({
    filterPeriod,
    idsFilterAuto = [],
    idsFilterGeneral = [],
  }) => {
    if (isHistory && filterPeriod !== historySelected) {
      setHistorySelected(filterPeriod);
      setPassByFilter(true);
    }

    setFieldsFilter([...idsFilterAuto, ...idsFilterGeneral]);
  };

  const handleClear = () => {
    setIdsFieldsFilterAuto([]);
    setIdsFieldsFilterGen([]);
    setFieldsFilter([]);
    setTotalFilter(isHistory ? 1 : 0);
  };

  const filterOptions = [
    {
      filterName: 'idsFilterGeneral',
      label: 'Campos Gerais',
      options: itemsGeneralFilters ?? [],
    },
    {
      filterName: 'idsFilterAuto',
      label: 'Campos Automáticos',
      options: itemsAutoFilters ?? [],
    },
  ];

  if (isHistory)
    filterOptions.unshift({
      filterName: 'filterPeriod',
      label: 'Histórico',
      multiple: false,
      options: historyForm.map(item => ({
        value: item.id,
        label: `${
          item?.concluido ? 'Concluído em:' : 'Iniciado em:'
        } ${formatNewDate(
          item?.concluido ? item.data_concluido : item?.createdAt,
        )}`,
        endAdornment: (
          <S.HistoryStatus concluido={item?.concluido}>
            {item?.concluido ? 'Concluído' : 'Não Concluído'}
          </S.HistoryStatus>
        ),
      })),
    });

  return (
    <FiltersGlobal
      hideDate
      hideRefleshButton
      data={filterOptions}
      filterDescription=""
      defaultDate={{
        filterPeriod: isHistory ? historySelected : undefined,
        idsFilterAuto: idsFieldsFilterAuto,
        idsFilterGeneral: idsFieldsFilterGen,
      }}
      handleFilters={handleSave}
      customComponent={customComponent}
      handleExport={handleExport}
      isLoadingExport={isLoadingExport}
    />
  );
};

export default Filters;
