import styled from 'styled-components';

export const ContentContainer = styled.div`
  margin-left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// container da página
export const PageThreeContainer = styled.div`
  display: flex;
`;

// container de novo certificado
export const NovoCertificadoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;

export const TitleAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const NovoCertificadoTitle = styled.h1`
  color: var(--neutral400, #939aab);
  font-family: Texta;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: 15px;
  margin: 0;
`;

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px; /* Espaçamento menor entre título e underline */
`;

export const NovoCertificadoEndIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
`;

export const NovoCertificadoUnderline = styled.div`
  width: 98%;
  height: 1px;
  align-self: center;
  background-color: var(--neutral400, #939aab);
`;

export const PreviewEPreviewConfigContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const LeftButtonGroup = styled(ButtonGroup)`
  /* Estilos específicos para o grupo da esquerda se necessário */
`;

export const RightButtonGroup = styled(ButtonGroup)`
  /* Estilos específicos para o grupo da direita se necessário */
`;
