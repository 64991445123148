import React, { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Divider, Grid, Link, SvgIcon, Tooltip } from '@mui/material';
import { InfoOutlined, WarningAmberRounded } from '@mui/icons-material';

import { DefaultTable } from 'components/_Table/templates/default';
import TextInput from 'components/Inputs/TextField';
import FakeTextField from 'components/Inputs/FakeTextField';
import { ReactComponent as anexar } from 'images/icons/inputs/anexar.svg';
import { usePlans } from 'hooks/usePlans';
import { formatNewDate } from 'utils/dates';

import { requestCertificate } from 'pages/CapacitacaoV2/MeuPerfil/services';
import axios from 'axios';
import * as S from '../styled';
import { columns, formatData } from './columns';
import { getStatus } from '../../ListAcoes/constants';
import { requestDriverLastTrip } from '../../../../Provider/Motoristas/services.js';

const Info = ({ acaoSuspensao, isSuspensao, desvios, loading }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isProvider, isOpLogistico, hasTorrePlus } = usePlans();
  const isEmbarcador = isProvider && !isOpLogistico && !hasTorrePlus;
  const hasImage = isEmbarcador
    ? acaoSuspensao.empresa?.logo_url
      ? acaoSuspensao.empresa.logo_url
      : false
    : acaoSuspensao?.motorista?.foto
    ? acaoSuspensao?.motorista.foto
    : false;

  const [ultimaViagem, setUltimaViagem] = useState();

  const fetchUltimaViagem = async idMotorista => {
    const ultimaViagemInfo = await requestDriverLastTrip(idMotorista);
    setUltimaViagem(ultimaViagemInfo?.data?.ultima_viagem);
  };

  // --------------------------------------------DOWNLOAD--------------------------------------------
  const thirtyPartyRequest = async (url, motorista) => {
    try {
      await axios
        .get(url, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Certificado_${motorista}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => toast.warn(error));
    } catch (err) {
      toast.warn('Token de acesso expirado!');
    }
  };

  const downloadCertification = async (
    certificationId,
    nota,
    contentId,
    motorista,
  ) => {
    const cert = await requestCertificate(certificationId, { nota, contentId });
    if (cert.data.success) {
      const link = cert.data.data.link;
      if (link) {
        thirtyPartyRequest(link, motorista);
      }
    } else toast.error(cert.data.message);
  };

  const actions = [
    {
      title: 'Visualizar',
      function: id => navigate(`/desvios/${id}`),
    },
  ];

  const conteudo =
    acaoSuspensao?.acao_suspensao_faixa?.conteudo ??
    acaoSuspensao?.acao_suspensao_reincidencia?.conteudo ??
    null;

  const IsRestrictionSafe = date => {
    const newDateObj = new Date(date);

    // Verifique se acaoSuspensao e suas propriedades não são nulos ou indefinidos
    if (
      !acaoSuspensao ||
      !acaoSuspensao.data_inicio ||
      !acaoSuspensao.acao_suspensao_reincidencia ||
      acaoSuspensao.acao_suspensao_reincidencia.dias == null
    ) {
      // Se qualquer propriedade necessária for nula ou indefinida, retorne false ou trate o caso de outra forma
      return false;
    }

    const acaoInicio = new Date(acaoSuspensao.data_inicio);
    const periodoFim = addDays(
      acaoInicio,
      acaoSuspensao.acao_suspensao_reincidencia.dias,
    );

    return (
      newDateObj.getTime() < acaoInicio.getTime() ||
      newDateObj.getTime() > periodoFim.getTime()
    );
  };

  useEffect(() => {
    if (acaoSuspensao?.status === 'BLOQUEADO')
      fetchUltimaViagem(acaoSuspensao?.motorista.id);
  }, [acaoSuspensao]);

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} sm={12} display="flex" flexDirection="row">
          <S.StyledLogoWrapper>
            <S.StyledLogo backgroundImage={hasImage}>
              {!hasImage && (
                <h2>
                  {isEmbarcador
                    ? acaoSuspensao?.empresa?.nome[0]
                    : acaoSuspensao?.motorista?.nome[0]}
                </h2>
              )}
            </S.StyledLogo>
          </S.StyledLogoWrapper>

          <S.SpacedRow style={{ width: '100%' }}>
            <S.StyledTextWrapper>
              <h1>
                {isEmbarcador
                  ? acaoSuspensao?.empresa?.nome.toLowerCase()
                  : acaoSuspensao?.motorista?.nome.toLowerCase()}
              </h1>
              {!hasTorrePlus && (
                <p>Filial: {acaoSuspensao?.motorista?.filial?.nome}</p>
              )}
              {hasTorrePlus && <p>Empresa: {acaoSuspensao?.empresa?.nome}</p>}
            </S.StyledTextWrapper>
            {isEmbarcador && (
              <S.StyledTextWrapper>
                <h1>
                  Matrícula:{' '}
                  {acaoSuspensao?.motorista?.matricula?.toLowerCase()}
                </h1>
              </S.StyledTextWrapper>
            )}
          </S.SpacedRow>
        </Grid>

        <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

        <Grid item xs={8} sm={8}>
          <TextInput
            id="acao"
            label="Ação Disciplinar"
            startIcon={
              !isEmbarcador ||
              acaoSuspensao?.acao_suspensao_faixa?.is_obrigatorio ? (
                <Tooltip title="Suspensão e capacitação obrigatórios">
                  <InfoOutlined
                    style={{
                      color: theme.palette.semantics.feedback.attention.natural,
                    }}
                  />
                </Tooltip>
              ) : null
            }
            value={acaoSuspensao?.acao_suspensao_faixa?.acao_disciplinar.nome}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={4} sm={4}>
          <TextInput
            id="pontos"
            label="Pontuação"
            value={acaoSuspensao?.acao_suspensao_faixa?.limiar}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextInput
            id="faixa"
            label="Faixa"
            value={acaoSuspensao?.acao_suspensao_faixa?.titulo}
            inputProps={{ readOnly: true }}
            variant="filled"
            fillcolor={acaoSuspensao?.acao_suspensao_faixa?.cor}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextInput
            id="inicio"
            label="Data do alcance de faixa"
            value={formatNewDate(acaoSuspensao?.data_inicio)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        {isSuspensao && (
          <>
            <Grid item xs={6} sm={4}>
              <TextInput
                id="fim"
                label="Data prog. para liberação"
                value={formatNewDate(
                  new Date(
                    new Date(acaoSuspensao?.data_inicio).getTime() +
                      (acaoSuspensao?.acao_suspensao_reincidencia?.dias || 0) *
                        24 *
                        60 *
                        60 *
                        1000,
                  ),
                )}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                id="reincidencia"
                label="Incidência/Reincidência"
                value={acaoSuspensao?.acao_suspensao_reincidencia?.reincidencia}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextInput
                id="dias"
                label="Dias de suspensão"
                value={acaoSuspensao?.acao_suspensao_reincidencia?.dias}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          </>
        )}

        <Grid item xs={6} sm={4}>
          <TextInput
            id="fim"
            label="Data de finalização"
            value={formatNewDate(acaoSuspensao?.data_fim)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
        {acaoSuspensao?.status === 'BLOQUEADO' && ultimaViagem ? (
          <Grid item xs={12} sm={12}>
            <TextInput
              id="fim"
              label="Última viagem registrada"
              value={ultimaViagem ? formatNewDate(ultimaViagem.data_fim) : ''}
              inputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                acaoSuspensao?.motorista?.viagens?.length &&
                IsRestrictionSafe(
                  acaoSuspensao.motorista.viagens[
                    acaoSuspensao.motorista.viagens.length - 1
                  ]?.data_fim,
                ) ? (
                  <></>
                ) : (
                  <WarningAmberRounded
                    style={{
                      color: theme.palette.semantics.feedback.attention.natural,
                    }}
                  />
                )
              }
              fillcolor={
                ultimaViagem && IsRestrictionSafe(ultimaViagem.data_fim)
                  ? undefined
                  : theme.palette.semantics.feedback.attention.natural
              }
            />
          </Grid>
        ) : acaoSuspensao?.status === 'BLOQUEADO' && !ultimaViagem ? (
          <Grid item xs={12} sm={12}>
            <TextInput
              id="fim"
              label="Última viagem registrada"
              value="Sem registro de viagens"
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12} sm={8}>
          <FakeTextField disabled label="Capacitação">
            {conteudo && (
              <Link
                href={`${window.location.origin}/capacitacao/editar/${conteudo.id}`}
                target="_blank"
              >
                {conteudo.titulo}
              </Link>
            )}
          </FakeTextField>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextInput
            id="status"
            label="Status"
            value={getStatus(acaoSuspensao?.status, isSuspensao)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FakeTextField disabled label="Certificado">
            {acaoSuspensao?.motorista?.capacitacao_certificados?.[0] && (
              <>
                <SvgIcon component={anexar} />
                <Link
                  onClick={() => {
                    const certification =
                      acaoSuspensao?.motorista.capacitacao_certificados[0];
                    downloadCertification(
                      certification.id,
                      certification.conteudo?.respostas?.[0].nota_percentual,
                      certification.conteudo?.id,
                      acaoSuspensao?.motorista?.nome,
                    );
                  }}
                  target="_blank"
                  download
                >
                  Certificado.pdf
                </Link>
              </>
            )}
          </FakeTextField>
        </Grid>
      </Grid>

      <br />
      <Divider sx={{ width: '100%' }} />
      <br />
      <div style={{ width: '100%' }}>
        <h2>Desvios Contabilizados</h2>
      </div>
      <br />
      <DefaultTable
        local
        actions={actions}
        columns={columns}
        loading={loading}
        data={desvios?.data ? formatData(desvios.data) : []}
        fixedItems={10}
        searchKeys={['desvio']}
        placeholder="Buscar Desvio"
        empty={{
          title: 'Ops! Não há desvios registrados.',
          subtitle: 'Verifique o período selecionado',
        }}
      />
    </S.ColumnWrapper>
  );
};

export default Info;
