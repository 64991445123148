import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const InfoLine = styled.div`
  ${({ theme }) => css`
    grid-area: infos;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    .flex {
      display: flex;
      gap: 1rem;
    }

    .period {
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 20px;
    }

    .filters {
      display: flex;
      align-items: center;

      .label {
        font-weight: 500;
        color: ${theme.palette.words.title.natural};
        margin-right: 10px;
        font-size: 14px;
      }

      .arrow {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};
        }
      }

      .textMedium {
        margin: 0px 15px;
        font-size: 18px;
        font-weight: 700;
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const BoxInfos = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const Card = styled.div`
  ${({ theme, width, onClick }) => css`
    display: flex;
    width: ${width ?? '100%'};
    height: 80px;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    cursor: ${onClick ? 'pointer' : 'default'};

    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 1rem;

    .textFields {
      display: flex;
      flex-direction: ${width ? 'column' : 'row'};
      justify-content: center;
      align-items: ${width ? 'start' : 'center'};
      gap: 0.5rem;
    }

    .text {
      font-weight: 500;
      font-size: 18px;
      color: ${theme.palette.words.title.natural};
    }

    .value {
      font-weight: 900;
      font-size: 16px;
    }
  `}
`;

export const TableArea = styled.div``;

export const TableHeader = styled.div`
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: start;
`;

export const BoxLoading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Paragraph = styled.p`
  ${({ theme, center }) => css`
    font-weight: bold;
    color: ${theme.palette.brand.primary.natural};
    font-size: 14px;
    text-align: ${center ? 'center' : 'start'};

    &.normal {
      font-size: 16px;
    }

    &.name {
      text-transform: capitalize;
    }

    &.small {
      font-weight: 500;
      font-size: 12px;
    }

    &.green {
      color: ${theme.palette.semantics.feedback.success.natural};
      background-color: ${theme.palette.semantics.feedback.success.light};
      border-radius: 20px;
      text-align: center;
    }

    &.orange {
      color: ${theme.palette.semantics.feedback.warning.natural};
      background-color: ${theme.palette.semantics.feedback.warning.light};
      border-radius: 20px;
      text-align: center;
    }

    &.red {
      color: ${theme.palette.semantics.feedback.attention.natural};
      background-color: ${theme.palette.semantics.feedback.attention.light};
      border-radius: 20px;
      text-align: center;
    }

    &.grey {
      color: ${theme.palette.words.title.natural};
      background-color: #edf2f9;
      border-radius: 20px;
      text-align: center;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PTable = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: '16px';
    font-weight: 600;
  `}
`;

export const Popup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 120px;

    button {
      width: 100%;
      border: none;
      background-color: ${theme.palette.system.overlay};
      border-radius: none;
      text-align: start;
      padding: 8px 10px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 15px;
      transition: all ease 0.2s;

      span {
        font-size: 10px;
        font-weight: 600;
        margin-left: 5px;
      }

      &:hover {
        background-color: ${theme.palette.system.border};
      }
    }
  `}
`;

export const ListUsers = styled.div`
  ${({ theme }) => css`
    .title {
      font-weight: bold;
      color: ${theme.palette.words.title.natural};
    }

    ul {
      margin-top: 3px;
      margin-left: 17px;
    }

    ul li {
      list-style: disc;
    }
  `}
`;
