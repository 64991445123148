export const columns = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => String(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    accessor: d => String(d.tipo),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Função',
    id: 'funcao',
    accessor: d => String(d.funcao),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Filial',
    id: 'filial_name',
    accessor: d => String(d.filial_name),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Status',
    id: 'ativo',
    accessor: d => String(d.status),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Grupos',
    id: 'grupos',
    accessor: d => String(d.turmas),
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];
