import React from 'react';
import * as S from './styled';
import { Controller } from 'react-hook-form';
import { objectGet } from 'utils/helpers';

const TextField = React.forwardRef(
  ({ control, name, rules, errors, ...props }, ref) => {
    const error = errors && objectGet(errors, name);
    return (
      <Controller
        ref={ref}
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <S.StyledTextField
            helperText={error?.message || null}
            variant="standard"
            error={!!error}
            {...props}
            {...field}
          />
        )}
      />
    );
  },
);

export default TextField;
