export const columns = [
  {
    header: 'Ocorrência',
    id: 'ocorrencia',
    type: 'string',
    sort: true,
  },
  {
    header: 'Criação',
    id: 'data_evento',
    type: 'date',
    sort: true,
  },
  {
    header: 'Vencimento',
    id: 'data_vencimento',
    type: 'date',
    sort: true,
  },
  {
    header: 'Responsável',
    id: 'responsavel.nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'FINALIZADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
