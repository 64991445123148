import { useMemo, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { useForm } from 'react-hook-form';
import { addHours, addMonths } from 'date-fns';
import { IconButton } from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import Icon from 'components/Icons';
import GhostButton from 'components/Buttons/Ghost';
import DropZoneCustom from 'components/Inputs/_withController/DropZoneCustom';
import Calendar from 'components/Inputs/_withController/Calendar';
import DateHour from 'components/Inputs/_withController/DateHour';
import SelectOne from 'components/Inputs/_withController/SelectOne';
import InputAttachedFile from 'components/Inputs/_withController/InputAttachedFile';
import SelectCreatable from 'components/Inputs/_withController/SelectCreatable';
import SelectMultiple from '../../../Configuracoes/EscalationList/NovaLista/components/SelectMultiple';
import ConstantsUpdater from 'services/updateConstants';
import { usePlans } from 'hooks/usePlans';

import * as S from './styled';
import {
  saveDesvioManual,
  getTiposDesvios,
  getPlanosAcao,
  createPlanoAcao,
  desactivatePlanoAcao,
  getDriversProvider,
  getClients,
  getResponsaveisDesvio,
} from './services';
import { toast } from 'react-toastify';

const defaultValues = {
  id: null,
  data_desvio: '',
  id_desvio_tipo: null,
};

const DesvioManualModal = ({ handleClose, open, refetchDesvios }) => {
  const theme = useTheme();
  const { isProvider, hasTorrePlus } = usePlans();

  const grupos = [
    { value: 1, label: 'Administrador' },
    { value: 2, label: 'Analista' },
    { value: 3, label: 'Operacional' },
    { value: 4, label: 'Convidado' },
  ];

  const [loadingSaveOpen, setLoadingSaveOpen] = useState(false);
  const [loadingSaveApprove, setLoadingSaveApprove] = useState(false);
  const [deletePlanoAcaoError, setDeletePlanoAcaoError] = useState(false);
  const [selectedResponsaveis, setSelectedResponsaveis] = useState([]);
  const [selectedClient, setSelectedClient] = useState(38); // Outra
  const [responsaveis, setResponsaveis] = useState([]);

  const selects = useSelector(state => {
    return state.selects;
  });

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const { data: _tiposDesvios = [] } = useQuery(
    ['tipos-desvios', selectedClient],
    () => getTiposDesvios(user.provider ? {} : { cliente: selectedClient }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: driversProvider = [] } = useQuery(
    ['drivers-provider'],
    () => getDriversProvider(),
    {
      enabled: user.provider,
      refetchOnWindowFocus: false,
    },
  );

  const tiposDesvios = _tiposDesvios.map(item => ({
    text: item.titulo,
    value: item.id,
    id: item.id,
    removable: true,
  }));

  const { data: _planosAcao = [], refetch: fetchPlanosAcao } = useQuery(
    ['planos-acao'],
    () => getPlanosAcao(),
    {
      staleTime: Infinity,
    },
  );

  const { data: dataSelects } = useQuery(
    ['responsaveis-desvio'],
    () => getResponsaveisDesvio(),
    {
      onSuccess: (data = []) => {
        setResponsaveis(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const planosAcao = _planosAcao.map(item => ({
    text: item.titulo,
    value: item.id,
    id: item.id,
    removable: !!item.id_empresa,
  }));

  const { data: _clients = [] } = useQuery(['clients'], () => getClients(), {
    staleTime: Infinity,
  });

  const clients = _clients.map(item => ({
    text: item.label,
    value: item.value,
    id: item.value,
  }));

  const motoristas = useMemo(() => {
    if (user.provider) return driversProvider;
    return selects.drivers
      ?.filter(motorista => motorista.status === 'ATIVO')
      .map(motorista => ({
        text: motorista.nome,
        value: motorista.id,
      }));
  }, [selects.drivers, user.provider, driversProvider]);

  const caminhoes = useMemo(() => {
    const key = user.provider ? 'trucks-viagens' : 'trucks';
    return selects[key]
      ?.filter(truck => truck.status === 'ATIVO')
      .map(truck => ({
        text: truck.placa,
        value: truck.id,
      }));
  }, [selects.trucks, selects['trucks-viagens']]);

  const empresas =
    selects.empresas?.map(empresa => ({
      text: empresa.nome,
      value: empresa.id,
      id: empresa.id,
    })) || [];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: user.provider
      ? defaultValues
      : { ...defaultValues, id_da_distribuidora: 38 },
  });

  const id_da_distribuidora = watch('id_da_distribuidora');

  useEffect(() => {
    setSelectedClient(id_da_distribuidora);
    setValue('id_desvio_tipo', null);
  }, [id_da_distribuidora]);

  const onClose = () => {
    reset(defaultValues);
    if (handleClose instanceof Function) handleClose();
  };

  const handleAddPlanoAcao = async titulo => {
    try {
      if (titulo?.length > 255) {
        toast.error('O plano de ação tem limite de 255 caracteres!');
        return;
      }
      const req = await createPlanoAcao(titulo);
      if (req.success) {
        toast.success(req.message);
        fetchPlanosAcao();
      } else toast.error(req.message);
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const handleDeletePlanoAcao = async id => {
    try {
      const req = await desactivatePlanoAcao(id);

      if (req.success) {
        toast.success(req.message);
        fetchPlanosAcao();
      } else if (req.status === 406) {
        setDeletePlanoAcaoError(
          'Existe um ou mais desvios que utilizam este plano de açao.',
        );
      } else {
        toast.error(req.message);
      }
      setDeletePlanoAcaoError(null);
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const onSubmit = async (_data, status = null) => {
    try {
      const plano_acao =
        planosAcao.find(plano => plano.value == _data.id_plano_acao)?.text ||
        '';
      if (!plano_acao) {
        toast.error('Plano de ação não encontrado');
        return;
      }

      if (status === 'ABERTO') {
        setLoadingSaveOpen(true);
      } else {
        setLoadingSaveApprove(true);
      }

      const payload = {
        ..._data,
        plano_acao,
        ids_responsaveis: selectedResponsaveis,
      };

      if (status) {
        payload.status = status;
      }

      payload.data_desvio = addHours(payload.data_desvio, -3);
      delete payload.final;

      const req = await saveDesvioManual(payload);

      setLoadingSaveOpen(false);
      setLoadingSaveApprove(false);

      if (req.success) {
        toast.success(req.message);
        refetchDesvios();
        onClose();
      } else toast.error(req.message);
    } catch (error) {
      setLoadingSaveOpen(false);
      setLoadingSaveApprove(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  const handleRemoveResponsavel = id => {
    setSelectedResponsaveis(
      selectedResponsaveis.filter(item => item.id !== id),
    );
  };

  const namesConstants = user.provider
    ? ['trucks-viagens', 'drivers-provider']
    : ['trucks', 'drivers'];

  return (
    <>
      <ConstantsUpdater names={namesConstants} />

      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <form>
          <S.Container>
            <S.Header>
              <div style={{ alignItems: 'center' }}>
                <div>
                  <AddCircleOutlineOutlined
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  />
                  <h2>Adicionar Desvio Manual</h2>
                </div>
              </div>

              <IconButton size="small" onClick={() => onClose()}>
                <Icon
                  sx={{ color: theme.palette.words.subtitle.natural }}
                  name="close"
                />
              </IconButton>
            </S.Header>

            <S.Main>
              <S.Grid>
                <div className="fullWidth">
                  <SelectOne
                    control={control}
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    errors={errors}
                    name="id_motorista"
                    label="Motorista"
                    data={motoristas || []}
                    required
                  />
                </div>

                <div className="fullWidth">
                  <SelectOne
                    control={control}
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    errors={errors}
                    name="id_caminhao"
                    label="Placa"
                    data={caminhoes || []}
                    required
                  />
                </div>

                <div className="fullWidth">
                  <SelectOne
                    errors={errors}
                    control={control}
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    name={
                      user.provider ? 'id_da_empresa' : 'id_da_distribuidora'
                    }
                    label={user.provider ? 'Empresa' : 'Cliente'}
                    data={user.provider ? empresas : clients}
                    required
                  />
                </div>

                <DateHour
                  control={control}
                  errors={errors}
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  required
                  label="Data do desvio"
                  name="data_desvio"
                  minDate={addMonths(new Date(), -6)}
                />

                <Calendar
                  control={control}
                  errors={errors}
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  required
                  label="Data do plano de ação"
                  name="data_plano_acao"
                />

                <div className="fullWidth">
                  <SelectOne
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    name="id_desvio_tipo"
                    label="Desvio"
                    data={tiposDesvios || []}
                    required
                  />
                </div>

                <div className="fullWidth">
                  <SelectMultiple
                    grupos={grupos}
                    limitRenderValue={4}
                    width="100%"
                    fieldName="ids_responsaveis"
                    data={responsaveis}
                    label={
                      <span style={{ color: 'rgb(101, 110, 140)' }}>
                        Atribuir Responsável
                      </span>
                    }
                    handleChange={setSelectedResponsaveis}
                    value={selectedResponsaveis}
                    required
                  />

                  <div style={{ display: 'flex' }}>
                    {selectedResponsaveis.map(id => (
                      <div className="label-hilight">
                        {responsaveis.find(item => item.id === id)?.value}
                        <span onClick={handleRemoveResponsavel}>X</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="fullWidth">
                  <SelectCreatable
                    required
                    control={control}
                    errors={errors}
                    name="id_plano_acao"
                    data={planosAcao}
                    label="Plano de ação"
                    searchPlaceHolder="Selecione o plano de ação"
                    handleNewOption={text => handleAddPlanoAcao(text)}
                    handleRemoveOption={id => handleDeletePlanoAcao(id)}
                    deleteErrorMessage={deletePlanoAcaoError}
                    setDeleteErrorMessage={setDeletePlanoAcaoError}
                    confirm
                  />
                </div>

                <div className="fullWidth">
                  <DropZoneCustom
                    control={control}
                    name="arquivo_evidencia"
                    multiple={false}
                    fileDir="desvios/evidencias"
                    automaticSave
                    label="Adicionar"
                    titleButton="Adicionar"
                    required
                    unicSize="200px"
                  />
                </div>

                <div className="fullWidth">
                  <InputAttachedFile
                    control={control}
                    name="arquivo_plano_acao"
                    label="Arquivo plano de ação"
                    inputLabel="Anexar Arquivo"
                    fileDir="desvios/arquivos"
                  />
                </div>
              </S.Grid>
            </S.Main>

            <S.Footer>
              <GhostButton
                onClick={() => {
                  onClose();
                }}
                customcolor={theme.palette.semantics.feedback.attention.dark}
                size="medium"
                disabled={loadingSaveOpen || loadingSaveApprove}
              >
                Cancelar
              </GhostButton>

              <S.SaveButtonsGroup>
                {(!isProvider || (isProvider && hasTorrePlus)) && ( // Mostrar adicionar em aberto apenas para torre plus ou não provider
                  <GhostButton
                    onClick={handleSubmit(data => onSubmit(data, 'ABERTO'))}
                    size="medium"
                    loading={loadingSaveOpen}
                    disabled={loadingSaveApprove}
                  >
                    Salvar em aberto
                  </GhostButton>
                )}

                <GhostButton
                  onClick={handleSubmit(data => onSubmit(data))}
                  size="medium"
                  loading={loadingSaveApprove}
                  disabled={loadingSaveOpen}
                >
                  Salvar e aprovar
                </GhostButton>
              </S.SaveButtonsGroup>
            </S.Footer>
          </S.Container>
        </form>
      </Modal>
    </>
  );
};

export default DesvioManualModal;
