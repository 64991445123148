import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FormControl from '@mui/material/FormControl';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { ReactComponent as IconCheckBorder } from '../../../images/icons/components/checkborder.svg';
import { ReactComponent as IconCheck } from '../../../images/icons/components/check.svg';

const SelectMultiple = ({
  value,
  handleChange = () => {},
  data = [],
  fieldName,
  label = 'Selecione',
  selectAll,
  disabled,
  required,
  placeholder,
  error,
  message,
  filialName = 'Filial',
  filialSubTitle = ' Filtrar por:',
  clearSelected = false,
  multiple = true,
  ...props
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [ids, setIds] = useState(value || []);

  const selectAllOptions = () => {
    if (!multiple) return;
    if (ids.length === data.length) {
      setIds([]);
      handleChange([]);
    } else {
      const allValues = data.map(item => item.value);
      setIds(allValues);
      handleChange(allValues);
    }
  };

  const handleSelect = selectedValue => {
    if (multiple) {
      const isSelected = ids.includes(selectedValue);
      const newIds = isSelected
        ? ids.filter(itemId => itemId !== selectedValue)
        : [...ids, selectedValue];

      setIds(newIds);
      handleChange(newIds);
    } else {
      // Para seleção única, basta setar um único valor
      const newIds = ids.includes(selectedValue) ? [] : [selectedValue];
      setIds(newIds);
      handleChange(newIds);
    }
  };

  const filteredData = data.filter(item =>
    item?.label?.toLowerCase().includes(search.toLowerCase()),
  );

  useEffect(() => {
    if (clearSelected) {
      setIds([]);
      handleChange([]);
    }
  }, [clearSelected]);

  return (
    <FormControl>
      <S.CustomSelect
        selected={ids.length > 0}
        id="demo-multiple-checkbox"
        multiple={multiple}
        error={error}
        value={ids}
        displayEmpty
        input={<S.Input />}
        renderValue={() => {
          return (
            <span className="title-select">
              {filialName} {ids.length > 0 && `(${ids.length})`}
            </span>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              minHeight: 364,
              width: 400,
              maxHeight: 'none',
            },
          },
        }}
        disabled={disabled}
      >
        <S.LineBox>
          <S.SubTitle>{filialSubTitle}</S.SubTitle>

          <S.SelectAll onClick={selectAllOptions}>
            {multiple &&
              (ids.length === data.length
                ? 'Desmarcar Todos'
                : 'Selecionar Todos')}
          </S.SelectAll>
        </S.LineBox>
        <S.SearchBox>
          <S.SearchInput
            onKeyDown={e => e.stopPropagation()}
            startAdornment={
              <SearchOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
              />
            }
            type="text"
            placeholder="Buscar..."
            onChange={e => setSearch(e.target.value)}
          />
        </S.SearchBox>

        <S.OptionsArea>
          {filteredData.length > 0 ? (
            filteredData.map(item => (
              <FormControlLabel
                key={item.value}
                control={
                  <S.CheckboxStyled
                    disableRipple
                    icon={<IconCheckBorder />}
                    checkedIcon={<IconCheck />}
                    checked={ids.includes(item.value)}
                  />
                }
                onChange={() => handleSelect(item.value)}
                label={
                  <S.LabelCheckBox checked={ids.includes(item.value)}>
                    {item.label}
                    {item.endAdornment}
                  </S.LabelCheckBox>
                }
                value="start"
                sx={{
                  '& .MuiTypography-root': {
                    width: '100%',
                  },
                }}
              />
            ))
          ) : (
            <S.EmptyOptions>Nenhuma opção disponível</S.EmptyOptions>
          )}
        </S.OptionsArea>
      </S.CustomSelect>
    </FormControl>
  );
};

export default SelectMultiple;
