import styled, { css, keyframes } from 'styled-components';

import { Modal } from '@mui/material';

const warning = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;

export const BigModal = styled(Modal)`
  margin-top: ${props => props.visible};
  transition: all ease 0.3s;
`;

export const Cover = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    position: relative;
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 70px;

    display: flex;

    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px #999;
    padding: 0px 20px;
    z-index: 9999;
    background-color: ${theme.palette.system.overlay};

    .left {
      position: absolute;
      display: flex;
      left: 0;
      margin-left: 30px;
      gap: 1rem;
      align-items: center;
    }

    .right {
      position: absolute;
      right: 0;
      margin-right: 30px;
      display: flex;
      align-items: center;

      .stArea {
        margin-right: 20px;

        .previewWeb {
          font-size: 18px;
          margin-right: 20px;
          font-weight: 600;
          background-color: ${theme.palette.semantics.feedback.warning.light};
          color: ${theme.palette.semantics.feedback.warning.natural};
          border-radius: 20px;
          padding: 0px 25px;
          animation: ${warning} 2s ease infinite;
        }
      }
    }
  `}
`;

export const BackButton = styled.button`
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 20px;
  transition: all 0.2s;
  margin-right: 10px;

  :hover {
    background: ${({ theme }) => theme.palette.system.highlight};
  }

  :active {
    background: ${({ theme }) => theme.palette.brand.primary.background};
  }

  h2 {
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
    font-size: 18px;
    font-weight: 600;
  }
`;

export const InfoBoxHeader = styled.div`
  ${({ theme }) => css`
    margin-right: 30px;
    width: 215px;
    font-size: 14px;
    color: ${theme.palette.words.text.light};

    .date {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .text {
        flex: 2;
      }

      .day {
        font-weight: 600;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: start;
        color: ${theme.palette.words.title.natural};
        flex: 1;
      }
    }
  `}
`;

export const FilterBox = styled.div`
  ${({ theme }) => css`
    width: 100%;
    cursor: pointer;
    margin-right: 70px;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    display: flex;
    padding: 10px 18px;
    width: 150px;
    color: ${theme.palette.words.text.light};
    font-size: 18px;
    font-weight: 600;
    position: relative;

    &:hover {
      box-shadow: 0px 0px 10px ${theme.palette.system.disabled};
    }

    .numberFilter {
      position: absolute;
      top: 0;
      right: 0;
      background-color: ${theme.palette.semantics.feedback.attention.natural};
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
      color: ${theme.palette.words.text.contrast};
      font-size: 12px;
      margin-right: -5px;
      margin-top: -5px;
      font-weight: 600;
    }
  `}
`;

export const Body = styled.div`
  padding-top: ${props => (props.preview ? '0px' : '70px')};
  height: 100%;
  display: flex;
  z-index: 1;
`;

export const Content = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.brand.primary.background};
    width: 100%;
    padding: ${props.preview ? '0px' : '50px 180px'};
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: ${props.loading ? '0.4' : '1'};
    pointer-events: ${props.loading ? 'none' : 'auto'};

    @media (max-width: 1260px) {
      padding: ${props.preview ? '0px' : '50px 100px'};
    }

    @media (max-width: 1100px) {
      padding: ${props.preview ? '0px' : '50px 30px'};
    }

    .currentUser {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1100px;
      width: 100%;

      .rightUser {
        display: flex;
        align-items: center;
      }

      .leftUser {
        display: flex;
        align-items: center;

        .ball {
          width: 36px;
          height: 36px;
          border: 1px solid ${theme.palette.brand.secondary.natural};
          background-color: ${theme.palette.system.overlay};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 36px;
          padding: 1px;

          img {
          }

          .circle {
            font-weight: 600;
            color: ${theme.palette.words.title.natural};
            width: 100%;
            height: 100%;
            background-color: ${theme.palette.brand.primary.background};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 36px;
          }
        }

        .name {
          margin-left: 10px;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};
          font-size: 18px;
        }
      }
    }

    .printArea {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    .spacing {
      width: 100%;
      max-width: 1100px;
      margin: 1rem 0;
    }

    @media print {
      img {
        /* Ensure images are visible in print */
        display: block !important;
        max-width: 100% !important;
        page-break-inside: avoid !important;
        break-inside: avoid !important;
      }

      /* Force all content to be visible during printing */
      * {
        visibility: visible !important;
        overflow: visible !important;
      }

      /* Improve rendering of sections */
      .printArea {
        display: block !important;
        page-break-after: always !important;
      }
    }
  `}
`;

export const HeaderSection = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-top: 5px solid ${theme.palette.brand.secondary.natural};
    border-radius: 5px;
    margin-top: ${props.preview ? '0px' : '30px'};
    max-width: 1100px;
    width: 100%;

    .primaryBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      .titleForm {
        font-size: ${props.preview ? '16px' : '25px'};
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
      }

      .rightArea {
        display: flex;
        align-items: center;
      }

      .legend {
        display: flex;
        justify-content: center;
        align-items: center;

        .redPointer {
          font-size: 30px;
          color: ${theme.palette.semantics.feedback.attention.natural};
          font-weight: 600;
          margin-right: 5px;
          margin-bottom: -12px;
        }

        .textReq {
          font-weight: bold;
          color: ${theme.palette.words.subtitle.natural};
          font-size: 14px;
        }
      }
    }

    .descriptionForm {
      color: ${theme.palette.words.subtitle.natural};
      padding: 15px;
      font-weight: 500;
      font-size: ${props.preview ? '13px' : '16px'};
    }

    .cover {
      ${({ src }) => css`
        background-size: ${props.preview ? 'contain' : 'cover'};
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${src});
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${props.preview ? 'auto' : '350px'};
        max-height: ${props.preview ? '350px' : 'auto'};
        min-height: ${props.preview ? '170px' : 'auto'};
        width: 100%;

        /* Add this for better print behavior */
        @media print {
          background-image: none !important;
          min-height: auto !important;
          height: auto !important;

          &::before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 56.25%; /* 16:9 aspect ratio */
            background-image: url(${src});
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      `}
    }
  `}
`;

export const BodySection = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-left: 5px solid ${theme.palette.brand.secondary.natural};
    border-radius: 5px;
    margin-top: 30px;
    max-width: 1100px;
    width: 100%;

    .topArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      .titleSection {
        font-size: 20px;
        font-weight: 800;
        color: ${theme.palette.words.title.natural};
      }

      .counterSec {
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        font-size: ${props.preview ? '10px' : '14px'};
      }
    }

    .descriptSection {
      color: ${theme.palette.words.subtitle.natural};
      padding: 15px;
      font-weight: 500;
    }
  `}
`;

export const FooterSection = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-left: 5px solid ${theme.palette.brand.secondary.natural};
    border-radius: 5px;
    padding: 0px 20px;
    max-width: 1100px;
    width: 100%;

    .headerArea {
      height: 60px;
      font-weight: 800;
      font-size: 23px;
      color: ${theme.palette.words.title.natural};
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .userArea {
      margin-top: 20px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .title {
          font-weight: 800;
          color: ${theme.palette.words.title.natural};
          font-size: 18px;
        }

        .status {
          font-weight: 600;
          padding: 0px 15px;
          border-radius: 50px;
          font-size: 15px;
        }
      }

      .medium {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 20px;

        .ball {
          width: 45px;
          height: 45px;
          border: 1px solid ${theme.palette.brand.secondary.natural};
          background-color: ${theme.palette.system.overlay};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 36px;
          padding: 1px;

          img {
          }

          .circle {
            font-weight: 600;
            color: ${theme.palette.words.title.natural};
            width: 90%;
            height: 90%;
            background-color: ${theme.palette.brand.primary.background};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 36px;
          }
        }

        .nameArea {
          margin-left: 10px;

          .mame {
            font-weight: 600;
            color: ${theme.palette.words.title.natural};
          }

          .concluded {
            font-size: 13px;
            color: ${theme.palette.words.subtitle.natural};
            font-weight: 500;
          }
        }
      }

      .bottom {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 20px;
      }
    }
  `}
`;

export const EmptyAreaFilter = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-left: 5px solid ${theme.palette.brand.secondary.natural};
    border-radius: 5px;
    margin-top: 30px;
    max-width: 1100px;
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageArea {
      width: 100%;
      max-width: 300px;
      img {
        width: 100%;
      }
    }

    .textArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 22px;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
      }

      .description {
        font-size: 18px;
        font-weight: 500;
        color: ${theme.palette.words.subtitle.natural};
      }
    }
  `}
`;

export const PrevieWarning = styled.div`
  ${({ theme, ...props }) => css`
    position: fixed;
    z-index: 999;
    width: 250px;
    background-color: ${props.saveError
      ? theme.palette.semantics.feedback.attention.natural
      : theme.palette.system.shadow};
    bottom: 0;
    right: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 10px;
    padding: 10px;
    font-weight: 600;
    flex-direction: column;
    font-style: oblique;
    display: ${props.view ? 'flex' : 'none'};

    .warning {
      color: ${theme.palette.words.text.contrast};
    }

    .close {
      align-self: end;
      font-weight: 600;
      color: ${props.saveError
        ? theme.palette.brand.primary.light
        : theme.palette.semantics.feedback.information.natural};
      margin: 10px;
      cursor: pointer;
    }
  `}
`;

export const TableContainer = styled.div`
  ${({ theme, ...props }) => css`
    margin-top: ${props.preview ? '0px' : '30px'};
    max-width: 1100px;
    width: 100%;
  `}
`;
