import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Ranking, { useFilterFields } from 'pages/_templates/Ranking';

import { trackEvent } from 'utils/mixpanel';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';
import { useQuery } from 'react-query';

import { requestDriversRanking } from './services';
import { categorias, columns, getExcelFields, fields } from './constants';
import { getRouterQueryParams } from 'utils/router-query-params';
import { generateFileName } from 'utils/generateFileName';

import ExcelModal from 'components/ExcelModalNew';

const RankingMotoristas = () => {
  const navigate = useNavigate();
  const {
    planosAtivos,
    isOpLogistico,
    isProvider,
    hasTorrePlus,
    hasMonitoramento,
    hasTelemetria,
    hasFadiga,
  } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);

  const [openExcelModal, setOpenExcelModal] = useState(false);

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.motoristas_ranking;
  });

  const [tab, setTab] = useState('geral');
  const [query, setQuery] = useState(null);
  const [podium, setPodium] = useState([]);

  const order = getRouterQueryParams({ location: useLocation }).get('order');

  const {
    isFetching,
    data: resData,
    refetch,
  } = useQuery(
    ['ranking', query],
    () => query && requestDriversRanking({ ...query }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Processa os dados da resposta para copiar os pontos do manual para o geral
  // quando não tiver fadiga, telemetria e monitoramento
  useEffect(() => {
    if (resData?.data && !hasFadiga && !hasTelemetria && !hasMonitoramento) {
      // Se não tiver as funcionalidades, copia os pontos do manual para o geral
      if (resData.data.resumo?.manual?.pontos && resData.data.resumo?.geral) {
        resData.data.resumo.geral.pontos = resData.data.resumo.manual.pontos;

        // Também copia outros campos relevantes, se necessário
        if (resData.data.resumo.manual.total_pontos) {
          resData.data.resumo.geral.total_pontos =
            resData.data.resumo.manual.total_pontos;
        }
      }
    }
  }, [resData, hasFadiga, hasTelemetria, hasMonitoramento]);

  const handleRefetch = () => {
    refetch();
  };

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    setTab(newValue);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: tab,
    items: categorias.filter(
      item =>
        !item.plan ||
        planosAtivos.some(plano => plano.id_do_plano === item.plan),
    ),
    onChange: handleStatusTab,
    disabled: isFetching,
  };

  useEffect(() => {
    setQuery({ ...filtersPersist, tipo: 'motorista', tab });
  }, [filtersPersist, tab]);

  useEffect(() => {
    if (resData?.data?.data) {
      const podio = resData.data.data[tab]?.slice(0, 3) ?? [];
      setPodium(
        podio.map(item => ({
          nameOne: item.nome ?? item.nome_motorista,
          position: item.posicao,
          oldPosition: item.posicao_anterior
            ? item.posicao_anterior > item.posicao
              ? 'UP'
              : item.posicao_anterior < item.posicao
              ? 'DOWN'
              : 'EQUAL'
            : 'UP',
          logo: item.foto,
          value: item.valor ?? item.total_pontos,
        })),
      );
    }
  }, [resData, tab]);

  const swapConditional = pts_km_hr => {
    return [
      {
        condition: (value, item) =>
          Number(item[pts_km_hr]) > Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          color: theme.palette.semantics.feedback.attention.natural,
          textAlign: 'center',
        }),
      },
      {
        condition: (value, item) =>
          Number(item[pts_km_hr]) <= Number(item.media),
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.success.light,
          color: theme.palette.semantics.feedback.success.natural,
          textAlign: 'center',
        }),
      },
    ];
  };

  const swapColumns = (columns, colId1, colId2) => {
    const index1 = columns.findIndex(col => col.id === colId1);
    const index2 = columns.findIndex(col => col.id === colId2);

    if (index1 !== -1 && index2 !== -1) {
      const newColumns = [...columns];

      const col1 = { ...newColumns[index1] };
      const col2 = { ...newColumns[index2] };

      delete col1.conditional;

      newColumns[index1] = col2;
      newColumns[index2] = col1;

      if (newColumns[index1].id === 'pontos_km') {
        newColumns[index1].conditional = swapConditional(newColumns[index1].id);
      }
      if (newColumns[index1].id === 'pontos_hora') {
        newColumns[index1].conditional = swapConditional(newColumns[index1].id);
      }

      return newColumns;
    }

    return columns;
  };

  const formattedColumns = useMemo(() => {
    let reorderedColumns = [...columns[tab]];

    if (tab === 'geral') {
      if (!hasFadiga && !hasTelemetria && !hasMonitoramento) {
        // Quando não tiver as funcionalidades, usa as colunas do manual
        reorderedColumns = [...columns.manual];
      } else if (hasMonitoramento && !(hasTelemetria && hasFadiga)) {
        reorderedColumns = swapColumns(
          reorderedColumns,
          'total_pontos',
          'pontos_hora',
        );
      } else if ((hasTelemetria || hasFadiga) && !hasMonitoramento) {
        reorderedColumns = swapColumns(
          reorderedColumns,
          'total_pontos',
          'pontos_km',
        );
      }
    }

    return reorderedColumns
      ?.filter(
        col =>
          !col.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            col.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        if (item.id === 'nome_motorista') {
          if (hasTorrePlus)
            return {
              ...item,
              subRow: {
                value: (_, item) => item?.nome_empresa,
              },
            };
          if (!isOpLogistico)
            return {
              ...item,
              subRow: {
                children: (_, item) => (
                  <span style={{ textTransform: 'none' }}>
                    Filial: {item?.nome_filial}
                  </span>
                ),
              },
            };
        }
        return item;
      });
  }, [
    query,
    isOpLogistico,
    tab,
    hasFadiga,
    hasTelemetria,
    hasMonitoramento,
    hasTorrePlus,
  ]);

  const actions = [
    {
      title: 'Ver perfil',
      function: id => {
        if (id) navigate(`/motoristas/${id}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  const formatResumo = useMemo(() => {
    let currentTab = tab;
    let resumoData = resData?.data?.resumo;

    // Se estamos na aba geral e não temos as funcionalidades,
    // usa os campos e dados do resumo manual
    if (
      currentTab === 'geral' &&
      !hasFadiga &&
      !hasTelemetria &&
      !hasMonitoramento
    ) {
      if (resumoData?.manual && resumoData.geral) {
        // Copia todos os dados do resumo manual para o resumo geral
        Object.keys(resumoData.manual).forEach(key => {
          resumoData.geral[key] = resumoData.manual[key];
        });
      }
    }

    return fields[currentTab]
      .filter(
        item =>
          !item.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            item.plans.includes(id_do_plano),
          ),
      )
      .map(item => {
        const data = resumoData?.[currentTab]?.[item.id] || '';
        return {
          ...item,
          value: item.value ? item.value(data) : data,
        };
      });
  }, [
    resData,
    fields,
    tab,
    hasFadiga,
    hasTelemetria,
    hasMonitoramento,
    planosAtivos,
  ]);

  const getDataMedia = useCallback(
    (data, tab) => {
      if (!data) return [];

      // Se estamos na aba geral e não temos as funcionalidades,
      // utiliza os dados da aba manual
      if (
        tab === 'geral' &&
        !hasFadiga &&
        !hasTelemetria &&
        !hasMonitoramento
      ) {
        // Copia os dados do manual para o geral
        if (data.data?.manual && data.resumo?.manual) {
          data.data.geral = [...data.data.manual];
          if (!data.resumo.geral) {
            data.resumo.geral = {};
          }

          // Copia os pontos do manual para o geral
          data.resumo.geral.pontos = data.resumo.manual.pontos;
        }
      }

      const getMedia = {
        geral: (resumo, dataArray) => {
          if (!hasFadiga && !hasTelemetria && !hasMonitoramento) {
            // Para geral sem funcionalidades, usa a mesma lógica do manual
            return resumo.pontos;
          }
          return resumo.pontos / (dataArray?.length || 1);
        },
        cabine: resumo => resumo.pontos_hora,
        telemetria: resumo => resumo.pontos_km,
        fadiga: resumo => resumo.pontos_km,
        manual: resumo => resumo.pontos,
        descarga: resumo => resumo.pontos_descarga,
      };

      return (
        data?.data?.[tab]?.map(item => ({
          ...item,
          media: getMedia[tab](data.resumo[tab], data.data[tab]),
        })) ?? []
      );
    },
    [hasFadiga, hasTelemetria, hasMonitoramento],
  );

  const filterFields = useFilterFields();

  return (
    <>
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={getExcelFields(tab).filter(
            item =>
              !item.plans ||
              planosAtivos.some(({ id_do_plano }) =>
                item.plans.includes(id_do_plano),
              ),
          )}
          query={{
            ...query,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={
            isProvider
              ? '/excel-provider/motoristas_ranking'
              : '/excel/motoristas_ranking'
          }
          filtersPersis={filtersPersist}
          collumns={filterFields}
          isManualDownload
        />
      )}
      <Ranking
        title="Ranking"
        filterKey="motoristas_ranking"
        headerInfo=""
        handleRefetch={handleRefetch}
        headerActions={() => {
          trackEvent(user, 'Exportar Planilha Ranking Motoristas');
          setOpenExcelModal(true);
        }}
        headerTabs={headerTabs}
        podium={podium}
        loading={isFetching}
        tableProps={{
          loading: isFetching,
          data: getDataMedia(resData?.data, tab),
          columns: formattedColumns,
          actions,
          searchKeys: ['nome_motorista'],
          placeholder: 'Buscar por Motorista',
          sortBy: { id: 'posicao', order: order ?? 'ASC' },
          searchEvent: search =>
            trackEvent(user, 'Busca Ranking de Motoristas', null, search),
          empty: {
            image: 'motorista.png',
            title: 'Ops! Não foram encontrados motoristas para essa busca.',
            description:
              'Altere os filtros ou faça o cadastro do novo motorista!',
          },
        }}
        resumo={formatResumo}
      />
    </>
  );
};

export default RankingMotoristas;
