// Styleds
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';

// Components
import { DatePicker } from './Date';
// import Table from 'components/Table';
import Loading from 'components/Loading';
import ConfirmModal from 'components/ConfirmModal';
import { Input } from 'components/Inputs/SearchInput';
import SelectMultiple from 'components/Inputs/_SelectMultiple';
import { HeaderlessTable } from 'components/_Table/templates/headerless';

// Components MUI
// Components Icons
import { ReactComponent as Info } from 'images/icons/cards/symbol_info.svg';
import { ReactComponent as Wrong } from 'images/icons/cards/card_incorreto.svg';
import { ReactComponent as Atention } from 'images/icons/cards/atention.svg';
import { ReactComponent as Check } from 'images/icons/components/rounded_check.svg';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
// import { Tooltip } from '@mui/material';
import { cardFilters, filters } from './constants';
// Services
import { formatNewHour } from 'utils/dates';
import { trackEvent } from 'utils/mixpanel';
// import qs from 'qs';
import GhostButton from 'components/Buttons/Ghost';
import * as services from '../services';

// Utils
import { fequencyModule } from '../actions';

// Columns
import {
  // columnsTotal,
  // columnsApprove,
  // columnsOcurrence,
  // columnsSmall,
  columnsTotal2,
  columnsApprove2,
  columnsOcurrence2,
  columnsSmall2,
} from './columns';
import { ModalPendentes } from './ModalPendentes';
import ModalSign from '../../components/ModalSign';
import * as S from './styled';

// filter
// import Filters from '../Filters';
import { ModalJustificativa } from './ModalJustificativa';

let timerRoute = null;
const loading = false;

const getFilteredFields = (filters, tiposCampo) => {
  if (!tiposCampo || !filters?.length) return [];
  const fieldsData = filters[0].data;
  const filteredFields = fieldsData.filter(field =>
    tiposCampo.includes(field.value),
  );

  return [
    {
      ...filters[0],
      data: filteredFields,
    },
  ];
};

export const Users = ({ select, currentSelect, setCurrentSelect }) => {
  const theme = useTheme();
  // Redux
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const formularios = useSelector(state => state.formularios);
  const gerais = formularios.gerais;
  const hasRepet = formularios.gerais.repetir;

  const navigate = useNavigate();

  // Data States
  const [dataJustify, setDataJustify] = useState([]);

  // Controllers States
  const [query, setQuery] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [signModal, setSignModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedForms, setSelectedForms] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [reportsFilters, setReportsFilters] = useState([]);

  const [formHist, setFormHist] = useState(null);
  const [modalView, setModalView] = useState({ open: false });
  const [loadingLine, setLoadingLine] = useState([]);
  const [currentHist, setCurrentHist] = useState(null);
  const [modalJustify, setModalJustify] = useState(false);
  const [modalPendding, setModalPendding] = useState(false);
  const [loadingJustify, setLoadingJustify] = useState(false);

  const [searchTrackEvent] = useDebounce(search, 1000);

  // ---------------------------------------REQUESTS---------------------------------------//

  const {
    data,
    refetch,
    isLoading: loading,
    isFetching,
  } = useQuery(
    [`/form-reports-table-${formularios.id}`, query],
    () => query && services.getInfosByPeriod(formularios.id, query),
    {
      refetchOnWindowFocus: false,
      onError: data => toast.error(data.message),
    },
  );

  useQuery(
    [`/form-tipos-campo-${formularios.id}`],
    () => services.getTiposCampoUsadosNoForm(formularios.id),
    {
      refetchOnWindowFocus: false,
      onError: data => toast.error(data.message),
      onSuccess: data => {
        if (data) {
          const filteredFilters = getFilteredFields(filters, data);
          setCurrentFilters(filteredFilters);
        }
      },
    },
  );

  const {
    data: resCards,
    isLoading: loadingCards,
    refetch: refetchCards,
  } = useQuery(
    [`/form-reports-cards-${formularios.id}`, currentSelect],
    () =>
      currentSelect &&
      services.getCardsByPeriod(formularios.id, {
        start: currentSelect[0],
        end: currentSelect[1],
      }),
    {
      refetchOnWindowFocus: false,
      onError: data => toast.error(data.message),
    },
  );

  useEffect(() => {
    setQuery({
      start: currentSelect[0],
      end: currentSelect[1],
      reportsFilters,
    });
  }, [currentSelect, selectedCard]);

  useEffect(() => {
    // Valida busca com pelo menos 3 caracteres para o MixPanel
    setQuery(state => ({
      ...state,
      reportsFilters,
      search: searchTrackEvent ?? undefined,
    }));
    if (searchTrackEvent.length >= 3) {
      trackEvent(user, 'Busca Respostas', null, searchTrackEvent);
    }
  }, [searchTrackEvent]);

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//

  const formatTypeColumns = () => {
    if (gerais.aprovador && gerais.ocorrencias) {
      return columnsTotal2;
    }
    if (gerais.aprovador) {
      return columnsApprove2;
    }
    if (gerais.ocorrencias) {
      return columnsOcurrence2;
    }
    return columnsSmall2;
  };

  const actions = [
    {
      title: 'Visualizar',
      function: id => getCurrentResponses(id),
    },
    {
      title: 'Ver ocorrências',
      function: id => getCurrentResponses(id, true),
      visible: item => !!item.ocorrencias?.length,
    },
    {
      title: 'Assinar',
      function: id => identifyCurrentItem(id),
      visible: item =>
        gerais.aprovador &&
        !item?.data_assinatura &&
        !selectedForms?.length &&
        !item.justificado,
    },
    {
      title: 'Ver Perfil',
      function: (_, item) =>
        window.open(`/motoristas/${item?.motorista?.id}?step=6`),
      visible: item => item.motorista,
    },
  ];

  // Actions
  const signatureApprove = async () => {
    let ids = [];
    setSignModal(false);
    if (selectedForms?.length > 0) {
      ids = selectedForms.filter(
        item => !data?.rows?.find(i => i.id === item)?.justificado,
      );
    } else {
      ids = currentItem.ids;
    }

    let res = await services.signApprove(formularios.id, { ids });
    if (res.success) {
      toast.success(res.message);
      refetch();
    } else {
      toast.error(res.message);
    }
  };

  const identifyCurrentItem = id => {
    let ids = (selectedForms?.length > 0 ? selectedForms : [+id]).filter(
      item => !data?.rows?.find(i => i.id === item)?.justificado,
    );

    let currentForms = data?.rows?.filter(item => ids.includes(item.id));
    let classNames = currentForms.map(user => user.aluno.nome);

    let ListComponent = (
      <>
        <S.ListUsers>
          <div className="title">
            {selectedForms?.length > 0
              ? 'Os seguintes alunos serão aprovados:'
              : 'O seguinte aluno será aprovado:'}
          </div>
          <ul>
            {classNames.map((user, key) => (
              <li key={key}>{user}</li>
            ))}
          </ul>
        </S.ListUsers>
      </>
    );

    setCurrentItem({ ids: [id], ListComponent });
    setSignModal(true);
  };
  // --------------------------------------------------------------------------------------------------------------//

  // Control back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (!signModal) {
      timerRoute = setTimeout(() => {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }, 100);
    }

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [signModal]);

  // ------------------------------------------------ MODAL VIEW CONTROL----------------------------------------//
  const getCurrentResponses = async (id, ocorrencias) => {
    let currentItem = data?.rows?.find(item => +item.id === +id);
    let alunoId = currentItem?.aluno?.id;
    let alunoTipo = currentItem?.id_motorista ? 'MOTORISTA' : 'GESTOR';

    if (currentItem?.justificado) {
      toast.warn(`Usuários justificados não possuem respostas!`);
      return null;
    }

    setLoadingLine([id]);
    setCurrentHist(+id);
    let res = await services.getFormResponse(
      formularios.id,
      id,
      alunoId,
      alunoTipo,
    );
    if (res.success) {
      setFormHist(res);
      setModalView({ open: true, ocorrencias });
    } else {
      toast.error(res.message);
    }
    setLoadingLine([]);
  };

  // ------------------------------------------------ MODAL JUSTIFY CONTROL----------------------------------------//
  const handleModalJustify = data => {
    setDataJustify(data);
    setModalPendding(false);
    setModalJustify(true);
  };

  const handleJustify = async data => {
    setLoadingJustify(true);
    let res = await services.justify(formularios.id, {
      justificativa: data,
      data_historico: currentSelect[0],
      alunos: dataJustify,
    });
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setLoadingJustify(false);
    setDataJustify([]);
    await refetch();
    refetchCards();
    setModalPendding(false);
    setModalJustify(false);
  };

  const handleClickCard = card => {
    setSelectedCard(selectedCard => (card === selectedCard ? null : card));
  };

  const cardFilter = useCallback(
    data => {
      if (!selectedCard) return data;
      return data.filter(item => item.status === selectedCard);
    },
    [selectedCard],
  );

  // ---------------------------------------------RENDER-------------------------------------------//
  return (
    <>
      <S.InfoLine>
        <div className="period">{fequencyModule(gerais)}</div>
        <div className="flex">
          {hasRepet && !!select.length && (
            <label className="filters">
              <span className="label">Periodo selecionado:</span>
              <DatePicker
                initialize={currentSelect}
                setCurrentSelect={setCurrentSelect}
                select={select}
                periodo={gerais.periodo}
              />
            </label>
          )}
          <GhostButton
            onClick={() => setModalPendding(true)}
            children="alunos pendentes"
            icon={<SchoolOutlinedIcon />}
            size="large"
            customcolor={theme.palette.semantics.feedback.attention.natural}
            style={{ marginRight: '10px' }}
          />
        </div>
      </S.InfoLine>

      <S.Container>
        <S.BoxInfos>
          {!loadingCards && (
            <>
              <S.Card width="320px">
                <Check />
                <div className="textFields">
                  <div className="text">{resCards?.[0]?.text}</div>
                  <div className="value">
                    {resCards?.[0]?.value}/{resCards?.[0]?.turmas || 0}
                  </div>
                </div>
              </S.Card>

              <S.Card
                onClick={() =>
                  resCards?.[1]?.value &&
                  handleClickCard(cardFilters.reprovados)
                }
              >
                <Wrong />
                <div className="textFields">
                  <div className="value">{resCards?.[1]?.value}</div>
                  <div className="text">{resCards?.[1]?.text}</div>
                </div>
                <KeyboardArrowDownIcon
                  style={{
                    transform: `rotate(${
                      selectedCard === cardFilters.reprovados
                        ? '0deg'
                        : '-90deg'
                    })`,
                    transition: 'all ease .3s',
                  }}
                />
              </S.Card>

              <S.Card
                onClick={() =>
                  resCards?.[2]?.value && handleClickCard(cardFilters.ressalvas)
                }
              >
                <Info />
                <div className="textFields">
                  <div className="value">{resCards?.[2]?.value}</div>
                  <div className="text">{resCards?.[2]?.text}</div>
                </div>
                <KeyboardArrowDownIcon
                  style={{
                    transform: `rotate(${
                      selectedCard === cardFilters.ressalvas ? '0deg' : '-90deg'
                    })`,
                    transition: 'all ease .3s',
                  }}
                />
              </S.Card>

              {formularios.gerais.aprovador && (
                <S.Card
                  onClick={() =>
                    resCards?.[3]?.value &&
                    handleClickCard(cardFilters.pendentes)
                  }
                >
                  <Atention />
                  <div className="textFields">
                    <div className="value">{resCards?.[3]?.value}</div>
                    <div className="text">{resCards?.[3]?.text}</div>
                  </div>
                  <KeyboardArrowDownIcon
                    style={{
                      transform: `rotate(${
                        selectedCard === cardFilters.pendentes
                          ? '0deg'
                          : '-90deg'
                      })`,
                      transition: 'all ease .3s',
                    }}
                  />
                </S.Card>
              )}
            </>
          )}
          {loadingCards && (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          )}
        </S.BoxInfos>

        <S.TableArea>
          <S.TableHeader>
            <span>Buscar por:</span>
            <SelectMultiple
              multiple
              clearSelected
              data={currentFilters?.[0]?.data || []}
              filialName="Selecione"
              filialSubTitle="Selecione os campos"
              value={reportsFilters}
              handleChange={val => setReportsFilters(val)}
            />
            <Input
              local={false}
              loading={isFetching}
              placeholder="Buscar"
              transparent
              value={search}
              handleChange={value => setSearch(value)}
              disabled={!reportsFilters.length}
            />
          </S.TableHeader>
          <HeaderlessTable
            data={cardFilter(data?.rows || [])}
            columns={formatTypeColumns()}
            setSelectedRows={setSelectedForms}
            loading={loading}
            local
            actions={actions}
            onClickRow={id => getCurrentResponses(id)}
            loadingSelection={loadingLine}
            sortBy={{ id: 'data_historico', order: 'ASC' }}
            empty={{
              title: hasRepet
                ? 'Ops! Você não tem nenhuma resposta para esse período'
                : 'Ops! Você não tem nenhuma resposta para esse formulário',
              description: hasRepet
                ? 'Verifique o período selecionado'
                : 'Aguarde até que os alunos iniciem as respostas',
            }}
            bulk={
              gerais.aprovador
                ? [
                    {
                      title: 'Assinar',
                      function: () => identifyCurrentItem(),
                    },
                  ]
                : undefined
            }
          />
        </S.TableArea>

        <ConfirmModal
          open={signModal}
          handleClose={() => {
            setSignModal(false);
            setCurrentItem(null);
          }}
          buttonText="Confirmar"
          title={`Tem certeza que deseja assinar ${
            selectedForms?.length > 0 ? 'os formulários?' : 'o formulário'
          }`}
          titleIcon={
            <SpellcheckOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
          }
          onClick={signatureApprove}
          subtitle={
            currentItem?.ListComponent /* 'Os formulários serão assinados e essa ação não poderá ser desfeita' */
          }
        />
      </S.Container>
      {formHist && modalView?.open && (
        <ModalSign
          open={modalView?.open}
          onClose={() => {
            setModalView({ open: false });
            setFormHist(false);
            setCurrentHist(null);
          }}
          ocorrencias={modalView?.ocorrencias}
          form={formHist}
          histId={currentHist}
          refetch={getCurrentResponses}
          loading={!!loadingLine.length}
          backPath={`/formularios/estatisticas/${formularios.id}`}
          paths={[
            {
              label: `Formularios`,
              href: `/formularios`,
            },
            {
              label: `Formulario #${formularios.id}`,
              href: `/formularios/estatisticas/${formularios.id}`,
            },
          ]}
        />
      )}

      {modalPendding && (
        <ModalPendentes
          open={modalPendding}
          data={data?.pending || []}
          onClose={() => setModalPendding(false)}
          currentPeriod={currentSelect}
          idForm={formularios?.id}
          openModalJustify={handleModalJustify}
          periodo={gerais.periodo}
        />
      )}
      {modalJustify && (
        <ModalJustificativa
          open={modalJustify}
          dataLength={dataJustify.length}
          onClick={handleJustify}
          isLoading={isFetching || loadingJustify}
          onClose={() => {
            setModalJustify(false);
            setModalPendding(true);
          }}
        />
      )}
    </>
  );
};
